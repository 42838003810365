import React, { useEffect, useState } from 'react';
// import PropType from 'prop-types';
import { useHistory } from 'react-router-dom';
import { Badge } from 'antd';
import ResourceTable from '../Shared/Table';
import { useStateValue } from '../../Providers/stateProvider';
import { getUserOrders } from '../../Providers/action/order';
import { parseOrderData, encode64 } from '../../utils';

import './myOrders.less';

const CancelledOrders = ({ t, resetValue }) => {
  const initialState = {
    page: 1,
    limit: 10,
    resultGroup: 'Canceled'
  };
  // eslint-disable-next-line no-unused-vars
  const [state, setState] = useState(initialState);
  const [{ order }, dispatch] = Object.values(useStateValue());
  const history = useHistory();

  useEffect(() => {
    getUserOrders(dispatch, state);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dispatch, resetValue, state]);

  const { loading, myOrders: { Canceled } } = order;
  const dataSource = parseOrderData(Canceled?.records);

  const goToDetails = (key) => {
    const order = Canceled?.records?.find(f => f.id === key);
    dispatch({
      type: 'PLACE_ORDER',
      payload: {
        orderId: order.id,
        orderType: order.orderType,
        offerAssetId: order.offerAssetId,
        orderStatusId: order.orderStatusId,
        step: order?.orderDetails?.orderStatusId === 2 || order?.orderDetails?.orderStatusId === 5 ? 2 : 1
      }
    });

    history.push(`/orderDetail/${encode64(key)}`);
  };

  const onShowSizeChange = (current, size) => {
    setState({ ...state, limit: size, page: current });
  }

  const changePage = (page, pageSize) => {
    setState({ ...state, limit: pageSize, page });
  }

  const columns = [
    {
      title: t(`Partners`),
      dataIndex: `maker`,
      key: 'maker',
      width: '20%',
    },
    {
      title: t(`Type`),
      dataIndex: `orderType`,
      key: 'orderType',
      width: '20%',
      render: (dataSource) => {
        return <div style={{ color: `${dataSource === 'Buy' ? '#4F9A94' : '#D32F2F'}` }}>{dataSource}</div>;
      }
    },
    {
      title: t('Asset'),
      dataIndex: 'asset',
      key: 'asset',
      width: '20%',
    },
    {
      title: t('Price'),
      dataIndex: 'price',
      key: 'price',
      width: '20%',
    },

    {
      title: t('Quantity'),
      dataIndex: 'quantity',
      key: 'quantity',
      width: '20%',
    },

    {
      title: t('Currency'),
      dataIndex: 'currency',
      key: 'currency',
      width: '20%',
    },
    
    {
      title: t('Time'),
      dataIndex: 'time',
      key: 'time',
      width: '15%'
    },
    {
      title: t('Status'),
      dataIndex: 'status',
      key: 'status',
      width: '25%',
      render: (dataSource) => (
        <span>
          <Badge status="default" />
          {dataSource}
        </span>
      ),
    },
  ];
  return (
    <>
      <ResourceTable
        loading={loading}
        data={Canceled}
        onShowSizeChange={onShowSizeChange}
        onChange={changePage}
        tableProps={{
          columns,
          dataSource,
          pagination: 'topRight',
          onRow: (order) => ({
            onClick: () => goToDetails(order.key),
          }),
        }}
      />
    </>
  );
};

export default CancelledOrders;