import React, { useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { Button, Space } from 'antd';
import ResourceTable from '../Shared/Table';
import { useStateValue } from '../../Providers/stateProvider';
import { getUserTrades } from '../../Providers/action/order';
import { parseOrderData, encode64 } from '../../utils';

import './myOrders.less';

const MyTradeOffers = ({ t, resetValue }) => {
  const initialState = {
    page: 1,
    limit: 10,
    resultGroup: "Awaiting"
  };
  // eslint-disable-next-line no-unused-vars
  const [state, setState] = useState(initialState);
  const [{ order }, dispatch] = Object.values(useStateValue());
  const history = useHistory();

  useEffect(() => {
    getUserTrades(dispatch, state);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dispatch, resetValue, state]);

  const { loading, myTrades: { Awaiting } } = order;
  const dataSource = parseOrderData(Awaiting?.records);

  const goToDetails = async (e, orderId, type = '') => {
    if (type === 1) {
      history.push({
        pathname: `/orderAction/${encode64(orderId)}`,
        search: '?trades=true'
      });

    } else {
      history.push({
        pathname: `/orderDetail/${encode64(orderId)}`,
        search: '?trades=true'
      });
    }
  };

  const onShowSizeChange = (current, size) => {
    setState({ ...state, limit: size, page: current });
  }

  const changePage = (page, pageSize) => {
    setState({ ...state, limit: pageSize, page });
  }

  const columns = [
    {
      title: t(`Partners`),
      dataIndex: `name`,
      key: 'name',
      width: '20%',
    },
    {
      title: t(`Type`),
      dataIndex: `offerType`,
      key: 'offerType',
      width: '20%',
      render: (dataSource) => {
        return <div style={{ color: `${dataSource === 'Buy' ? '#008751' : '#ef2445'}` }}>{dataSource}</div>;
      }
    },
    {
      title: t('Asset'),
      dataIndex: 'asset',
      key: 'asset',
      width: '20%',
    },
    {
      title: t('Price'),
      dataIndex: 'price',
      key: 'price',
      width: '20%',
    },

    {
      title: t('Quantity'),
      dataIndex: 'quantity',
      key: 'quantity',
      width: '20%',
    },

    {
      title: t('Currency'),
      dataIndex: 'currency',
      key: 'currency',
      width: '20%',
    },

    {
      title: t('Time'),
      dataIndex: 'time',
      key: 'time',
      width: '15%'
    },
    {
      title: t('Status'),
      dataIndex: 'status',
      key: 'status',
      width: '25%',
    },
    {
      title: t('Action'),
      width: '30%',
      render: (data) => {
        return <Space size="middle">
          {/* {data.orderStatusId > 1 ? */}
            <Button onClick={(e) => goToDetails(e, data.key, data.orderStatusId)} disabled={loading} type="primary">{t('View')}</Button>
            {/* :
            <>
              <Button onClick={(e) => goToDetails(e, data.key, 'accept')} ref={btn} type="primary"> {t('Accept')} </Button>
              <Button onClick={(e) => goToDetails(e, data.key, 'reject')} type='secondary'>{t('Reject')}</Button>
            </>
          } */}
        </Space>;
      },
    },
  ];
  return (
    <>
      <ResourceTable
        loading={loading}
        data={Awaiting}
        onShowSizeChange={onShowSizeChange}
        onChange={changePage}
        tableProps={{
          columns,
          dataSource,
          pagination: 'topRight',
          onRow: (data) => ({
            onClick: (e) =>  goToDetails(e, data.key, data.orderStatusId),
          }),
        }}
      />
    </>
  );
};

export default MyTradeOffers;
