import offerDataState from './state';
import Type from './type';

const offerReducer = (offers = offerDataState, { payload, type, error }) => {
  switch (type) {
    case Type.SET_NOTIFICATION:
      return { ...offers, showNotification: payload }

    case Type.CREATE_OFFER_REQUEST:
      return { ...offers, loading: true };

    case Type.CREATE_OFFER:
      // offers.offers.unshift(payload);
      return {
        ...offers,
        showNotification: true,
        loading: false,
        message: 'Offer created successfully',
        error
      };

    case Type.GET_OFFERS_REQUEST:
      return { ...offers, loading: true };

    case Type.GET_OFFERS:
      return {
        ...offers,
        offers: payload,
        loading: false
      };

    case Type.GET_USER_OFFERS_REQUEST:
      return { ...offers, loading: true };

    case Type.GET_USER_OFFERS:
      return {
        ...offers,
        loading: false,
        userOffer: payload
      };
    case Type.EDIT_OFFER_REQUEST:
      return { ...offers, loading: true };

    case Type.EDIT_OFFER:
      const index = offers?.userOffer?.records.findIndex((r) => r.id === payload.id);
      if (index > -1) {
        offers.userOffer.records[index] = payload;
      }
      return {
        ...offers,
        userOffer: offers.userOffer,
        loading: false,
        error,
        showNotification: true,
        message: 'Offer updated successfully'
      };

    case Type.DELETE_OFFER:
      offers.userOffer.records = offers?.userOffer?.records.filter((r) => r.id !== payload.id);

      return {
        ...offers,
        userOffer: offers.userOffer,
        loading: false,
        error,
        showNotification: true,
        message: 'Offer deleted successfully'
      };

    case Type.SET_ERROR:
      return {
        ...offers,
        error: payload,
        loading: false
      }
    default:
      return offers;
  }
};

export default offerReducer;
