import React, { useState, useEffect } from 'react';
import {
  Row,
  Col,
  Form,
  Input,
  Button,
  Select,
  InputNumber,
  Descriptions,
  // Statistic
} from 'antd';
import { useHistory } from 'react-router-dom';
import Loader from '../../Shared/Loading';
import { useStateValue } from '../../../Providers/stateProvider';
import { placeOrder } from '../../../Providers/action/order';
import { encode64 } from '../../../utils';
// import moment from 'moment';
import commaNumber from 'comma-number';

import PaymentMethodModal from '../../Shared/Navigationbar/PaymentModal';

// const { Countdown } = Statistic;

const SetOrderAmount = ({ orderData = {}, payMethods, id, t }) => {
  const initialState = {
    offerId: '',
    orderAmount: '',
    takerPaymentMethodId: '',
    currencyOrderAmount: ''
  };

  const [state, setState] = useState(initialState);
  const [form] = Form.useForm();
  const { Option } = Select;
  const history = useHistory();
  const [proceed, setProcceed] = useState(false);
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [disableSubmit, setDisablSubmit] = useState(true);
  const [change, setChange] = useState(false);
  const [showLimitError, setShowLimitError] = useState(false);
  // eslint-disable-next-line no-unused-vars
  // const [timeout, setTimeout] = useState(Date.now() + 1000 * 60);

  const showModal = () => {
    setIsModalVisible(true);
  };


  const handleCancel = () => {
    setIsModalVisible(false);
  };

  const {
    assetAmount, assetId,
    assetPrice, currencyId, maker, makerStats,
    currencyPaymentChannelId, remark, orderType, offerType,
    maxTradeAmount, minTradeAmount, maxTimePerTransaction
  } = orderData;

  const [{ order }, dispatch] = Object.values(useStateValue());
  const { loading, orderDetails } = order;

  const onChangeOrderAmount = (value) => {
    if (orderType === 'Buy') {
      setState({ ...state, orderAmount: value, currencyOrderAmount: value / assetPrice });
    } else {
      setState({ ...state, orderAmount: value, currencyOrderAmount: value * assetPrice });
    }
    setChange(!change)
  };

  const onChangeCurrencyOrderAmount = (value) => {
    if (orderType === 'Buy') {
      setState({ ...state, currencyOrderAmount: value, orderAmount: value * assetPrice });
    } else {
      setState({ ...state, currencyOrderAmount: value, orderAmount: value / assetPrice });
    }
    setChange(!change)
  };

  const onSelect = (value) => {
    if (value === -1) {
      showModal();
    } else {
      setState({ ...state, takerPaymentMethodId: value });
      setChange(!change)
    }
  };

  const url = window.location.hash;
  useEffect(() => {
    if (url.includes('processOrder')) {
      dispatch({
        type: 'RESET',
        payload: {
          orderDetails: {}
        }
      });
    };
  }, [dispatch, url]);

  useEffect(() => {
    if (proceed) {
      if (orderDetails.id !== undefined) {
        history.push(`/orderDetail/${encode64(orderDetails.id)}`);
      };
    }
  }, [orderDetails, history, url, proceed]);

  useEffect(() => {
    const { orderAmount, takerPaymentMethodId, currencyOrderAmount } = state;
    const value = orderType === 'Sell' ? currencyOrderAmount : orderAmount;

    if (value && (value < minTradeAmount || value > maxTradeAmount)) {
      setShowLimitError(true)
    } else {
      setShowLimitError(false)
    }

    if (takerPaymentMethodId && (value >= minTradeAmount && value <= maxTradeAmount)) {
      setDisablSubmit(false)
      setShowLimitError(false)
    } else {
      setDisablSubmit(true)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [change, orderType, maxTradeAmount, minTradeAmount])

  const performChecks = () => {

    return true;
  }
  const createOrder = async () => {
    if (performChecks) {
      const { orderAmount, takerPaymentMethodId, currencyOrderAmount } = state;
      dispatch({
        type: 'RESET',
        payload: {
          orderDetails: {}
        }
      });

      await placeOrder(dispatch, {
        offerId: id,
        orderAmount: orderType === 'Buy' ? '' + currencyOrderAmount : '' + orderAmount,
        takerPaymentMethodId
      });
      setProcceed(true);
    }
  };

  console.log(orderType, '><<<<')
  const filteredPayMethod = payMethods.filter((method) => {
    const filter = orderType.toLowerCase() === 'buy' ? 
      method.paymentChannelId === 'Bantu Public Key'
      :
      currencyId === 'USDT' ? 
        currencyId  === method.paymentChannelId.split('-')[0] : method.paymentChannelId !== 'Bantu Public Key' && method.paymentChannelId.split('-')[0] !== 'USDT'; 
    return filter;

  });

  const asset = orderType === 'Buy' ? currencyId : assetId;
  const currency = orderType === 'Buy' ? assetId : currencyId;
  const action = orderType === 'Buy' ? 'pay' : 'sell';

  const color = orderType === 'Buy' ? '#F86912' : '#D32F2F';

  const availableAsset = `${commaNumber(assetAmount * assetPrice)} ${currencyId}`;
  const availableFiat = `${commaNumber(assetAmount)} ${assetId}`;

  // const onFinish = () => {
  //   history.push(`/${orderType.toLowerCase()}`);
  // }

  return (
    <Row>
      <Col md={24} lg={12} className="content-border">
        <Descriptions column={1}>
          <Descriptions.Item label="" >
            <span style={{ fontSize: '18px' }}>
              {maker}
            </span>
            &nbsp;
            &nbsp;
            &nbsp;
            <Descriptions.Item label="" className="user-progress">
              {t('Trades')}:
              &nbsp;
              <span
                style={{ fontWeight: 400 }}
              >
                {makerStats?.trades || 0}
              </span>
              &nbsp;&nbsp;  | &nbsp;&nbsp;
              <span style={{ fontWeight: 400 }}>
                {makerStats?.rank || 0}% {t('Completion')}
              </span>
            </Descriptions.Item>
          </Descriptions.Item>
        </Descriptions>

        <Descriptions column={1}>
          <Descriptions.Item label={t('Price')}>{assetPrice} {currencyId}</Descriptions.Item>
          <Descriptions.Item label={t('Available')}>
            {availableAsset }
          </Descriptions.Item>
          <Descriptions.Item label={t('Available')}>
          {availableFiat}
          </Descriptions.Item>
          <Descriptions.Item label={t('Transaction Time Limit')}>
            <span className="price">{maxTimePerTransaction} {t('Minutes')}</span>
          </Descriptions.Item>
          <Descriptions.Item label={`${t(offerType + "er's")} ${t('Payment method')}`}>
            <span className="payment-method">{currencyPaymentChannelId}</span>
          </Descriptions.Item>
        </Descriptions>
        <Descriptions layout='vertical'>
          <Descriptions.Item label={t(`${offerType}er’s Remark`)}>
            <div className="remark">
              {remark || 'N/A'}
            </div>
          </Descriptions.Item>
        </Descriptions>

      </Col>
      <Col className="payment-form" md={24} xl={12} >
        {/* <p className='timer'>
          <Countdown style={{ color: 'red' }} value={moment(timeout)} onFinish={onFinish} format="ss" suffix={t('seconds remaining')} />
        </p> */}
        <Row
          style={{
            margin: '0px 20px'
          }}
          type='flex'>
          <Form
            form={form}
            className='add-offer'
            style={{ width: '100%' }}
            layout='vertical'
          >
            <Row gutter={8}>
              <Col span={24}>
                <Form.Item label={`I want to ${action}`} >
                  <Input.Group >
                    <Form.Item
                      noStyle
                      rules={[{ required: true, message: t('Please add an amount') }]}
                    >
                      <InputNumber
                        size="large"
                        precision={orderType === 'Buy' ? 4 : 7}

                        onChange={(value) => onChangeOrderAmount(value,)}
                        value={state.orderAmount}
                        name="orderAmount"
                      />
                    </Form.Item>
                    <Form.Item
                      name={[t('Total amount of asset to buy') + ':', 'asset']}
                      noStyle
                      initialValue={asset}
                    >
                      <Select size="large">
                        {[asset].map((item) => (
                          <Option key={item} className='option'>
                            {item}
                          </Option>
                        ))}
                      </Select>
                    </Form.Item>
                  </Input.Group>
                  <div style={{ textAlign: 'center' }}>
                    {showLimitError ? orderType === 'Buy' ?
                      <span style={{ color: '#d91019' }}>
                        {orderType} {t('limit')}: {minTradeAmount} - {maxTradeAmount} {currencyId}
                      </span> : null : null}
                  </div>
                </Form.Item>
              </Col>
            </Row>
            <Row gutter={16}>
              <Col span={24}>
                <Form.Item label="I will receive">
                  <Input.Group >
                    <Form.Item
                      noStyle
                      rules={[{ required: true, message: t('Please add an amount') }]}
                    >
                      <InputNumber size="large"
                        precision={orderType === 'Buy' ? 7 : 4}
                        value={state.currencyOrderAmount}
                        onChange={(value) => onChangeCurrencyOrderAmount(value)}
                        name="currencyOrderAmount"
                      // formatter={ value => `${ value }`.replace(/\B(?=(\d{3})+(?!\d))/g, ',') }
                      />
                    </Form.Item>
                    <Form.Item
                      name={[t('Total amount of asset to buy') + ':', 'currency']}
                      noStyle
                      initialValue={currency}
                    >
                      <Select size="large">
                        {[currency].map((item) => (
                          <Option key={item} className='option'>
                            {item}
                          </Option>
                        ))}
                      </Select>
                    </Form.Item>
                  </Input.Group>
                  <div style={{ textAlign: 'center' }}>
                    {showLimitError ? orderType === 'Sell' ?
                      <span style={{ color: '#d91019' }}>
                        {orderType} {t('limit')}: {minTradeAmount} - {maxTradeAmount} {currencyId}
                      </span> : null : null}
                  </div>
                </Form.Item>
              </Col>
            </Row>
            <Row gutter={16}>
              <Col span={24}>
                <Form.Item
                  label={
                    orderType === 'Sell' ?
                      `${t('Receiving account for')} ${currencyId}`
                      :
                      `${t('Bantu Wallet to receive')} ${assetId} ${t('asset')}`}
                  name={[t('Select payment method') + ':', 'paymentChannelId']}
                >
                  <Select size="large" onChange={onSelect}>
                    {filteredPayMethod.map((item) => (
                      <Option key={item.paymentChannelId} value={item.id} className='option'>
                        {item.paymentChannelId} - {item.destinationAccount}
                      </Option>
                    ))}
                    <Option value={-1}>{`>> ${t('Add Payment Method')} << `}</Option>
                  </Select>
                </Form.Item>
              </Col>
            </Row>
          </Form>
          {/* </Col> */}
        </Row>
        <div style={{ textAlign: 'center' }}>
          <Button style={{ borderRadius: '7px' }} onClick={() => history.push(`/${orderType.toLowerCase()}`)}>{t('Cancel')}</Button>
          &nbsp;&nbsp;&nbsp;
          <Button
            className="button"
            style={{ background: color, color: 'white', borderRadius: '7px' }}
            disabled={disableSubmit}
            onClick={() => createOrder()}
          >
            {loading ? <><Loader /> {t('Placing Order')} </> : `${orderType} ${assetId}`}
          </Button>
        </div>
      </Col>
      <PaymentMethodModal isModalVisible={isModalVisible} handleCancel={handleCancel} />
    </Row>
  );
};

SetOrderAmount.propTypes = {

};

export default SetOrderAmount;
