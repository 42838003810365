import orderState from './state';
import Type from './type';
import { stepValue } from './utils';

const orderReducer = (
  order = orderState,
  {
    payload,
    type,
    user = 'taker',
    message
  }
) => {
  let step;

  switch (type) {
    case Type.PLACE_ORDER_REQUEST:
      return { ...order, loading: true };

    case Type.PLACE_ORDER:
      return {
        ...order,
        loading: false,
        orderDetails: {
          ...order.orderDetails,
          ...payload,
          step: 1,
          user,
        }
      }

    case Type.DELETE_ORDER_REQUEST:
      return { ...order, loading: true }

    case Type.DELETE_ORDER:
      return {
        ...order,
        loading: false,
        step: 1,
        showNotification: true,
        message: 'Order successfully deleted', // possible bug? 🤔
        orderDetails: {
          ...order.orderDetails,
          user
        }
      }

    case Type.CONFIRM_ERROR:
      return {
        ...order,
        loading: false,
      }

    case Type.GET_USER_ORDER_LIST_REQUEST:
      return { ...order, loading: true };

    case Type.GET_USER_ORDER_LIST:
      return {
        ...order,
        myOrders: {
          ...order.myOrders,
          ...payload,
        },
        loading: false
      };

    case Type.GET_USER_TRADE_LIST_REQUEST:
      return { ...order, loading: true };

    case Type.GET_USER_TRADE_LIST:
      return {
        ...order,
        myTrades: {
          ...order.myTrades,
          ...payload,
        },
        loading: false
      };

    case Type.GET_USER_ORDER_DETAILS_REQUEST:
      return { ...order, loading: true };

    case Type.GET_USER_TRADES_DETAILS_REQUEST:
      return { ...order, loading: true };

    case Type.GET_USER_ORDER_DETAILS:
      step = stepValue(payload);
      return {
        ...order,
        loading: false,
        lastUpdated: new Date(),
        orderDetails: {
          ...order.orderDetails,
          ...payload,
          step,
          user,
          counter: order.orderDetails.counter + 1
        }
      }

    case Type.GET_USER_TRADES_DETAILS:
      step = stepValue(payload);
      return {
        ...order,
        loading: false,
        lastUpdated: new Date(),
        orderDetails: {
          ...order.orderDetails,
          ...payload,
          step,
          user
        }
      }

    case Type.SOCKET_ORDER_UPDATE:
      if (payload.id === order.orderDetails.id) {
        step = stepValue(payload);
        return {
          ...order,
          playNotification: true,
          message,
          lastUpdated: new Date(),
          orderDetails: {
            ...order.orderDetails,
            ...payload,
            step,
            user
          }
        }
      } else {
        return order;
      }

    case Type.SOCKET_NEW_ORDER:
      const newOrder = order?.myTrades?.Awaiting?.records?.unshift(payload);
      return {
        ...order,
        ...newOrder,
        playNotification: true,
        message,
      }

    case Type.SET_NOTIFICATION_SOUND:
      return {
        ...order,
        playNotification: payload,
      }
    case Type.SET_NOTIFICATION:
      return {
        ...order,
        showNotification: payload
      }

    case Type.CONFIRM_DEPOSITE_REQUEST:
      return { ...order, loading: true };

    case Type.CONFIRM_DEPOSITE:
      step = stepValue(payload);
      return {
        ...order,
        loading: false,
        orderDetails: {
          ...order.orderDetails,
          ...payload,
          step,
          user
        }
      }

    case Type.ACCEPT_ORDER_REQUEST:
      return { ...order, loading: true };

    case Type.ACCEPT_ORDER:
      step = stepValue(payload);
      return {
        ...order,
        loading: false,
        orderDetails: {
          ...order.orderDetails,
          ...payload,
          step,
          user: 'maker'
        }
      }

    case Type.RESET:
      const x = {
        ...order,
        ...payload,
        loading: false,
      }
      return x;

    case Type.REJECT_OFFER:
      const newOrderData = {
        ...order,
        ...order.myTrades,
      }
      return newOrderData;

    case Type.MAKER_APPEAL_ORDER:
      return {
        ...order,
        loading: false,
        playNotification: true,
        orderDetails: {
          ...order.orderDetails,
          ...payload,
          step,
          user: 'maker'
        }
      }

    case Type.TAKER_APPEAL_ORDER:
      return {
        ...order,
        loading: false,
        playNotification: true,
        orderDetails: {
          ...order.orderDetails,
          ...payload,
          step,
          user: 'maker'
        }
      }

    default:
      return order;
  }
};

export default orderReducer;