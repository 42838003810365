import React from 'react';
import { Card } from 'antd';

const CardComponent = ({title, children, extra}) => {
    return (
        <div className="site-card-border-less-wrapper">
            <Card 
                title={title}
                extra={extra}
                bordered={false}
                style={{ 
                    width: '100%',
                    border: '1px solid #ccc',
                    borderRadius: '10px',
                    padding: '2%'
                }}>
            {children}
            </Card>
        </div>
    );
};

export default CardComponent;
