const offersType = {
    CREATE_OFFER_REQUEST: 'CREATE_OFFER_REQUEST',
    CREATE_OFFER: 'CREATE_OFFER',
    GET_OFFERS_REQUEST: 'GET_OFFERS_REQUEST',
    GET_OFFERS: 'GET_OFFERS',
    GET_USER_OFFERS_REQUEST: 'GET_USER_OFFERS_REQUEST',
    GET_USER_OFFERS: 'GET_USER_OFFERS',
    EDIT_OFFER_REQUEST: 'EDIT_OFFER_REQUEST',
    EDIT_OFFER: 'EDIT_OFFER',
    SET_NOTIFICATION: 'SET_NOTIFICATION',
    ERROR: 'ERROR',
    CONFIRM_ERROR: 'CONFIRM_ERROR',
    SET_CONFIRM_ERROR: 'SET_CONFIRM_ERROR',
    SET_ERROR: 'SET_ERROR',
    DELETE_OFFER: 'DELETE_OFFER',
    DELETE_OFFER_REQUEST: 'DELETE_OFFER_REQUEST',
}

export default offersType;