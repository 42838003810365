import React from 'react';
import ReactDOM from 'react-dom';
import reportWebVitals from './reportWebVitals';
import StateProvider from './Providers/stateProvider';
import { HashRouter } from 'react-router-dom';
import { I18nextProvider } from "react-i18next";
import * as serviceWorker from "./serviceWorkerRegistration"

import './index.css';
import App from './App';
import i18n from "./i18n";

ReactDOM.render(
  <React.StrictMode>
    <HashRouter>
      <StateProvider>
        <I18nextProvider i18n={i18n}>
          <App />
        </I18nextProvider>
      </StateProvider>
    </HashRouter>
  </React.StrictMode>,
  document.getElementById('root')
);

serviceWorker.register({
  onUpdate: registration => {
    const waitingServiceWorker = registration.waiting

    if (waitingServiceWorker) {
      waitingServiceWorker.addEventListener("statechange", event => {
        if (event.target.state === "activated") {
          if (
            window.confirm(
              "There is a new version of the app ready. Please reload to update."
            )
          ) {
            window.location.reload()
          }
        }
      })
      waitingServiceWorker.postMessage({ type: "SKIP_WAITING" })
    }
  },
});

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
