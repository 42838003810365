import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import ResourceTable from '../Shared/Table';
import FormModal from '../Shared/Modal';
import {
  Row,
  Col,
  Form,
  Input,
  InputNumber,
  Space,
  Button,
  Modal,
  Switch,
  Slider,
} from 'antd';
import { ExclamationCircleOutlined } from '@ant-design/icons';
import { getUserOffer, editOffer, deleteOffer } from '../../Providers/action/offers';
import { useStateValue } from '../../Providers/stateProvider';
import { parseOfferData } from '../../utils';

import './myOffers.less';

const { TextArea } = Input;

const { confirm } = Modal;

const MyOffers = () => {
  const initialState = {
    id: '',
    key: '',
    currencyId: '',
    paymentMethodId: '',
    assetAmount: '',
    assetId: '',
    assetPrice: '',
    minTradeAmount: 0,
    maxTradeAmount: 0,
    amount: '',
    currencyPaymentMethodId: '',
    remark: '',
    offerType: '',
    inactive: 0,
    limit: 10,
    page: 1,
    maxTimePerTransaction: 45,

  };

  const [state, setState] = useState(initialState);
  const { t } = useTranslation();
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [check, setCheck] = useState(true)
  const [form] = Form.useForm();
  const [{
    offer,
  }, dispatch] = Object.values(useStateValue());

  useEffect(() => {
    getUserOffer(dispatch, {page: state.page, limit: state.limit});
    window.scroll(0, 0);
  }, [dispatch, state.limit, state.page]);

  const onShowSizeChange = (current, size) => {
    setState({ ...state, limit: size, page: current });
  }

  const changePage = (page, pageSize) => {
    setState({ ...state, limit: pageSize, page });
  }

  const { loading, userOffer, error } = offer;
  const dataSource = parseOfferData(userOffer?.records);

  const deleteOfferConfirm = async (id) => {
    await deleteOffer(dispatch, {id})
  }

  const confirmDelete = (data) => {
    console.log(data);
    confirm({
      title: t('Do you want to delete this offer') + '?',
      icon: <ExclamationCircleOutlined />,
      async onOk() {
        return deleteOfferConfirm(data.key);
      },
      onCancel() { },
    });
  }


  const showModal = (data) => {
    const offerData = userOffer?.records.find(r => r.id === data.key);
    console.log(offerData);
    setState({
      ...state,
      id: offerData.id,
      key: offerData.id,
      paymentMethodId: offerData.paymentMethodId,
      currencyId: offerData.currencyId,
      assetAmount: offerData.assetAmount,
      assetId: offerData.assetId,
      assetPrice: offerData.assetPrice,
      minTradeAmount: offerData.minTradeAmount,
      maxTradeAmount: offerData.maxTradeAmount,
      remark: offerData.remark,
      amount: offerData.assetAmount * offerData.assetPrice,
      inactive: offerData.inactive,
      offerType: offerData.offerType,
      maxTimePerTransaction: offerData.maxTimePerTransaction
    });
    setIsModalVisible(true);
  };

  const handleCancel = () => {
    setIsModalVisible(false);
  };

  const columns = [
    {
      title: t(`Offer Type`),
      dataIndex: `offerType`,
      key: 'offerType',
      width: '15%',
      render: (dataSource) => {
        return <div style={{ color: `${dataSource === 'Buy' ? '#4F9A94' : '#D32F2F'}` }}>{dataSource}</div>;
      }
    },
    {
      title: t('Price'),
      dataIndex: 'price',
      key: 'price',
      width: '20%',
    },
    {
      title: t('Available'),
      // dataIndex: initialType === 'Buy' ? 'availableAsset' : 'availableFiat',
      // key: initialType === 'Buy' ? 'availableAsset' : 'availableFiat',
      width: '20%',
      render: (dataSource) => { 
        return dataSource.offerType === 'Sell' ? dataSource.availableAsset : dataSource.availableFiat
      }
    },
    {
      title: t('Limits'),
      dataIndex: 'limits',
      key: 'limits',
      width: '20%',
    },

    {
      title: t('Payment method'),
      dataIndex: 'paymentMethod',
      key: 'paymentMethod',
      width: '25%',
    },
    {
      title: t('Status'),
      dataIndex: 'inactive',
      key: 'inactive',
      width: '25%',
    },
    {
      title: t('Max Trade Time'),
      // dataIndex: 'maxTimePerTransaction',
      // key: 'maxTimePerTransaction',
      width: '25%',
      render: (data) => data.maker.maxTimePerTransaction
    },
    {
      title: t('Action'),
      dataIndex: '',
      key: '',
      width: '25%',
      responsive: ['md'],
      render: (record) => {
        return <Space size="middle">
          <span onClick={() => showModal(record)} style={{color: 'green', textDecoration: 'underline', cursor: 'pointer' }}>Edit</span>
          <span onClick={() => confirmDelete(record)} style={{color: 'red', textDecoration: 'underline', cursor: 'pointer' }}>Delete</span>
        </Space>;
      },
    },
  ];

  const liquidityLabel = state.offerType === 'Buy' ? t(`Total liquidity needed`) : t(`Total Liquidity Available`);

  const valueLabel = state.offerType === 'Buy' ? t(`Value of liquidity needed`) : t(`Value of Liquidity Available`);

  const onChange = (value, name) => {
    const v = (name !== 'remark' && value <= 0) ? 0 : value;
    console.log()
    if (name === 'maxTimePerTransaction') {
      setState({ ...state, [name]: v });
    } else {
      setState({ ...state, [name]: '' + v });
    }
  };

  const onChangeActive = (value) => {
    setState({ ...state, 'inactive': value === true ? 0 : 1 });
  };
  const onChangeAssetAmount = (value) => {
    setState({ ...state, assetAmount: value, amount: value * state.assetPrice });
  };

  const onChangeAmount = (value) => {
    setState({ ...state, amount: value, assetAmount: value / state.assetPrice });
  };

  const setPrice = (value) => {
    setState({
      ...state,
      assetPrice: value,
      amount: state.assetAmount * (value || 1),
      // assetAmount: state.amount / (value || 1)
    });
  };

  const createOffer = async () => {
    const done = await editOffer(dispatch, {
      ...state,
      assetAmount: '' + state.assetAmount,
      assetPrice: '' + state.assetPrice
    });
    setCheck(!check)
    return done;
  };

  useEffect(() => {
    if (!error) {
      setIsModalVisible(false);
    }
  }, [error, check]);

  const showPromiseConfirm = () => {
    confirm({
      title: t('Do you want to update this offer') + '?',
      icon: <ExclamationCircleOutlined />,
      async onOk() {
        return createOffer();
      },
      onCancel() { },
    });
  };

  return (
    <div className="myOffers" >
      <ResourceTable
        loading={loading}
        data={userOffer}
        onShowSizeChange={onShowSizeChange}
        onChange={changePage}
        tableProps={{
          columns,
          dataSource,
          pagination: 'topRight',
          onRow: (record) => ({
            onClick: () => showModal(record),
          }),
        }}
      />
      <FormModal className="myOffers"  width='1000px' title={`${t('Edit')} ${state.offerType} ${t('Offer')} `} visible={isModalVisible} onCancel={handleCancel} footer={[
        <span className="hide-lg" onClick={() => confirmDelete(state)} style={{color: 'red', textDecoration: 'underline', cursor: 'pointer', display: 'none' }}>Delete Offer</span>,
        <Button key="submit" type="primary" onClick={showPromiseConfirm}>
          {t('Continue')}
        </Button>,
        <Button key="back" type="secondary" onClick={handleCancel}>
          {t('Cancel')}
        </Button>]}>

        <Row type='flex'>
          <Col xxl={12} lg={18} md={24} sm={24} xs={24}>
            <Form
              form={form}
              className='add-offer'
              layout='vertical'
            >
              <Row gutter={16}>
                <Col xxl={7} lg={7} md={12} sm={24} xs={24}>
                  <Form.Item
                    label='Set Price:'
                    rules={[
                      {
                        required: true,
                        message: t('Please set a price') + '!',
                      },
                    ]}>
                    <InputNumber
                      size="large" min={0}
                      value={state.assetPrice}
                      onChange={setPrice}
                      style={{
                        width: 150,
                      }}
                      name="assetPrice"
                    />
                  </Form.Item>
                </Col>
              </Row>
              <Row gutter={16}>
                <Col xxl={18} lg={18} md={18} sm={24} xs={24}>
                  <Form.Item label={liquidityLabel}>
                    <Form.Item
                      noStyle
                      rules={[{ required: true, message: t('Please add an amount') + '!' }]}
                    >
                      <Input.Group>
                        <InputNumber size="large" min={0}
                          value={state.assetAmount}
                          name="assetAmount"
                          onChange={(value) => onChangeAssetAmount(value)}
                        />
                        <Input
                          style={{ width: '30%' }}
                          size="large"
                          disabled
                          value={state.assetId || '-'}
                        />
                      </Input.Group>
                    </Form.Item>
                  </Form.Item>
                  <Form.Item
                    label={valueLabel}
                    // name={ ['Amount:', 'amount'] }
                    rules={[{ required: true, message: 'Please add an amount!' }]}
                  >
                    <Input.Group>
                      <InputNumber size="large" min={0}
                        value={state.amount}
                        name="amount"
                        onChange={(value) => onChangeAmount(value)}
                      // formatter={ value => `${ currencySymbol } ${ value }`.replace(/\B(?=(\d{3})+(?!\d))/g, ',') }
                      />
                      <Input
                        style={{ width: '30%' }}
                        size="large"
                        disabled
                        value={state.currencyId || '-'}
                      />
                    </Input.Group>
                  </Form.Item>
                </Col>
              </Row>
              <Row gutter={12}>
                <Col xxl={8} lg={8} md={10} sm={24} xs={24}>
                  <Form.Item
                    label='Min trade amount:'
                    rules={[
                      {
                        required: true,
                        message: t('Please set a Limit') + '!',
                      },
                    ]}>
                    <InputNumber
                      size="large"
                      min={1}
                      value={state.minTradeAmount}
                      onChange={(value) => onChange(value, 'minTradeAmount')}
                    // formatter={ value => `${ currencySymbol } ${ value }`.replace(/\B(?=(\d{3})+(?!\d))/g, ',') }
                    />
                  </Form.Item>
                </Col>
                <Col xxl={4} lg={4} md={4} sm={24} xs={24} className='top-limit-to'> <p>{'To'} </p></Col>
                <Col xxl={8} lg={8} md={8} sm={24} xs={24} >
                  <Form.Item
                    label='Max trade amount:'
                    rules={[
                      {
                        required: true,
                        message: 'Please set a Limit!',
                      },
                    ]}
                  >
                    <InputNumber
                      size="large"
                      min={1}
                      value={state.maxTradeAmount}
                      onChange={(value) => onChange(value, 'maxTradeAmount')}
                      style={{
                        width: '100%',
                      }}
                    // formatter={ value => `${ currencySymbol } ${ value }`.replace(/\B(?=(\d{3})+(?!\d))/g, ',') }
                    />
                  </Form.Item>
                </Col>
              </Row>
              <Row>
                  <p>Enter Max time per transaction (unit: mins):</p>
                    <Col span={12}>
                      <Slider 
                        name='maxTimePerTransaction' 
                        size="large" placeholder="00:00:45 = 45 mins"
                        defaultValue={45}
                        max={480}
                        value={state.maxTimePerTransaction}
                        tooltipVisible
                        onChange={(value) => onChange(value, 'maxTimePerTransaction')} /> 
                    </Col>
                    <Col span={4}>
                      <InputNumber
                        min={1}
                        max={480}
                        style={{ margin: '0 16px' }}
                        value={state.maxTimePerTransaction}
                        onChange={(value) => onChange(value, 'maxTimePerTransaction')}
                      />
                      </Col>
              </Row>
                {state.maxTimePerTransaction < 60 ? `${state.maxTimePerTransaction} Mins` : `${(state.maxTimePerTransaction / 60).toFixed(1)} hours`}
                <br/>
                <br/>
              <Row >
                <Col xxl={18} lg={18} md={19} sm={24} xs={24}>
                  <Form.Item label='Enter Remark (Optional):'>
                    <TextArea
                      value={state.remark}
                      onChange={(value) => onChange(value.target.value, 'remark')}
                      rows={4}
                      placeholder={t('Enter Remark and description here')} />
                  </Form.Item>
                </Col>
              </Row>
              <Row gutter={15}>
                <Col>
                  {t('Set Active status')}: {state.inactive === 0 ? 'true' : 'false'} &nbsp;&nbsp;&nbsp;
                  <Switch checkedChildren="Active" unCheckedChildren="Inactive" defaultChecked={state.inactive === 0 ? true : false} onChange={onChangeActive} />
                </Col>
              </Row>
            </Form>
          </Col>

        </Row>
      </FormModal>
    </div>
  );
};

export default MyOffers;