import React from 'react';
import { 
  Col,
  Form,
  Input,
  Select
} from 'antd';
import { countries } from '../../../../utils/countries';

const { Option } = Select;

const MobileMoneyForm = ({ setState, state, t }) => {
  const onChange = (e) => {
    const { name, value } = e.target;
    setState({ ...state, [name]: value });
  };

  const onSelect = (value) => {
    const country = countries.find(c => c.name === value);
    setState({ ...state, countryCode: country.isoAlpha2, currencyId: country.currency.code  });
  }

  return (
    <>
          <Col xxl={12} lg={12} md={24} sm={24} xs={24}>
        <Form.Item
          label={t('Select Country') + ':'}
          rules={[
            {
              required: true,
              message: t('Please Select payment a channel'),
            },
          ]}>
          <Select
            name="country"
            size='large'
            onChange={(value) => onSelect(value)}
            showSearch
            placeholder='Select'>
            {countries.map((item) => (
              <Option key={item.name} className='option'>
                {item.name}
              </Option>
            ))}
          </Select>
        </Form.Item>
      </Col>
    <Col xxl={ 24 } lg={ 24 } md={ 24 } sm={ 24 } xs={ 24 }>
      <Form.Item
        label={t('Name') +':'}
        rules={ [
          {
            required: true,
            message: t('Please enter name of beneficiary'),
          },
        ] }
      >
        <Input name='name' onChange={ onChange } size="large" />
      </Form.Item>

      <Form.Item
        label={t('Network name') +':'}
        rules={ [
          {
            required: true,
            message: t('Please enter network name'),
          },
        ] }
      >
        <Input name='bankName' onChange={ onChange } size="large" />
      </Form.Item>

      <Form.Item
        label={t('Phone Number') +':'}
        rules={ [
          {
            required: true,
            message: t('Please enter correct phone number'),
          },
        ] }
      >
        <Input name='destinationAccount' onChange={ onChange } size="large" />
      </Form.Item>
    </Col>
  </>
  );
};
export default MobileMoneyForm;