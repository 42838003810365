import React from 'react';
import { InputNumber } from 'antd';

const InputNumberField = (props) => {
  return (
    <>
      <div className="ant-input-group-addon" style={{ background: 'transparent', border: 0, paddingTop:'2px', verticalAlign:'middle', display:'inline-table', lineHeight:'24px', height:'32px'}}>{props.prefix || '-'}</div>
      <InputNumber style={{verticalAlign:'middle', borderBottomRightRadius:0, borderTopRightRadius:0}} {...props}/>
    </>
  )
}

export default InputNumberField
