import React from 'react';
import { Spin } from 'antd';
import { LoadingOutlined} from '@ant-design/icons';

const index = () => {
    const isLoading = <LoadingOutlined style={{ fontSize: 24 }} spin />;
    return <Spin indicator={isLoading} />
}

export default index
