import React, { useState, useEffect } from 'react';
import { Row, Badge } from 'antd';
import { useTranslation } from 'react-i18next';
import Tabs from '../../Components/Shared/Tabs';
import MyOrders from '../../Components/MyOrders';
import PendingOrders from '../../Components/MyOrders/PendingOrders';
import CompletedOrders from '../../Components/MyOrders/CompletedOrders';
import CancelledOrders from '../../Components/MyOrders/CancelledOrders';
import { useStateValue } from '../../Providers/stateProvider';
import './offers.less';

const MyOrderList = () => {
  const initualValues = {
    1: false,
    2: false,
    3: false,
    4: false,
  }
  const [resetValue, setResetValue] = useState(initualValues);
  const { t } = useTranslation();
  const changeTabe = (key) => {
    const newS = { ...resetValue, [key]: !resetValue[key] };
    setResetValue(newS)
  };

  const [{ order }, dispatch] = Object.values(useStateValue());
  const { myOrders } = order;


  useEffect(() => { 
    dispatch({ type: 'SET_PAGE_CURRENT', payload: 'orders' });
  }, [dispatch]);

  const pending = (
    <Badge 
      count={myOrders.Awaiting?.records?.length || 0}
      overflowCount={99}
      offset={[10, -6]}
      style={{ backgroundColor: '#fff', color: '#000', border: '1px solid #000' }}
    >
      {t('Pending')}
    </Badge>
  )

  const tabsData = [
    {
      key: t('Completed'),
      body: (
        <CompletedOrders resetValue={resetValue['3']} t={t} />
      ),
    },
    {
      key: t('Cancelled'),
      body: (
        <CancelledOrders resetValue={resetValue['4']} t={t} />
      ),
    },
  ];

  const pendingTab = {
    key: pending,
    body: (
      <PendingOrders resetValue={resetValue['1']} t={t} />
    ),
  }
  const allTab =  {
    key: t('All'),
    body: (
      <MyOrders resetValue={resetValue['2']} t={t} />
    ),
  }

  if (myOrders.Awaiting?.records?.length > 0){
    tabsData.unshift(pendingTab, allTab);
  } else {
    tabsData.unshift(allTab, pendingTab);
  }

  return (
    <Row className="container" style={{ border: '1px solid #d9d9d9', minHeight: '100vh' }}>
      <Tabs onTabClick={changeTabe} tabs={tabsData} />
    </Row>


  );
};
export default MyOrderList;
