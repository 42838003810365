import React from "react";
import { Switch, Route } from "react-router-dom";
import { PrivateRoute } from './ProtectedRoutes';
import Home from '../Pages/Home';
import Login from '../Pages/Login';
import Buy from '../Pages/Buy';
import Sell from '../Pages/Sell';
import AddOffer from '../Pages/Offers/AddOffer';
import Offers from '../Pages/Offers/MyOfferList';
import OffersTrades from '../Pages/Offers/MyOfferTrades';
import MyOfferList from '../Pages/Offers/MyOfferList';
import MyOrderList from '../Pages/Offers/MyOrderList';
import OrderDetail from '../Pages/OrderDetail';
import ProcessOrder from '../Pages/OrderDetail/ProcessOrder';
import OrderAction from '../Pages/OrderDetail/OrderAction';
import PageNotFound from '../Pages/PageNotFound';
import TermsAndConditions from '../Pages/TermsAndConditions';
import Disclaimer from '../Pages/Disclaimer';
import ListingTermsAndCondition from '../Pages/ListingTermsAndCondition';
import Appeal from '../Pages/Appeal';

import Logout from './Logout';
import Manual from '../Pages/Manual';
import MenuBar from '../Components/Shared/MenuBar';

const Routes = () => {
    return (
        <Switch>
            <Route exact path="/" component={ Home } />

            <Route exact path="/login" >
                <MenuBar />
                <Login />
            </Route>
            <Route exact path="/buy">
                <MenuBar />
                <Buy />
            </Route>
            <Route exact path="/sell">
                <MenuBar />
                <Sell />
            </Route>
            <Route exact path="/offers" >
                <MenuBar />
                <MyOfferList />
            </Route>
            <Route exact path="/manual">
                <MenuBar />
                <Manual />
            </Route>
            <Route exact path="/termsAndConditions">
                <MenuBar />
                <TermsAndConditions />
            </Route>
            <Route exact path="/disclaimer">
                <MenuBar />
                <Disclaimer />
            </Route>
            <Route exact path="/listing">
                <MenuBar />
                <ListingTermsAndCondition />
            </Route>


            <PrivateRoute exact path="/orders">
                <MenuBar />
                <MyOrderList />
            </PrivateRoute>
            <PrivateRoute exact path="/offers/add">
                <MenuBar />
                <AddOffer />
            </PrivateRoute>
            <PrivateRoute exact path="/processOrder/:id" >
                <MenuBar />
                <ProcessOrder />
            </PrivateRoute>
            <PrivateRoute exact path="/orderDetail/:id">
                <MenuBar />
                <OrderDetail />
            </PrivateRoute>

            <PrivateRoute exact path="/appeal/:id">
                <MenuBar />
                <Appeal />
            </PrivateRoute>

            <PrivateRoute exact path="/orderAction/:id">
                <MenuBar />
                <OrderAction />
            </PrivateRoute>
            {/* <PrivateRoute path="/dashboard">
                <MenuBar />
                <Dashboard />
            </PrivateRoute> */}
            <PrivateRoute path="/offers">
                <MenuBar />
                <Offers />
            </PrivateRoute>
            <PrivateRoute path="/trades">
                <MenuBar />
                <OffersTrades />
            </PrivateRoute>
            <Route exact path="/logout" component={ Logout } />
            <Route exact >
                <MenuBar />
                <PageNotFound />
            </Route>
        </Switch>
    );
};

export default Routes;
