import React from 'react';
import {
  Row, Col, Form, Input,
  Select
} from 'antd';
import { countries } from '../../../../utils/countries';

const { Option } = Select;

const BankTransferForm = ({ setState, state, t}) => {
  const onChange = (e) => {
    const { name, value } = e.target;
    setState({ ...state, [name]: value?.trim() });
  };

  const onSelect = (value) => {
    const country = countries.find(c => c.name === value);
    setState({ ...state, countryCode: country.isoAlpha2, currencyId: country.currency.code  });
  }

  return (
    <>
      <Col xxl={12} lg={12} md={24} sm={24} xs={24}>
        <Form.Item
          label={t('Select Country') + ':'}
          rules={[
            {
              required: true,
              message: t('Please Select payment a channel'),
            },
          ]}>
          <Select
            name="country"
            size='large'
            onChange={(value) => onSelect(value)}
            showSearch
            placeholder='Select'>
            {countries.map((item) => (
              <Option key={item.name} className='option'>
                {item.name}
              </Option>
            ))}
          </Select>
        </Form.Item>
      </Col>
      <Col xxl={ 24 } lg={ 16 } md={ 24 } sm={ 24 } xs={ 24 }>
        <Form.Item
          label={t('Bank acount name')+':'}
          rules={ [
            {
              required: true,
              message: t('Please enter name on account'),
            },
          ] }
        >
          <Input name='name' onChange={ onChange } size="large"
          />
        </Form.Item>
      </Col>
      <Col xxl={ 16 } lg={ 16 } md={ 24 } sm={ 24 } xs={ 24 }>
        <Form.Item
          label={t('Bank account number')+':'}
          rules={ [
            {
              required: true,
              message: t('Please enter Bank account number'),
            },
          ] }
        >
          <Input name='destinationAccount' onChange={ onChange } size="large"
          />
        </Form.Item>
      </Col>
      <Row gutter={ 16 }>
        <Col xxl={ 12 } lg={ 12 } md={ 24 } sm={ 24 } xs={ 24 }>
          <Form.Item
            label={t('Bank') +':'}
            rules={ [
              {
                required: true,
                message: t('Please enter Bank name'),
              },
            ] }
          >
            <Input name='bankName' onChange={ onChange } size="large"
            />
          </Form.Item>
        </Col>
        <Col xxl={ 12 } lg={ 12 } md={ 24 } sm={ 24 } xs={ 24 }>
          <Form.Item
            label={t('Branch (Optional)') +':'}
          >
            <Input name='accountOpeningBranch' onChange={ onChange } size="large"
            />
          </Form.Item>
        </Col>
      </Row>
    </>
  );
};
export default BankTransferForm;