import React from 'react';
import {
    Row,
    Col,
    Form,
    Input,
    Button,
} from 'antd';
import Warning from './warning';
import Modal from '../../../Components/Shared/Modal';
import Loading from '../../../Components/Shared/Loading';


const ConfirmModal = ({
    isModalVisible,
    handleOk,
    handleCancel,
    onChange,
    loading,
    button = '',
    assetCurrency = '',
    t
}) => {

    const [form] = Form.useForm();

    return (
        <Modal
            width={700}
            title={t('Confirm Successful Payment')}
            visible={isModalVisible}
            onOk={handleOk}
            onCancel={handleCancel}
            footer={[
                <Button key="back" type="secondary" onClick={handleCancel}>
                    {t('Cancel')}
                </Button>,
                <Button key="submit" disabled={loading} type="primary" onClick={handleOk}>
                    {loading ? <Loading /> : t('Confirm')}
                </Button>]}
        >
            <Row>
                <Form
                    form={form}
                    className='confirm'
                    style={{ width: '100%' }}
                    layout='vertical'
                    autoComplete='off'
                >
                    <Col span={24}>
                        <p>
                            {t(`Please confirm that you have transferred the correct amount to your peer by pasting the transaction ID bellow`)}
                        </p>

                        {`${t(`Enter`)} ${assetCurrency}  ${t('transaction ID')}`}: <br />
                        <Input autoComplete='off' name='txnID' onChange={onChange} size="large" />
                        <br /><br />
                        <Warning Text={() => (<p>{t(`${t('Ensure you have transferred the right amount to your peer. Do not click on')} "${button}" ${t('if you have not made the right payment to the provided account. The platform reserve the rights to claim any damages caused')}.`)}</p>)} />
                        <br />
                    </Col>
                </Form>
            </Row>
        </Modal>
    )
}

export default ConfirmModal
