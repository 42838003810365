import React, { useState, useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import { Navbar, Image } from 'react-bootstrap';
import {
  Dropdown, Spin, Menu,
  Divider, Drawer, Row, Col,
  Avatar, Statistic, Switch, Badge
} from 'antd';
import Button from '../Button';
import { TranslationOutlined } from '@ant-design/icons';
import { useTranslation } from 'react-i18next';
import "./navigationbar.less";
import img from '../../../assets/img/user.png';
import logo from '../../../assets/img/logo.png';
import brandText from '../../../assets/img/brand-text.png';
import { useStateValue } from '../../../Providers/stateProvider';

import { getPaymentChannelsData } from '../../../Providers/action/payments';
import { getProfileData } from '../../../Providers/action/profile';
import Type from '../../../Providers/reducers/user/type';
import i18nType from '../../../Providers/reducers/i18n/type';
import { clearToken } from '../../../Services/localStorageService';
import { userOrderData } from '../../../utils/sockets';

import { UserOutlined } from '@ant-design/icons';
import PaymentMethodList from './PaymentMethodList';
import PaymentMethodModal from './PaymentModal';
import { getPaymentData } from '../../../Providers/action/payments';
import { setOfflineStatus } from '../../../Providers/action/profile';
import PhoneModal from './PhoneModal';
import Notifications from './Notifications';
import { languageList } from './languageList';
import ErrorConfirmModal from './ErrorConfirmModal';
import DisclaimerModal from './DisclaimerModal';
import { getItem } from '../../../Services/localStorageService';

const Navigationbar = () => {
  const [isDrawerVisible, setIsDrawerVisible] = useState(false);
  const [isPaymentDrawerVisible, setIsPaymentDrawerVisible] = useState(false);
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [trigger, setTrigger] = useState(false);
  const [TandC, setTandC] = useState(false);

  const { t, i18n } = useTranslation();

  const [
    {
      i18n: defaultLanguage,
      user: { loggedIn },
      payments,
      profile = {},
      app: { current }
    }, dispatch] = Object.values(useStateValue());

  const history = useHistory();

  useEffect(() => {
    if (getItem('timbuktu_disclaimerAccepted')) {
      setTandC(true);
    }
    i18n.changeLanguage(defaultLanguage);
  }, [defaultLanguage, i18n, current, TandC]);

  useEffect(() => {
    if (loggedIn) {
      userOrderData(dispatch);
    }
  }, [dispatch, loggedIn]);

  const { paymentMethods = [] } = payments;

  const {
    email,
    firstName,
    imageThumbnail,
    lastName,
    mobile,
    username,
    contactPhone,
    loading: profileLoading,
    tradeStats,
    offline } = profile;

  const status = offline === 0 ? true : false;

  const showModal = () => {
    setIsModalVisible(true);
  };

  const handleCancel = () => {
    setIsModalVisible(false);
  };

  useEffect(() => {
    getPaymentChannelsData(dispatch);
    if (loggedIn) {
      getProfileData(dispatch);
      getPaymentData(dispatch);
    }
  }, [dispatch, loggedIn]);

  const showProfileDrawer = () => {
    setIsDrawerVisible(true);
  };

  const handleDrawerClose = () => {
    setIsDrawerVisible(false);
  };
  const showPaymentDrawer = () => {
    setIsPaymentDrawerVisible(true);
  };

  const handlePaymentDrawerClose = () => {
    setIsPaymentDrawerVisible(false);
  };

  const logout = async () => {
    clearToken();
    dispatch({
      type: Type.LOGOUT
    });
    history.push('/login');
  };

  const onChange = (value) => {
    setOfflineStatus(dispatch);
  };

  const addEdit = (text) => <span onClick={ () => setTrigger(true) } style={ { color: 'orange', cursor: 'pointer' } }>{ text }</span>;

  const profileData = (
    <>
      <Spin style={ { background: 'none !important' } } spinning={ profileLoading }>
        <Row>
          <Col span={ 24 } className='center-text'>
            <Avatar
              className='img-avatar'
              size={ { xs: 173, sm: 173, md: 173, lg: 173, xl: 173, xxl: 173 } }
              icon={ <UserOutlined /> }
              src={ <Image src={ !imageThumbnail ? <UserOutlined /> : imageThumbnail } /> }
              shape="square"
            />
          </Col>
        </Row>
        <Row>
          <Col span={ 24 }>
            <p className='center-text profile-p'>{ `${ firstName } ${ lastName }` }</p>
          </Col>
        </Row>
        <Row>
          <Col span={ 24 }>
            <p className='center-text profile-p-val'>{ username } </p>  {/* is ther eneed for this? ?.split('@')[0]  */ }
            <br />
            <br />
            <p style={ { textAlign: 'center' } }>
              <Switch onChange={ onChange } defaultChecked={ status } checkedChildren="Online" unCheckedChildren="Offline" />
            </p>
          </Col>
        </Row>
        <Divider />

        <Row className='stats-wrapper'>
          <Statistic title={ t('Trades') } value={ tradeStats?.trades } style={ {
            marginRight: 32,
          } } />
          <Statistic
            title={ t('Completion') }
            suffix="%"
            value={ tradeStats?.rank }
          />
        </Row>
        <Row>
          <Col span={ 24 }>
            <div style={ { color: '#3F3F3F', marginBottom: '20px' } }>{ t('Phone Number') }:
              &nbsp; <span className='profile-span'> { mobile } </span></div>
          </Col>
        </Row>
        <Row>
          <Col span={ 24 }>
            <div style={ { color: '#3F3F3F', marginBottom: '20px' } }>{ t('Contact Number') }:
              &nbsp; <span className='profile-span'> { contactPhone ? contactPhone : addEdit('add') } { contactPhone ? addEdit('edit') : null } </span></div>
          </Col>
        </Row>
        <Row>
          <Col span={ 24 }>
            <div style={ { color: '#3F3F3F' } }> { t('Email Address') }:
              &nbsp; <span className='profile-span'> { email } </span></div>
          </Col>
        </Row>
        <Divider />
      </Spin>
    </>
  );

  const paymentMethodData = (
    <>
      <Row style={ { marginBottom: '20px' } }>
        <Button key="1" type="primary" onClick={ showModal }>
          { t('Add Payment Method') }
        </Button>
      </Row>
      <Row>
        <PaymentMethodList t={ t } showModal={ showModal } paymentMethods={ paymentMethods } />
      </Row>
      <Divider />
    </>
  );

  const userData = (
    <Menu>
      {TandC ?
      <>
      <Menu.Item key="0" onClick={ showProfileDrawer }>
        { t('Profile') }
      </Menu.Item>
      <Menu.Item key="1" onClick={ showPaymentDrawer }>
        { t('Payment Methods') }
      </Menu.Item> 
      </>
      : null }
      <Menu.Divider />
      <Menu.Item key="3" onClick={ logout }>{ t('Log Out') }</Menu.Item>
    </Menu>
  );

  const setLanguage = (lang) => {
    dispatch({
      type: i18nType.CHANGE_DEFAULT_LANGUAGE,
      payload: lang.key
    });
  };

  const languages = (
    <Menu onClick={ setLanguage }>
      { languageList.map(l => (
        <Menu.Item key={ l.key }>
          { l.name }
        </Menu.Item>
      )) }
      <Menu.Divider />
    </Menu>
  );

  return (
    <>
      <Notifications t={ t } />
      { loggedIn ?  <DisclaimerModal t={ t } setTandC={setTandC} /> : null }
      { profileLoading ? null : <PhoneModal t={ t } manualCancel={ () => setTrigger(false) } loggedIn={ loggedIn } phone={ contactPhone } manualTrigger={ trigger } /> }
      <ErrorConfirmModal t={ t } />
      <Navbar bg="dark" variant="dark" expand="lg" style={ { justifyContent: 'space-between' } }>
        <Navbar.Brand className="brandName" href="#">
          <Image preview={ false } src={ logo } className="logo" alt="logo" />
          <span className='logo-text'><Image className='logo-text' src={ brandText } /></span>
        </Navbar.Brand>
        {/* {!loggedIn &&
          <Nav className="mr-auto hidden-md-nav">
            <Nav.Link href="/#/buy">Buy</Nav.Link>
            <Nav.Link href="/#/sell">Sell</Nav.Link>
          </Nav>} */}
        <div>
          { loggedIn ? <> <Badge status={ status ? 'success' : 'default' } /><span className="hidden-md-nav" style={ { color: '#ffffff' } }>{ status ? 'online' : 'offline' }</span> </> : null }
          { loggedIn ? null : <Button href='/#/login' style={ { marginRight: '20px', marginLeft: '10px' } } type='secondary'>{ t('Log In') }</Button> }
          { loggedIn ?
            <Dropdown overlay={ userData }>
              <Image
                preview={ false }
                src={ img }
                // onClick={e => e.preventDefault()}
                style={ { marginRight: '15px', marginLeft: '10px' } }
                className="userIcon" alt="user"
              />
            </Dropdown>
            : null }
          <Dropdown overlay={ languages } placement="bottomLeft" trigger={ ['click'] }>
            <TranslationOutlined style={ { fontSize: '32px', color: '#ffffff' } } onClick={ e => e.preventDefault() } />
          </Dropdown>
        </div>
      </Navbar>
      <Drawer
        style={ { paddingTop: '70px' } }
        title="My Profile"
        width={ 400 }
        placement="right"
        closable={ true }
        onClose={ handleDrawerClose }
        visible={ isDrawerVisible }
      >
        { profileData }
      </Drawer>
      <Drawer
        style={ { paddingTop: '70px' } }
        title="Payment Methods"
        width={ 400 }
        placement="right"
        closable={ true }
        onClose={ handlePaymentDrawerClose }
        visible={ isPaymentDrawerVisible }
      >
        { paymentMethodData }
      </Drawer>

      <PaymentMethodModal isModalVisible={ isModalVisible } handleCancel={ handleCancel } />
    </>
  );
};

export default Navigationbar;
