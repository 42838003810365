import React, { useEffect } from 'react';
import {
  Modal
} from 'antd';
import { useStateValue } from '../../../Providers/stateProvider';

const { error } = Modal;

const ConfirmError = ({ t }) => {

  const [
    {
      error: {
        errorTitle,
        message,
        showErrorModal
      },
    }, dispatch] = Object.values(useStateValue());

  const showConfirm = () => {
    error({
      title: t(errorTitle),
      content: message || 'Something went wrong, please contact support.'
    });
  }

  //offer notification trigger
  useEffect(() => {
    if (showErrorModal) {
      showConfirm();
      dispatch({
        type: 'SET_CONFIRM_ERROR',
        payload: false,
        message: ''
      })
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [showErrorModal, t, message])

  return (
    <div>
    </div>
  )
}

export default ConfirmError;
