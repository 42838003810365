import React, { useState, useEffect } from 'react';
import {
    Row,
    Col,
    Input,
    Button,
} from 'antd';
import { useHistory } from 'react-router-dom'
import Modal from '../../../Components/Shared/Modal';
import { useStateValue } from '../../../Providers/stateProvider';
import Loading from '../../Shared/Loading';

const ReleaseModal = ({ 
    t,
    showReleaseModal,
    offerAssetId,
    handleCancel,
    confirmReleaseAsset,
    reject=true,
    confirmCancelOrder,
    user,
}) => {
    const initialState = {
        code: 123456,
        codeValid: false,
        codeInput: 123456
    }

    const [state, setState] = useState(initialState);
    const history = useHistory();

    const [{ order }] = Object.values(useStateValue());
    const { loading } = order;

    const onChange = (e) => {
        const { value } = e.target;
        setState({ ...state, codeInput: value });
    }

    const handleOk = async () => {
        setState({ ...state, codeValid: false });
        if (reject) {
            await confirmCancelOrder();
            if (user === 'maker'){
                history.push('/trades');
            } else {
                history.push('/orders');
            }
        } else {
            await confirmReleaseAsset();
        }
    }

    useEffect(() => {
        const code = Math.floor(100000 + Math.random() * 900000);
        setState({ ...state, code });
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [Math, showReleaseModal]);

    return (
        <Modal
            width={700}
            title={t(reject ? 'Confirm order cancellation' :'Confirm asset release')}
            visible={showReleaseModal}
            onOk={handleOk}
            onCancel={handleCancel}
            footer={[
                <Button key="back" type="secondary" onClick={handleCancel}>
                    {t('Cancel')}
                </Button>,
                <Button key="submit" disabled={!(state.code === Number(state.codeInput))} type="primary" onClick={handleOk}>
                    {loading ? <> <Loading />...</> : t('Confirm')}
                </Button>]}
        >
            <Row>
                <Col span={24}>
                    { !reject ?
                    <p style={{ fontSize: '20px', textAlign: 'center' }}>
                        {t('Are you sure you want to release')} {offerAssetId} {t('asset from escrow')}?
                    </p> :  <p style={{ fontSize: '20px', textAlign: 'center' }}>
                        {t('Are you sure you want to cancel this order?')}?
                    </p>}
                    <p style={{ textAlign: 'center' }}>{t('Enter the code below to proceed')}</p>

                    <h1 className="confirm-code">{state.code}</h1>
                    <Input name='codeInput' placeholder={t("Enter the code above to proceed")} onChange={onChange} size="large" />
                    <br /><br />
                    <br />
                </Col>
            </Row>
        </Modal>
    )
}

export default ReleaseModal
