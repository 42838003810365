import React from 'react';
import { Table, Spin, Pagination } from 'antd';
import './table.less';

const TableComponent = ({
  tableProps,
  loading,
  expandable,
  onShowSizeChange,
  onChange,
  data,
}) => {

  return (
    <div className='table-wrapper'>
      <Spin spinning={loading}>
        <Table
          {...tableProps}
          expandable={{ expandable }}
          pagination={false}
        />
      </Spin>
      <br />
      <div style={{ textAlign: 'right' }}>
        <Pagination
          total={data?.totalRecords}
          onChange={onChange}
          showSizeChanger
          onShowSizeChange={onShowSizeChange}
          showTotal={total => `Total ${total} items`}
        />
      </div>
    </div>
  );
};

export default TableComponent;
