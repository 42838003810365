const userType = {
    INITIAL_LOGIN_REQUEST: 'INITIAL_LOGIN_REQUEST',
    INITIAL_LOGIN: 'INITIAL_LOGIN',
    VERIFY_LOGIN_REQUEST: 'VERIFY_LOGIN_REQUEST',
    LOOP_REQUEST: 'LOOP_REQUEST',
    LOGIN_USER: 'LOGIN_USER',
    LOGIN_ERROR: 'LOGIN_ERROR',
    SET_USER_ONLINE_STATUS: 'SET_USER_ONLINE_STATUS',
    LOGOUT: 'LOGOUT',
    SET_TRADE_CONTACT: 'SET_TRADE_CONTACT',
    SET_NOTIFICATION: 'SET_NOTIFICATION',
}

export default userType;
