import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Row, Col, Form, Select } from 'antd';
import { useStateValue } from '../../Providers/stateProvider';
import OfferForm from '../../Components/OfferForm/index';
import EmptyComponent from '../Dashboard/empty';

import './offers.less';

const { Option } = Select;

const AddOffer = () => {
  // const [isActive, setActive] = useState(true);
  const [type, setType] = useState('buy');
  const [asset, setAsset] = useState('XBN');
  const [form] = Form.useForm();

  const [, dispatch] = Object.values(useStateValue());

  useEffect(() => { 
    dispatch({ type: 'SET_PAGE_CURRENT', payload: 'offers/add' });
  }, [dispatch]);

  const toggleClass = (type) => {
    // setActive(false);
    setType(type);
  };
  const { t, i18n } = useTranslation();
  const [{ i18n: defaultLanguage, payments, profile: { kycLevel }, }] = Object.values(useStateValue());

  useEffect(() => {
    i18n.changeLanguage(defaultLanguage);
  }, [defaultLanguage, i18n]);

  const { assets } = payments;

  const selectAsset = (id) => {
    setAsset(id);
  };

  return (
    <div className="container" style={ { background: '#ffffff' } }>
    { kycLevel > 0 ?
      <>

      <h3 style={{ paddingBottom: '20px', paddingTop: '30px' }}>{ t("Create an Offer") }</h3>
      <Row
        style={{
          margin: '10px 0',
        }}
        type='flex'>
        <Col xxl={ 6 } lg={ 6 } md={ 12 } sm={ 24 } xs={ 24 }>
          <Form
            form={ form }
            className='add-offer'
            layout='vertical'
          //   onFinish={handleSubmit}
          >
            <Form.Item
              label="Select Asset"
              rules={ [{ required: true, message: t('Please select an asset!') }] }
              name={ [t('Total amount of asset to buy') + ':', 'asset'] }
            // noStyle
            >
              <Select
                size="large"
                defaultValue="XBN"
                onChange={ selectAsset }
                placeholder="Select"
              >
                { assets.map((item) => (
                  <Option key={ item.id } className='option'>
                    { item.id }
                  </Option>
                )) }
              </Select>
            </Form.Item>
          </Form>
        </Col>
      </Row>

      <Row className='offer-header-wrapper' >
        <Col xs={ 24 } md={ 12 } xl={ 12 }><div onClick={ () => toggleClass('buy') } className={ type === 'buy' ? 'offer-inner-header' : 'offer-inner-header-bg' }>{ t('I want to buy') } { asset } </div></Col>
        <Col xs={ 24 } md={ 12 } xl={ 12 }><div onClick={ () => toggleClass('sell') } className={ type === 'sell' ? 'offer-inner-header' : 'offer-inner-header-bg' }>{ t('I want to sell') } { asset } </div></Col>
      </Row>
      <OfferForm type={ type } asset={ asset } />
      </>
      : 
      <Row
        style={{
          margin: '10px 0',
        }}
        type='flex'>
        <Col>
        <div style={ { minHeight: '100vh' } }>
          <EmptyComponent t={t} />
        </div>
        </Col>
      </Row>
      }
    </div>
  );
};

export default AddOffer;
