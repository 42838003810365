/* eslint-disable no-unused-vars */
import React, { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import {
    Row,
    Col,
    Button,
    Statistic,
    Image,
    Spin,
} from 'antd';
import ReactTooltip from 'react-tooltip';
import { CheckCircleFilled, SyncOutlined, CloseCircleOutlined } from '@ant-design/icons';
import moment from 'moment';
import Icon from '@ant-design/icons';
import Warning from './warning';
import Loader from '../../Shared/Loading';
import { CopyToClipboard } from 'react-copy-to-clipboard';
import { useStateValue } from '../../../Providers/stateProvider';
import {
    deleteOrder,
    getUserOrderDetails,
    takerComfirmDeposit,
    makerComfirmDeposit,
    takerCompleteOrder,
    makerCompleteOrder,
    confirmMakerFiatDeposit,
    confirmTakerFiatDeposit,
} from '../../../Providers/action/order';
import { decode64 } from '../../../utils';
import ConfirmMoal from './confirmModal';
import ReleaseModal from './releaseModal';
import AppealOrder from './appealModal';
import commaNumber from 'comma-number';
import { copy } from '../icons';

const { Countdown } = Statistic;

const ConfirmOrderPayment = ({ t }) => {
    const [{ order, app: { current } }, dispatch] = Object.values(useStateValue());
    const [cancel, setCancel] = useState(false);
    const [confirmed, setConfirmed] = useState(false);
    const [txnid, setTxnid] = useState('');
    const tradesQuery = window.location.hash.split('?trades=');
    const [isModalVisible, setIsModalVisible] = useState(false);
    const [showReleaseModal, setShowReleaseModal] = useState(false);
    const [allowToCancel, setAllowToCancel] = useState(false);
    const [allowToAppeal, setAllowToAppeal] = useState(false);
    const [hideQR, setHideQR] = useState(false);
    const [reject, setReject] = useState(false);
    const [appeal, setAppeal] = useState(false);
    const [showAppealModal, setShowAppealModal] = useState(false);
    const [hideConfirmPay, setHideConfirmPay] = useState(false);
    const [isCopied, setIsCopied] = useState(false);
    const [orderDetails, setOrderDetails] = useState({});

    const { orderDetails: stateOrderDetails, loading } = order;

    useEffect(() =>{
        // window.location.reload();
        setOrderDetails(stateOrderDetails);
        // setAllowToAppeal(false);
        // setAllowToCancel(false);
        // setHideConfirmPay(false);
        // setCancel(false);
        // setAppeal(false);
        // setReject(false);
        // setHideQR(false);

    }, [current, stateOrderDetails]);

    const {
        orderType, offerAssetId,
        orderStatusId, id: orderId,
        qrCode: payQrCode = '',
        dynamicLink, offerType = '',
        createdAt, offerAssetPrice,
        user, offerCurrencyId, orderAmount,
        takerPaymentChannelId, takerPaymentMethodBankName,
        takerPaymentMethodDestinationAccount,
        takerPaymentMethodName, orderCurrencyAmount, offerCurrencyPaymentMethod,
        orderAssetReleaseExplorerLink,
        expiresAt, offerMaker, offerTaker,
        fiatDepositTransactionId, offerTakerPhone,
        offerMakerPhone,
        cancelAfter, inAppeal, offerTakerFullName,
        offerMakerFullName,
    } = orderDetails;

    const showModal = () => {
        setIsModalVisible(true);
    };

    const confirmDeposit = async () => {
        if (tradesQuery.length === 1) {
            await takerComfirmDeposit(dispatch, { transactionID: txnid, orderId });
        } else if (tradesQuery.length === 2) {
            await makerComfirmDeposit(dispatch, { transactionID: txnid, orderId });
        }
    };

    const confirmFiat = async () => {
        if (tradesQuery.length === 1) {
            await confirmTakerFiatDeposit(dispatch, { fiatDepositTransactionId: txnid, orderId });
        } else if (tradesQuery.length === 2) {
            await confirmMakerFiatDeposit(dispatch, { fiatDepositTransactionId: txnid, orderId });
        }

    };

    const confirmReleaseAsset = async () => {
        if (tradesQuery.length === 1) {
            await takerCompleteOrder(dispatch, { orderId });
        } else if (tradesQuery.length === 2) {
            await makerCompleteOrder(dispatch, { orderId });
        }
        setShowReleaseModal(false)
    };

    const releaseAsset = async () => {
        setShowReleaseModal(true)
    };

    const handleOk = async () => {
        // check status code to call maker or taker action.
        if (orderStatusId === 3 || orderStatusId === 6) {
            await confirmFiat();
        }

        if (orderStatusId === 2 || orderStatusId === 5) {
            await confirmDeposit();
        }

        setIsModalVisible(false);
    };

    const handleCancel = () => {
        setIsModalVisible(false);
    };

    const onChange = (e) => {
        const { value } = e.target;
        setTxnid(value);
    };

    const { id } = useParams();
    const decodedId = decode64(id);

    useEffect(() => {
        let query = { orderId: decodedId || orderId };
        if (tradesQuery.length === 1) {
            query['type'] = 'orders';
        } else if (tradesQuery.length === 2) {
            query['type'] = 'trades';;
        }
        getUserOrderDetails(dispatch, query);
        if (orderStatusId > 1) {
            setConfirmed(true);
        }
        hideQRTimeout();
        const d = new Date(cancelAfter);
        const now = new Date();
        console.log(now > d, '<<<<< date time  here.....');
        if (now > d){
            setAllowToAppeal(true);
            setAllowToCancel(true);
            setHideConfirmPay(true);
        } 
        else {
            setAllowToAppeal(false);
            setAllowToCancel(false);
            setHideConfirmPay(false);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [orderStatusId, dispatch, current]);

    const hideQRTimeout = () => {
        if ([5, 2].includes(orderStatusId)) {
            console.log('starting timer now ....');
            setTimeout(() => {
                setHideQR(true);
            }, 45000);
        }
    }

    const onCopyText = () => {
        setIsCopied(true);
        setTimeout(() => {
          setIsCopied(false);
        }, 1000);
      };


    const onFinish = () => {
        setCancel(true);
    };

    const getUpdates = () => {
        let query = { orderId: decodedId || orderId };
        if (tradesQuery.length === 1) {
            query['type'] = 'orders';
        } else if (tradesQuery.length === 2) {
            query['type'] = 'trades';;
        }
        getUserOrderDetails(dispatch, query);
        setHideQR(false)
        hideQRTimeout()
    }

    const isCrypto = (assetId) => { 
        const assetList = ['USDT-BEP2', 'USDT-ERC-20', 'USDT-TRC-20', 'Bantu Public Key'];
        return assetList.includes(assetId);
    }

    const Copy = props => <Icon component={ copy } { ...props } />;
    // const QRCode = props => <Icon component={ qrCode } { ...props } />;

    const warningText = () => {
        return <div>
            <b>{t('Send only')} {offerCurrencyId} {t('to payment details provided above')}.</b> <br />
        </div>;
    };

    // show qr code if orderStatusID is 5 and orderType is Buy or orderStatusID 2 and orderType is Sell
    const paymentQRCode = () => (
        <Col lg={12}>
            {!hideQR ?
                <>
                    <Col lg={12}>
                        <p>
                            {t('Scan QR code with your BantuPay wallet to make payment')} <span className="hide-lg"></span>
                        </p>
                        <Image preview={false} style={{ width: '100%' }} src={payQrCode} alt="payment QRCode" />
                    </Col>
                    <Col className="hide-lg" span={24} style={{ textAlign: 'center' }}>
                        <Button onClick={() => window.open(dynamicLink, '_blank')} className="loginbtn">{t('Tap to pay with BantuPay')}</Button>
                        <br />
                        <br />
                    </Col>
                </>
                :
                <Col className="hide-lg" span={24} style={{ textAlign: 'center' }}>
                    <p>

                    </p>
                </Col>
            }
        </Col>
    );

    const paymentAddress = () => (
        <Col lg={12}>
            {orderStatusId === 6 ?
                <>
                    {!isCrypto(takerPaymentChannelId) ?  
                    <p>{t("Please ensure that the bank account name you are making payment to matches")}  {user === 'maker' ? offerTaker : offerMaker}'s {t("real name displayed on this order")}</p>
                    : null}
                    <p>
                        {t('Please carefully transfer the correct amount to the seller using the following payment method provided')}
                    </p> 
                    <p>
                        {t('Seller’s Payment Method')}: <span className="payment-method">{takerPaymentChannelId}</span>
                    </p>
                    <p>
                        {takerPaymentMethodBankName} <br />
                        {takerPaymentMethodName} <br />
                        <span style={{ overflowWrap: 'break-word' }}> {takerPaymentMethodDestinationAccount}</span> &nbsp;
                        <CopyToClipboard text={ takerPaymentMethodDestinationAccount } onCopy={ onCopyText }>
                            { isCopied ? <span>copied!</span> : <Copy /> }
                            {/* <ReactTooltip type="dark" id="username" place="top" effect="solid">
                            <span>{ isCopied ? "copied!" : "Copy username" }</span>
                          </ReactTooltip> */}
                        </CopyToClipboard>
                    </p>
                    <p>
                    </p> &nbsp;
                    <Warning Text={() => warningText()} />
                    <br />
                    <p>
                        <h5>
                            <span style={{ color: '#D91019' }}>
                                {/* show contact here? at what point exactly???  */}
                                <Countdown onFinish={() => setHideConfirmPay(true)} prefix={t('Payment to be made in')} value={moment(cancelAfter)} />
                            </span>
                        </h5>
                        {t(`Please make your payment within the time indicated above, otherwise your order will be cancelled. We strongly advise that you do not proceed with making the payment once the time ends. Kindly cancel the transaction once the time gets to 00.00.00, otherwise you stand the risk of losing your fund`)}.
                    </p>
                </>
                :
                <>
                    {!isCrypto(offerCurrencyPaymentMethod?.paymentChannelId) ?  
                    <p>{t("Please ensure that the bank account name you are making payment to matches")}  {user === 'maker' ? offerTaker : offerMaker}'s {t("real name displayed on this order")}</p>
                    : null}
                    <p>
                        {t('Please carefully transfer the correct amount to the seller using the following payment method provided')}
                    </p>
                    <p>
                        {t('Seller’s Payment Method')}: <span className="payment-method">{offerCurrencyPaymentMethod?.paymentChannelId}</span>
                    </p>
                    <p>
                        {offerCurrencyPaymentMethod?.name} <br />
                        <span style={{ overflowWrap: 'break-word' }}> {offerCurrencyPaymentMethod?.destinationAccount}</span> &nbsp;

                        <CopyToClipboard text={ offerCurrencyPaymentMethod?.destinationAccount } onCopy={ onCopyText }>
                            <Copy />
                        </CopyToClipboard>
                        <br />
                        {offerCurrencyPaymentMethod?.bankName} <br />
                        {offerCurrencyPaymentMethod?.memo} <br />
                    </p>
                    &nbsp;
                    <Warning Text={() => warningText()} />
                    <br />
                    <p>
                        <h5>
                            <span style={{ color: '#D91019' }}>
                                <Countdown onFinish={() => setHideConfirmPay(true)}  prefix={t('Payment to be made in')} value={moment(cancelAfter)} />
                            </span>
                        </h5>
                        {t(`Please make your payment within the time indicated above, otherwise your order will be cancelled. We strongly advise that you do not proceed with making the payment once the time ends. Kindly cancel the transaction once the time gets to 00.00.00, otherwise you stand the risk of losing your fund`)}.

                    </p>
                </>
            }
        </Col>
    );

    const awaitingFiat = (orderType) => (
        <Col lg={12}>
            <h4>{t('Waiting for')} {user === 'taker' ? offerMaker : offerTaker}'s {t('payment')}</h4>
            {!isCrypto( orderStatusId === 6? takerPaymentChannelId : offerCurrencyPaymentMethod?.paymentChannelId) ?  
                <p>{t("Please ensure that the bank account name you are making payment to matches")}  {user === 'maker' ? offerTaker : offerMaker}'s {t("real name displayed on this order")}</p>
            :null}

            <p>{t("Please ensure you have received")} {offerCurrencyId} {t('payment confirming payment')}</p>
            <br/>
            <br/>
            <br/>
            <br/>
            <br/>
            <p>
                <h5>
                    <span style={{ color: '#D91019' }}>
                        {/* show contact here? at what point exactly???  */}
                        <Countdown prefix={t('Payment to be made in')} onFinish={() => setAllowToCancel(true)} value={moment(cancelAfter)} />
                    </span>
                </h5>
                {t('You will be allowed to cancel the order after the wait time elapses')}.
            </p>
        </Col>
    );

    const awaitingFiatReceipt = (orderType) => (
        <Col lg={12}>
            <h4>{user === 'taker' ? offerMaker : offerTaker} is {t('waiting for')} {t('you to confirm payment receipt')}</h4>
            <br/>
            <p>
                <span style={{ color: 'red', fontSize: '16px' }}>Attention!!!</span> <br/><br/>Please be sure to log in to your bank account or blockchain wallet to confirm
                that you have actually received the payment and it is sitting in your bank account or
                blockchain wallet before confirming payment receipt.
                <br/>
                <br/>
                <ul>
                    <li>I confirm that the payment has been successfully received with the correct amount and sender’s information.</li>
                </ul>
            </p>
            <Countdown prefix={t('Payment to be made in')} onFinish={() => setAllowToAppeal(true)} value={moment(cancelAfter)} />
        </Col>
    );

    const expiredCancledRejected = (orderType) => (
        <Col lg={12}>
            <h4>
                {orderStatusId === 8 ? t(`Canceled Order`) : null}
                {orderStatusId === 9 ? t('Canceled Order') : null}
                {orderStatusId === 10 ? t('Expired Order') : null}
            </h4>
        </Col>
    );

    const awaitingEscrowDeposit = () => (
        <Col lg={12}>
            <h4>{t('Waiting for')}  {user === 'taker' ? offerMaker : offerTaker}'s {t('escrow deposit')}</h4>
        </Col>
    );

    const awaitingFiatRelease = () => (
        <>
            <Col lg={12}>
                <h4>{t('Waiting for')} {user === 'taker' ? offerMaker : offerTaker} {t('to confirm payment receipt')}</h4>
                <Countdown value={moment(cancelAfter)} onFinish={() => setAllowToAppeal(true)} />
            </Col>
        </>
    );

    const cancelOrder = async () => {
        setReject(true);
        setShowReleaseModal(true)
    };

    const confirmCancelOrder = async () => {
        await deleteOrder(dispatch, { orderId });
        setShowReleaseModal(false)
    }

    const releaseAppeal = () => (
        <>
            <Button disabled={loading} style={{ width: '60%', background: '#008751', color: 'white', borderRadius: '7px' }} onClick={releaseAsset} >
                {loading ? <><Loader /> Releasing... </> : `${t('I have received')} ${offerCurrencyId}`}
            </Button>
            &nbsp;&nbsp;&nbsp;
            {allowToAppeal ?
                inAppeal < 1 ?
                    <Button style={{ width: '35%', border: '0' }} onClick={() => appealOrder()} >{t('Appeal')}</Button>
                :
                    <Button style={{ width: '35%', border: '0' }} disabled >{t('Appeal in progress')}</Button>
            : null}
        </>);

    const cancelAppeal = () => (
        <>
        <Button disabled={loading} style={{ width: '60%', background: 'red', color: 'white', borderRadius: '7px' }} onClick={() => cancelOrder()} >
            {loading ? <><Loader /> Cancelling... </> : msgNot}
        </Button>
        &nbsp;&nbsp;&nbsp;
        {allowToAppeal ?
            inAppeal < 1 ?
        <Button style={{ width: '35%', border: '0' }} onClick={() => appealOrder()} >{t('Appeal')}</Button>
        :
            <Button style={{ width: '35%', border: '0' }} disabled >{t('Appeal in progress')}</Button>
        : null}
    </>
    );


    const assetCurrency = `${orderStatusId === 2 ?
        orderType === 'Sell' ?
            offerCurrencyId : offerAssetId : ''}
    ${orderStatusId === 6 ?
            orderType === 'Sell' ?
                offerCurrencyId : offerAssetId : ''}
    ${orderStatusId === 5 ?
            orderType === 'Sell' ?
                offerAssetId : offerCurrencyId : ''} 
    ${orderStatusId === 3 ?
            orderType === 'Sell' ?
                offerAssetId : offerCurrencyId : ''}`;

    const msg = `${t('I have sent')} ${assetCurrency}`;
    const msgNot = `${t('I did not send')} ${offerCurrencyId}`;
    const confirmPayment = () => (
        <>

        {[2, 5].includes(orderStatusId) ?
            <Button style={{ width: '70%', background: '#F86912', color: 'white', borderRadius: '7px' }} onClick={showModal} >
                {msg}
            </Button> 
        : 
        allowToAppeal ?
            inAppeal < 1 ?
                <Button style={{ width: '70%', border: '0', background: '#F86912', color: '#fff' }} className="primary" onClick={() => appealOrder()} >{t('Appeal')}</Button>
            :
            <Button style={{ width: '35%', border: '0' }} disabled >{t('Appeal in progress')}</Button>
        : 
        <Button style={{ width: '70%', background: '#F86912', color: 'white', borderRadius: '7px' }} onClick={showModal} >
            {msg}
        </Button> }

            &nbsp;&nbsp;&nbsp;
            <Button style={{ width: '25%', border: '0' }} onClick={() => cancelOrder()}>{t('Cancel')}</Button>
        </>
    );

    const completed = () => (
        <>
            <Col lg={12} md={24} sm={24} xs={24}>
                <div style={{ textAlign: 'center', margin: '72px' }} >
                    <CheckCircleFilled style={{ fontSize: '100px', paddingBottom: '30px' }} /><h4>{t('Order Completed')}</h4></div>
            </Col>
        </>
    );

    const rejected = () => (
        <>
            <Col lg={12} md={24} sm={24} xs={24}>
                <div style={{ textAlign: 'center', margin: '72px' }} >
                    <CloseCircleOutlined className="rejected" style={{ fontSize: '100px', paddingBottom: '30px' }} /><h4>{t('Order Rejected')}</h4></div>
            </Col>
        </>
    );

    const cancelButton = () => (
        <Button style={{ width: '100%', border: '0', color: 'white', background: '#f32013' }} onClick={() => cancelOrder()}>
            {loading ? <><Loader /> {t('Cancelling')}... </> : 'Cancel Order'}
        </Button> 
    );

    const appealOrder = () => { 
        setAppeal(true);
        setShowAppealModal(true)
    }

    const handleCancelAppeal = () => { 
        setShowAppealModal(false)
    }

    return (
        <Spin spinning={loading}>
            <AppealOrder
                t={t}
                showAppealModal={showAppealModal}
                handleCancelAppeal={handleCancelAppeal}
                orderId={id}
                maker={user === 'maker'}
            />
            <ReleaseModal
                t={t}
                handleCancel={() => setShowReleaseModal(false)}
                confirmReleaseAsset={confirmReleaseAsset}
                offerAssetId={offerAssetId}
                showReleaseModal={showReleaseModal}
                reject={reject}
                confirmCancelOrder={confirmCancelOrder}
                user={user}
            />
            <Row>
                <Col md={24} lg={24} className="confirm-payment">
                    <Row className="row-top">
                        <Col xs={24} md={8}>
                            <h2 className="title">
                                {t(user === 'maker' ? offerType : orderType)} {offerAssetId}
                            </h2>
                        </Col>
                        {hideQR ? orderStatusId < 8 ?
                            <Col xs={24} md={8} style={{ textAlign: 'right' }}>
                                <span style={{ color: 'red', marginRight: '3%' }}>Click to get latest order update</span> <SyncOutlined onClick={getUpdates} title="Get order updates" style={{ cursor: 'pointer', fontSize: '30px' }} size="large" />
                            </Col> : <Col xs={24} md={8} style={{ textAlign: 'right' }} /> : <Col xs={24} md={8} style={{ textAlign: 'right' }} />}
                        <Col xs={24} md={8}>
                            <p className="title" style={{ textAlign: 'right'}}>
                               <a href="#/buy">Back to Home</a>
                            </p>
                        </Col>
                    </Row>
                    <Row>
                        {orderStatusId < 2 ?
                            <Col lg={12}>
                                <Col span={24}>
                                    <Countdown title={`${t('Awaiting order confirmation from')}  ${user === 'taker' ? offerMaker : offerTaker}`} value={moment(expiresAt)} onFinish={onFinish} />
                                </Col>
                            </Col> : null}

                        {loading ? <Col lg={12}>
                            <Col span={12}>

                            </Col>
                        </Col>
                            : null}
                        {orderStatusId === 5 && orderType === 'Sell' && user === 'taker' ? paymentQRCode() : null}

                        {orderStatusId === 5 && orderType === 'Sell' && user === 'maker' ? awaitingEscrowDeposit() : null}

                        {orderStatusId === 2 && orderType === 'Buy' && user === 'maker' ? paymentQRCode() : null}

                        {orderStatusId === 2 && orderType === 'Buy' && user === 'taker' ? awaitingEscrowDeposit() : null}

                        {orderStatusId === 3 && orderType === 'Buy' && user === 'taker' ? paymentAddress() : null}

                        {orderStatusId === 3 && orderType === 'Buy' && user === 'maker' ? awaitingFiat('Buy') : null}

                        {orderStatusId === 6 && orderType === 'Sell' && user === 'taker' ? awaitingFiat('Buy') : null}

                        {orderStatusId === 6 && orderType === 'Sell' && user === 'maker' ? paymentAddress() : null}

                        {orderStatusId === 7 && user === 'taker' ? awaitingFiatReceipt('Buy') : null}

                        {/* relse payment here  */}
                        {orderStatusId === 7 && orderType === 'Sell' && user === 'maker' ? awaitingFiatRelease() : null}

                        {orderStatusId === 4 && user === 'maker' ? awaitingFiatReceipt('Buy') : null}

                        {orderStatusId === 4 && user === 'taker' ? awaitingFiatRelease() : null}

                        {[8, 9, 10].includes(orderStatusId) ? expiredCancledRejected() : null}

                        {orderStatusId === 11 ? completed() : null}

                        {orderStatusId === 12 ? rejected() : null}


                        <Col xs={24} lg={12}>
                            <div style={{ margin: '0 5%' }}>
                                <p className="orderDetails">
                                    {t('Order ID')}: <b>{orderId} &nbsp;</b>
                                    {/* <Copy style={ { fontSize: '5px' } } /> */}
                                </p>
                                <p className="orderDetails">{t('Created Time')}: <b>{moment(createdAt).format('Do MMMM YYYY - hh:MM a')}</b> </p>
                                <p className="orderDetails">{t('Price')}: <b>{commaNumber(offerAssetPrice)} {offerCurrencyId}</b> </p>
                                {user === 'maker' ?
                                    offerType === 'Sell' ?
                                        <>
                                            <p className="orderDetails">
                                                {t('Amount')} {orderStatusId === 11 ? t('sent') : t('to send')}: { orderAssetReleaseExplorerLink ? <a href={orderAssetReleaseExplorerLink} target="_blank" rel="noreferrer">   <b> {commaNumber(orderAmount)} </b> {offerAssetId}</a> : <> <b> {commaNumber(orderAmount)} </b> {offerAssetId}</>}
                                            </p>
                                            <p className="orderDetails">
                                                {t('Amount')} {orderStatusId === 11 ? t('received') : t('to receive')}: <b> {commaNumber(orderCurrencyAmount)} </b> {offerCurrencyId}
                                            </p>
                                        </> :
                                        <>
                                            <p className="orderDetails">
                                                {t('Amount')} {orderStatusId === 11 ? t('sent') : t('to send')}:  <b> {commaNumber(orderCurrencyAmount)} </b> {offerCurrencyId}
                                            </p>
                                            <p className="orderDetails">
                                                {t('Amount')} {orderStatusId === 11 ? t('received') : t('to receive')}: { orderAssetReleaseExplorerLink ? <a href={orderAssetReleaseExplorerLink} target="_blank" rel="noreferrer"> <b> {commaNumber(orderAmount)} </b> {offerAssetId} </a> : <> <b> {commaNumber(orderAmount)} </b> {offerAssetId} </>}
                                            </p>
                                        </>
                                    :
                                    offerType === 'Sell' ?
                                        <>
                                            <p className="orderDetails">
                                                {t('Amount')}  {orderStatusId === 11 ? t('sent') : t('to send')}:  <b> {commaNumber(orderCurrencyAmount)} </b> {offerCurrencyId}
                                            </p>
                                            <p className="orderDetails">
                                                {t('Amount')}  {orderStatusId === 11 ? t('received') : t('to receive')}: {orderAssetReleaseExplorerLink ? <a href={orderAssetReleaseExplorerLink} target="_blank" rel="noreferrer"> <b> {commaNumber(orderAmount)} </b> {offerAssetId} </a> : <> <b> {commaNumber(orderAmount)} </b> {offerAssetId} </>}
                                            </p>
                                        </>
                                        : <>
                                            <p className="orderDetails">
                                                {t('Amount')}  {orderStatusId === 11 ? 'sent' : 'to send'}: {orderAssetReleaseExplorerLink ? <a href={orderAssetReleaseExplorerLink} target="_blank" rel="noreferrer"> <b> {commaNumber(orderAmount)} </b> {offerAssetId}</a> : <> <b> {commaNumber(orderAmount)} </b> {offerAssetId} </>}
                                            </p>
                                            <p className="orderDetails">
                                                {t('Amount')}  {orderStatusId === 11 ? 'received' : 'to receive'}: <b> {commaNumber(orderCurrencyAmount)} </b> {offerCurrencyId}
                                            </p>
                                        </>
                                }
                                  { [4, 7, 11].includes(orderStatusId) ? <p style={{ wordWrap: 'break-word'}} className="orderDetails">{t('Fiat Transaction ID/Reference')}: <b>{fiatDepositTransactionId}</b> </p> : null }
                                  {!isCrypto(orderStatusId === 6? takerPaymentChannelId : offerCurrencyPaymentMethod?.paymentChannelId) ?  
                                    [3,4,6,7].includes(orderStatusId)? 
                                        <p style={{background: '#fcbfbf'}} className="orderDetails">
                                            {user === 'maker' ? offerTaker : offerMaker}'s {t('real name')}:
                                            <b> { user === 'maker' ? offerTakerFullName : offerMakerFullName}</b> 
                                        </p>
                                    : null : null }
                                 { orderStatusId > 1 && orderStatusId < 8? <p style={{background: '#fcbfbf'}} className="orderDetails">{user === 'maker' ? offerTaker : offerMaker}'s {t('phone number')}: <b>{ user === 'maker' ?<a className="phoneLink" href={`tel:${offerTakerPhone}`}>{offerTakerPhone}</a> : <a className="phoneLink" href={`tel:${offerMakerPhone}`}>{offerMakerPhone}</a>}</b> </p> : null}
                                <br />
                                {confirmed ?
                                    <>
                                        {orderStatusId === 6 && user === 'maker' ? confirmPayment() : null}

                                        {orderStatusId === 6 && user === 'taker' ? allowToCancel ? <Button style={{ width: '100%', border: '0', color: 'white', background: '#f32013' }} onClick={() => cancelOrder()}>
                                        {loading ? <><Loader /> {t('Cancelling')}... </>  : 'Cancel Order'}
                                    </Button> : null : null}

                                        {orderStatusId === 5 && user === 'maker' ? cancelButton() : null}

                                        {orderStatusId === 5 && user === 'taker' ? confirmPayment() : null}

                                        {orderStatusId === 2 && user === 'maker' ? confirmPayment() : null}

                                        {orderStatusId === 2 && user === 'taker' ? cancelButton() : null}

                                        {orderStatusId === 4 && user === 'maker' ? releaseAppeal() : null}

                                        {orderStatusId === 4 && user === 'taker' ? cancelAppeal() : null}

                                        {orderStatusId === 7 && user === 'taker' ? releaseAppeal() : null}
                                        
                                        {orderStatusId === 7 && user === 'maker' ? cancelAppeal() : null}

                                        {orderStatusId === 3 && user === 'taker' ? confirmPayment() : orderStatusId === 3 && allowToCancel? <Button style={{ width: '100%', border: '0', color: 'white', background: '#f32013' }} onClick={() => cancelOrder()}>
                                        {loading ? <><Loader /> {t('Cancelling')}... </> : 'Cancel Order'} </Button> : null}
                                    </>
                                    : null}
                                    {orderStatusId === 1 ?
                                    <Button style={{ width: '100%', border: '0', color: 'white', background: '#f32013' }} onClick={() => cancelOrder()}>
                                        {loading ? <><Loader /> {t('Cancelling')}... </> : 'Cancel Order'}
                                    </Button> : null}
                            </div>
                        </Col>
                    </Row>
                </Col>
                <ConfirmMoal
                    offerType={offerType}
                    offerAssetId={offerAssetId}
                    offerCurrencyId={offerCurrencyId}
                    orderType={orderType}
                    isModalVisible={isModalVisible}
                    handleCancel={handleCancel}
                    handleOk={handleOk}
                    onChange={onChange}
                    loading={loading}
                    button={msg}
                    orderStatusId={orderStatusId}
                    assetCurrency={assetCurrency}
                    user={user}
                    t={t}
                />
                <ConfirmMoal
                    offerType={offerType}
                    offerAssetId={offerAssetId}
                    offerCurrencyId={offerCurrencyId}
                    orderType={orderType}
                    isModalVisible={isModalVisible}
                    handleCancel={handleCancel}
                    handleOk={handleOk}
                    onChange={onChange}
                    loading={loading}
                    button={msg}
                    orderStatusId={orderStatusId}
                    assetCurrency={assetCurrency}
                    user={user}
                    t={t}
                />
            </Row>
        </Spin>

    );
};

export default ConfirmOrderPayment;
