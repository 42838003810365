import React, { useState, useEffect } from 'react';
import {
  Row, Col, Select, Form
} from 'antd';
import { useTranslation } from 'react-i18next';
import Loading from '../Loading';
import Modal from '../Modal';
import Button from '../Button';

import BankTransferForm from './forms/BankTransferForm';
import MobileMoneyForm from './forms/MobileMoneyForm';
import CryptoForm from './forms/CryptoForm';
import ETransfer from './forms/ETransfer';

import { useStateValue } from '../../../Providers/stateProvider';
import { addPayment } from '../../../Providers/action/payments';


const { Option } = Select;

const PaymentMethodModal = ({ isModalVisible, handleCancel }) => {
  const initialState = {
    channel: '',
    paymentChannelId: '',
  };
  const [form] = Form.useForm();
  const [{ payments }, dispatch] = Object.values(useStateValue());
  const { t } = useTranslation();

  const [paymentChannels, setPaymentChannels] = useState([]);
  const [isDisabled, setIsDisabled] = useState(true);
  const [payMethods, setPayMethods] = useState([]);
  const [state, setState] = useState(initialState);

  const { paymentChannels: payChannelData, loading } = payments;


  const validateMethodType = () => {
    console.log(state.channel);
    switch(state.channel){
      case 'Bank Transfer':
        // const x = (state?.name === '' || state?.destinationAccount === '')
        // console.log(state?.name === '', state?.destinationAccount === '', state?.name, '????')
        return (state.name === undefined || state.name === '') || (state.destinationAccount === undefined || state.destinationAccount === '')
      default:
        return Object.keys(state).length < 3 || (state.destinationAccount === undefined || state.destinationAccount === '')
    };
  };


  useEffect(() => {
    setPaymentChannels(Object.keys(payChannelData));
    setIsDisabled(validateMethodType())
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [payChannelData, state]);


  const handleOk = async () => {
    await addPayment(dispatch, state);
    handleCancel();
    form.resetFields();
    setState(initialState);
  };

  const selectChannel = (value) => {
    setPayMethods(payChannelData[[value]]);
    setState({ ...state, 'channel': value });

  };

  const selectMethod = (value) => {
    setState({ ...state, 'paymentChannelId': value });
  };
  
  return (
    <>
      <Modal
        title={t("Add Payment Method")}
        visible={isModalVisible}
        onOk={handleOk}
        onCancel={handleCancel}
        footer={[
          <Button key="back" type="secondary" onClick={handleCancel}>
            {t('Cancel')}
          </Button>,
          <Button
            key="submit"
            type="primary"
            style={{ color: 'white' }}
            disabled={
              loading || isDisabled ? true : false
            }
            onClick={handleOk}
          >
            {loading ? <> <Loading /> &nbsp; {t('Saving')}... </> : t('Save Payment Method')}
          </Button>
        ]}
      >
        <Row
          type='flex'>
          <Col xxl={24} lg={24} md={24} sm={24} xs={24}>
            <Form
              form={form}
              className='add-offer'
              layout='vertical'
            //   onFinish={handleSubmit}
            >
              <Row>
                <Col xxl={12} lg={12} md={24} sm={24} xs={24}>
                  <Form.Item
                    name="channel"
                    label={t('Select Type') + ':'}
                    rules={[
                      {
                        required: true,
                        message: t('Please Select payment a channel'),
                      },
                    ]}>
                    <Select
                      size='large'
                      onChange={selectChannel}
                      placeholder='Select'>
                      {paymentChannels.map((item) => (
                        <Option key={item} className='option'>
                          {item}
                        </Option>
                      ))}
                    </Select>
                  </Form.Item>
                </Col>
              </Row>
              <Row>
                <Col xxl={12} lg={12} md={24} sm={24} xs={24}>
                  <Form.Item
                    label={t('Select payment method') + ':'}
                    name='paymentChannelId'
                    rules={[
                      {
                        required: true,
                        message: t('Please Select payment a channel'),
                      },
                    ]}
                  >
                    <Select
                      size='large'
                      disabled={state.channel === ''}
                      onChange={selectMethod}
                      placeholder='Select'>
                      {payMethods.map((item) => (
                        <Option key={item} className='option'>
                          {item}
                        </Option>
                      ))}
                    </Select>
                  </Form.Item>
                </Col>
              </Row>
              <Row>
                {state.channel === 'Crypto' ? <CryptoForm t={t} setState={setState} state={state} /> : null}
                {state.channel === 'Bank Transfer' ? <BankTransferForm t={t} setState={setState} state={state} /> : null}
                {state.channel === 'Mobile Money' ? <MobileMoneyForm t={t} setState={setState} state={state} /> : null}
                {state.channel === 'E-Transfer' ? <ETransfer t={t} setState={setState} state={state} /> : null}

              </Row>
            </Form>
          </Col>
        </Row>
      </Modal>

    </>
  )
}

export default PaymentMethodModal
