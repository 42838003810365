import React, { useEffect, useState } from 'react';
import { 
    Row,
    Col,
    Button,
    Checkbox,
} from 'antd';
import { useHistory, useParams } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { useStateValue } from '../../Providers/stateProvider';
import { decode64 } from '../../utils';
import AppealForm from './appealForm';

import './appeal.less'

const AppealPage = () => {
    const [, dispatch] = Object.values(useStateValue());
    const { t }  = useTranslation();
    const [disabled, setDisabled] = useState(true);
    const [showAppealModal, setShowAppealModal] = useState(false);
    const tradesQuery = window.location.hash.split('?trades=');
    const history = useHistory();
    const { id } = useParams();
  
    useEffect(() => { 
      dispatch({ type: 'SET_PAGE_CURRENT', payload: 'appeal' });
      window.scrollTo(0, 0)
    }, [dispatch]);

    const handleCancelAppeal = () => { 
      setShowAppealModal(false)
  }

    const handleCheck = (e) => { 
      setDisabled(!e.target.checked)
    }

    const handleCancle = () => {
        history.push(
            tradesQuery.length === 2 ?
            {
                pathname: `/orderDetail/${id}`, 
                search: '?trades=true'
            } : 
            `/orderDetail/${id}`
        )
    }

    return (
      <div className="Appeal-container container">
        <AppealForm
          t={t}
          showAppealModal={showAppealModal}
          handleCancelAppeal={handleCancelAppeal}
          redirect={handleCancle}
          maker={ tradesQuery.length === 2 }
          orderId={ decode64(id)}
        />
        <Row className='header-style'>
            <h2>TIMBUKTU DISPUTE RESOLUTION</h2>
        </Row>
        <Row>
            <Col className='body-style' xs={ 24 } sm={ 24 } md={ 24 } lg={ 24 } xl={ 24 } xxl={ 24 }>
                <p>
                Timbuktu users can buy and sell XBN among themselves. Most transactions occur 
                without any problem. However, in case of a dispute between users, Timbuktu offers the 
                opportunity for both users to open an appeal. This is when the Timbuktu dispute 
                resolution team comes in, to mediate between both parties and solve the issue.
                It is faster to reach an agreement with your counterpart rather than opening an appeal. 
                However, if communication fails and you cannot agree to a solution, you can click on the 
                "appeal" button. Our Timbuktu dispute resolution team will help you solve the dispute.
                When an order is on appeal, the XBN will remain in escrow until the dispute is resolved. 
                Our Timbuktu dispute resolution team communicate with the users involved in the 
                dispute by email or support service. Please note that the appeal process is not instant, it 
                is a complicated process and may take time. We are grateful for your patience and 
                understanding.
                </p>
                <br/>
                <h6>Reasons to open an appeal as a Seller.</h6>
                <p>
                    <span style={{ fontWeight: '700'}}>I received the payment from the buyer, but the amount is not correct:</span> The buyer 
                    transfers the money to your account, but the amount does not correspond to the 
                    amount indicated on the platform.
                </p>
                <p>
                    <span style={{ fontWeight: '700'}}>The buyer marked as paid but I did not receive the payment to my account:</span> Contact 
                    the buyer and inform him that you have not received the payment on your account, if 
                    you do not reach an agreement through the chat, you can open an appeal.
                </p>
                <p>
                    <span style={{ fontWeight: '700'}}>I received payment from a third-party account:</span> Open an appeal. If the name on the 
                    buyer's account (e.g. bank account) does not match the name on the platform, the 
                    buyer has violated the rules and the XBN cannot be released, the seller must refund the 
                    money and the order will be canceled.

                </p>
                <p>
                    <span style={{ fontWeight: '700'}}>Others:</span> You can also initiate an appeal if the buyer has violated the transaction policies. 
                    For example, you are advised to continue the transaction through another platform or 
                    outside the Timbuktu P2P website. You can find more information about how P2P 
                    appeals are handled below
                </p>
                <br/>
                <h6>Reasons to open an appeal as a Buyer.</h6>
                <p>
                    <span style={{ fontWeight: '700'}}>I made the payment, but the seller did not release the XBN: </span>
                    Before opening the appeal, 
                    contact the seller via chat and send the payment’s proof (note that sometimes these transactions are not immediate). If the seller does not respond to chat messages or 
                    refuses to send the XBN, open an appeal.
                </p>
                <p>
                    <span style={{ fontWeight: '700'}}>Pay the seller extra money: </span>If you make a payment that exceeds the amount you set on 
                    the platform, you can chat with the other party to reach an agreement. If no agreement 
                    is reached, you can open an appeal, you will need to attach proof of payment.
                </p>
                <p>
                    <span style={{ fontWeight: '700'}}>Others:</span> You can also initiate an appeal if the buyer has violated the transaction policies. 
                    For example, you are advised to continue the transaction through another platform or 
                    outside the Timbuktu P2P website. You can find more information about handling 
                    appeals on P2P appeals are handled below.
                </p>
                <br/>
                <h6>Disputing XBN Trades</h6>
                <p>
                    When a buyer and a seller disagree over a trade either party or Timbuktu can start a 
                    dispute ("Disputed trade or "dispute") to initiate a mediation process on the XBN held in 
                    escrow. Disputed trades are reviewed and resolved by Timbuktu support staff.
                    Disputes can only be started on trades that are open and marked as paid by the buyer 
                    but not yet released by the seller. Trades that are not marked as paid by the buyer, have 
                    been released by the seller, cancelled by the buyer, automatically cancelled, or already 
                    disputed and resolved are considered completed and cannot be disputed, reversed, or 
                    altered.
                </p>
                <p>
                    <span style={{ fontWeight: '700'}}> Unresponsiveness</span>
                    <br/>
                    <br/>
                    When you have placed orders, which have not expired or you are involved in a XBN
                    trade it is important that you remain active and available at least until the trade is 
                    completed, canceled, or resolved. This means that you must be able to provide a 
                    response to a request by Timbuktu support in a disputed trade within twenty-four (24) 
                    hours or you may be deemed as unresponsive, and the dispute may be resolved against 
                    you for this reason alone.
                </p>

                <p>
                    <span style={{ fontWeight: '700'}}>Dispute review</span>
                    <br/>
                    <br/>
                    During a dispute review Timbuktu support may give you instructions that you are 
                    required to follow. The instructions given to you may require you to provide proof of 
                    payment, proof that you have or have not received payment (e.g. bank transaction history), additional ID verification, photo; audio; or video evidence, or any other 
                    documents deemed relevant by Timbuktu. Failure to follow the instructions may lead to 
                    the dispute being resolved against you.
                </p>

                <p>
                    <span style={{ fontWeight: '700'}}>Dispute resolution criteria</span>
                    <br/>
                    <br/>
                    During a dispute review Timbuktu support may give you instructions that you are 
                    required to follow. The instructions given to you may require you to provide proof of 
                    payment, proof that you have or have not received payment (e.g. bank transaction history), additional ID verification, photo; audio; or video evidence, or any other 
                    documents deemed relevant by Timbuktu. Failure to follow the instructions may lead to 
                    the dispute being resolved against you.
                </p>

                <p>
                    <span style={{ fontWeight: '700'}}>Dispute resolution criteria</span>
                    <br/>
                    <br/>
                    A disputed trade is most commonly resolved by Timbuktu support moving the escrowed 
                    XBN to the buyer or the seller of the disputed trade once the dispute resolution criteria 
                    are met.
                    <br/>
                    <br/>
                    In rare situations where neither party fulfill the criteria, or it is in other ways unclear or 
                    not possible to determine which party has fulfilled the dispute resolution criteria, 
                    Timbuktu may decide to resolve the dispute by splitting the escrowed XBN between the 
                    buyer and the seller evenly or unevenly
                </p>

                <p>
                    <span style={{ fontWeight: '700'}}>Dispute resolution criteria</span>
                    <br/>
                    <br/>
                    Timbuktu can resolve a disputed trade in the favour of a XBN buyer when one of the 
                    following criteria are met:
                    <ul style={{ listStyle: 'disc', paddingLeft: '6%'}}>
                        <li>The buyer has made payment according to the instructions provided by the seller 
                    in the trade chat, order terms of trade, or order payment details and the buyer 
                    has provided sufficient proof that the payment was made according to these 
                    instructions.</li>
                    <li>
                    The seller has become unresponsive.
                    </li>
                    </ul>
                </p>

                <p>
                    <br/>
                    Timbuktu can resolve a disputed trade in the favour of a XBN seller when one of the  following criteria are met:
                    <ul style={{ listStyle: 'disc', paddingLeft: '6%'}}>
                        <li>The buyer has not provided payment or not provided payment in full.</li>
                        <li>The payment made by the buyer has been held/frozen/stopped by the payment provider.</li>
                        <li>The buyer has not provided payment according to the instructions provided to 
                            them by the seller in the trade chat, advertisement terms of trade or 
                            advertisement payment details.</li>
                        <li>The payment is made by a third party to the trade OR the payment is made from a payment account not registered in the name of the buyer.</li>
                        <li>The buyer is not responding.</li>
                    </ul>

                    If the buyer or the seller of a disputed trade provides fraudulent information or 
                    fraudulent documents or makes false claims or otherwise tries to force a certain 
                    outcome of a disputed trade the dispute may be immediately resolved against the user.
                </p>

                <p>
                    <span style={{ fontWeight: '700'}}>Incorrect dispute resolution</span>
                    <br/>
                    <br/>
                    If you believe Timbuktu has resolved a dispute you are a party of in a way which is not in 
                    accordance with these terms you have a right to request a review. To request a review,
                    you need to notify us and specifically request a review by contacting customer support 
                    no later than 30 days after the dispute resolution has been given.
                </p>

                <p>
                <span style={{ fontWeight: '700'}}>Limitation of Liability regarding dispute resolution</span>
                    <br/>
                    <br/>
                    You acknowledge that the dispute resolution is a service that we provide, and which will 
                    be conducted in accordance with our terms of service. You agree and accept that we 
                    assume no responsibility for the outcome thereof and in any event our liability for 
                    dispute resolution is limited to the amount of the transaction in dispute.
                    <br/>
                    <br/>
                    In the event of an incorrect dispute resolution which is corrected in accordance with the 
                    terms of this Agreement, we assume no liability for any damage, costs or inconvenience
                </p>
            </Col>
        </Row>
        <Row>
          <Col className='body-style' xs={ 24 } sm={ 24 } md={ 24 } lg={ 24 } xl={ 24 } xxl={ 24 }>
            <span className="tandc">
              <Checkbox onChange={handleCheck}> 
                <b>{t("I have read and I understand")}</b>
              </Checkbox>
            </span>
            <Button
              key="submit"
              type={!disabled ?"secondary" :"primary"}
              onClick={handleCancle}
            >Cancel</Button>
            &nbsp;
            <Button
              key="submit"
              type={disabled ?"secondary" :"primary"}
              disabled={disabled}
              onClick={() => setShowAppealModal(true)}
            >Continue</Button>
          </Col>
        </Row>
      </div>
    )
}

export default AppealPage;
