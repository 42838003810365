
const languageList = [

  {
    name: 'English',
    key: 'en'
  },
  {
    name: 'French',
    key: 'fr'
  },
  // {
  //   name: 'Deutsche',
  //   key: 'ger'
  // },
  {
    name: 'Español',
    key: 'es'
  },
  {
    name: 'Português',
    key: 'pt'
  },
  {
    name: 'Indonesia',
    key: 'id'
  },
  {
    name: 'Tiếng Việt',
    key: 'vi'
  },
  {
    name: 'Kiswahili',
    key: 'sw'
  },
  {
    name: 'Filipino',
    key: 'tl'
  },
  {
    name: 'Polski',
    key: 'pl'
  },
  {
    name: 'Türkçe',
    key: 'tr'
  },
  {
    name: 'Malay',
    key: 'ms'
  },
  {
    name: 'ਪੰਜਾਬੀ',
    key: 'pa'
  },
  {
    name: 'Hausa',
    key: 'ha'
  },
  {
    name: 'Igbo',
    key: 'ig'
  },
  {
    name: 'Yoruba',
    key: 'yo'
  },
  {
    name: '한국어',
    key: 'ko'
  },
  {
    name: 'Pусский',
    key: 'ru'
  },
  {
    name: '日本語',
    key: 'ja'
  },
  {
    name: 'বাংলা',
    key: 'bn'
  },
  {
    name: '中文',
    key: 'zh'
  },
  {
    name: 'हिंदी',
    key: 'hi'
  },
  {
    name: 'عربى',
    key: 'ar'
  },
]

// English
// Français (french)
// Deutsche (german)

// Español (spanish)
// Português (portuguese)

//  (indonesia)
// Tiếng Việt (vietnamese)
//  (swahili)
//  (tagalog)
//  (polish)
//  (turkish)
// Malay
//  (punjabi)
// Hausa
// 
// 
//  (korean)
//  (russian)
//  (japanese)
//  (bengali)
//  (chinese)
//   ( hindi)
//  (arabic)

export {
  languageList
}

