import React from 'react';
import { Row } from 'antd';
import './listingTermsAndCondition.less';



const ListingTermsAndCondition = () => {
  return (
    <div className='Terms-container container'>
      <Row className='header-style'>
        <h2>LISTING OF TOKENS ON TIMBUKTU EXCHANGE</h2>
      </Row>
      <Row className='body-style'>
        <p>Timbuktu Exchange welcomes the effort of various teams issuing tokens on the Bantu Blockchain as they are part of the parties that are expanding the reach of the Bantu Blockchain ecosystem.
        </p>
        <p>
          This document, therefore, sets out the details to enable proper vetting of any digital asset that will be listed on Timbuktu Exchange.</p>
        <p>
          We hope to provide a clear understanding of why and how certain tokens are listed even as we work on ways to provide more data on each token project and their backers so that traders on the Timbuktu platform can do ample research and make proper assessments.</p>
        <p>
          We firmly believe that a long-term approach to working with quality token teams is the key to successfully fostering a healthy ecosystem and providing our community with a variety of sustainable and useful tokens to trade.</p>

        <p>
          There are several risks that exist with tokens that are issued on the blockchain, from regulatory and legal risks to cases of the incompetence of the token team and outright fraud, especially with tokens issued by scammers who are hell-bent on defrauding unsuspecting ecosystem users. While we ensure to support the token issuers with listing on Timbuktu, we are also cautious about these risks. We have a duty to our community to do our best in minimizing their exposure to scam tokens, at the same time, we try to select and list tokens that our users would like to purchase and utilize.
        </p>
        <p>
          Our process of due diligence ensures that we achieve to the best of our ability a clean marketplace with minimal so-called bad tokens, therefore we encourage token issuing teams to cooperate with the Timbuktu team to pass through this process.</p>
        <p>
          We aim to partner with project teams that are able to bring more active users to the ecosystem, however, the Timbuktu token evaluation team will at their discretion decide which token to list and which one not to list we will attempt to give quick, specific reasons for the approval or rejection of particular assets.</p>
        <p>
          Timbuktu listing officer will give assistance to the project team and also give step by step guide on how to go about listing.</p>
        <p>
          The following are expected from the token team before listing consideration on Timbuktu Exchange:</p>
        <ul>
          <li>Your token must have been issued on the Bantu Blockchain mainnet and visible on the Explorer.</li>
          <li>The details of your token are complete and valid and your home domain is set on the toml file..</li>
          <li>Your token has been listed on BantuPay wallet (to be listed on the Asset section of Timbuktu, otherwise it will be listed on the currency section.)</li>
          <li>You have signed off the Timbuktu NDA</li>
          <li>You have filled the <a target="_blank" rel="noreferrer" href="https://forms.gle/cnbnPhq5BgU7xCWP9">listing application form</a> (If you have previously filled out the application form, please refrain from reapplying. Any duplicated submission will be denied.)</li>
          <li>You have submitted your legal opinion document to <b>listing@timbuktu.exchange</b>.</li>
          <li>Disclosure of listing information is forbidden until Timbuktu officially issues the listing announcement.</li>
          <li>If the listing fee is applicable to your token, it will be disclosed once the listing approval is given.</li>
          <li>Unauthorized use of the Timbuktu logo in relative marketing material is prohibited.</li>
          <li>Additional questions (if any) will be asked by our listing team.</li>
        </ul>

      </Row>
    </div>
  );
};

export default ListingTermsAndCondition;
