import React, { useState, useEffect } from 'react';
import {
    Button,
    Form,
    Input,
    Select
} from 'antd';
import { useStateValue } from '../../Providers/stateProvider';
import { makerAppealOrder, takerAppealOrder} from '../../Providers/action/order';
import Modal from '../../Components/Shared/Modal';

const { Option } = Select;
const { TextArea } = Input;

const AppealForm = ({
    t,
    showAppealModal,
    handleCancelAppeal,
    redirect,
    maker,
    orderId
}) => {
    const initialState = { 
        appealReason: '',
        appealDetail: ''
    }

    const [state, setState] = useState(initialState);
    const [disabled, setDisabled] = useState(true);
    const [loading, setLoading] = useState(false);
    const [form] = Form.useForm();

    const [, dispatch] = Object.values(useStateValue());

    const onSelect = (value) => {
        setState({
            ...state,
            appealReason: value
        });
    }

    useEffect(() => { 
        if (state.appealReason && state.appealDetail){    
            setDisabled(false)
        }
    }, [state]);

    const onChange = (e) => {
        setState({
            ...state,
            appealDetail: e.target.value
        });
    }

    const submit = async () => { 
        const { appealReason, appealDetail } = state;
        const data = {
            orderId,
            appealDetail,
            appealReason
        }

        if (maker) {
            await makerAppealOrder(dispatch, data);
        } else {
            await takerAppealOrder(dispatch, data);
        }
    }

    const submitAppeal = async () => {
        setLoading(true);
        await submit();
        setLoading(false);
        redirect();
    }

    const issueList = [
        'The buyer is not responding',
        'The buyer has not provided payment in full',
        'I have paid, but order is cancled',
        'I received the payment from the buyer, but the amount is not correct',
        'The buyer marked as paid but I did not receive the payment to my account',
        'I received payment from an account name different from the user’s real name',
        'I made the payment, but the seller did not release the XBN',
        'I paid seller more money than I should'
    ]

    return (
        <Modal
            width={700}
            title={t('Order Appeal')}
            visible={showAppealModal}
            onOk={handleCancelAppeal}
            footer={[
                <Button type={!disabled ?"secondary" :"primary"} onClick={handleCancelAppeal}>
                    {t('Cancel')}
                </Button>,
                <Button key="submit" disabled={disabled}  type={disabled ?"secondary" :"primary"} onClick={submitAppeal}>
                    { loading ? t('Processing...') : t('Proceed')}
                </Button>
            ]}
        >
            <Form
                form={form}
                layout='vertical'
            >
                <Form.Item
                    name='appealReason'
                    label={t('Select your issue')}
                    rules={[
                    {
                        required: true,
                        message: t('Please select an option') + '!',
                    },
                    ]}>
                    <Select
                        // style={{ width: '100%' }}
                        size='large'
                        showSearch
                        onChange={onSelect}
                        placeholder='Select'
                        >
                            {issueList.map((item, index) => (
                                <Option key={item} className='option'>
                                    {item}
                                </Option>
                            ))}
                    </Select>
                    
                </Form.Item>
                <Form.Item
                    name='appealDetail'
                    label={t('Additional Information')}
                >
                    <TextArea 
                        label={t('Select your issue')}
                        showCount maxLength={250} onChange={onChange} />
                    </Form.Item>
            </Form>
        </Modal>
    )
}

export default AppealForm;

