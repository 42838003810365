import React from 'react';
import { Row, Col } from 'antd';
import { useTranslation } from 'react-i18next';
import { TwitterOutlined, LinkedinOutlined, FacebookOutlined } from '@ant-design/icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTelegram } from '@fortawesome/free-brands-svg-icons';
// import img from '../../../assets/img/14K.png';
// import imgText from '../../../assets/img/Asset 204K.png';


import './footer.css';
const Footer = () => {
  const { t } = useTranslation();
  return (
    <footer fixed="bottom" className="footer5 footer--bw footer-move-mobile">
      <div className="footer__big">
        <div className="container">
          <div className="row" style={ { marginRight: '0' } }>

            {/* <div style={ { flexDirection: 'column', alignItems: 'center' } } className="col-lg-3 col-md-6 col-sm-12 d-flex justify-content-center">
              <Image preview={ false } style={ { width: '150px' } } src={ img } alt="logo" />
              <Image preview={ false } style={ { width: '150px' } } src={ imgText } alt="text" />
            </div> */}
            <div className="col-lg-3 col-md-6 col-sm-12 d-flex justify-content-lg-center align-center">
              <div className="widget widget--links">
                <h6 className="widget__title">{ t('Listing') }</h6>
                <ul className="links">
                  <li><a href="/#/listing" >{ t('Listing T & C') }</a></li>
                  <li><a target="_blank" rel="noreferrer" href="https://forms.gle/cnbnPhq5BgU7xCWP9">{ t('Listing Application Form') }</a></li>
                </ul>
              </div>
            </div>
            <div className="col-lg-3 col-md-6 col-sm-12 d-flex justify-content-lg-center align-center">
              <div className="widget widget--links">
                <h6 className="widget__title">{ t('Legal & Privacy') }</h6>
                <ul className="links">
                  <li><a href="/#/termsAndConditions" >{ t('Terms of Use') }</a></li>
                  <li><a href="/#/disclaimer" >{ t('Disclaimer') }</a></li>
                </ul>
              </div>
            </div>
            <div className="col-lg-3 col-md-6 col-sm-12 d-flex justify-content-lg-center align-center">
              <div className="widget widget--links">
                <h6 className="widget__title">{ t('Support & Feedback') }</h6>
                <ul className="links">
                  <li><a href="/#/manual" >{ t('User Guide') }</a></li>
                  <li><a target="_blank" rel="noreferrer" href="https://t.me/timbuktux"> { t('User Support') } </a></li>
                </ul>
              </div>
            </div>
            <div className="col-lg-3 col-md-6 col-sm-12 justify-content-center align-center" style={ { alignItems: 'center' } }>
              <div className="widget subcribe--widget">
                <div className="widget__social">
                  <h6 className="widget__title">{ t('Community') }</h6>
                  <ul className="d-flex flex-wrap">
                    <li>
                      <a href="https://t.me/timbuktux" rel="noreferrer" target='_blank' >
                        <span className="sm-sty fa-telegram-plane"><FontAwesomeIcon style={ { fontSize: '25px' } } icon={ faTelegram } /></span>
                      </a>
                    </li>
                    <li>
                      <a href="https://twitter.com/TimbuktuX" rel="noreferrer" target='_blank' className="">
                        <span className="sm-sty"><TwitterOutlined style={ { fontSize: '25px', padding: '0 5px' } } /></span>
                      </a>
                    </li>

                    <li>
                      <a href="https://www.linkedin.com/company/timbuktux/" rel="noreferrer" target='_blank' className="">
                        <span className="sm-sty"><LinkedinOutlined style={ { fontSize: '25px', padding: '0 5px' } } /></span>
                      </a>
                    </li>

                    <li>
                      <a href="http://facebook.com/TimbuktuX" rel="noreferrer" target='_blank' className="">
                        <span className="sm-sty"><FacebookOutlined style={ { fontSize: '25px', padding: '0 5px' } } /></span>
                      </a>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <Row className="footer__small text-center footer-wrap">
        <Col className='footer-txt-left' xs={ 24 } sm={ 24 } md={ 24 } lg={ 24 } xl={ 24 } xxl={ 24 }>
          © { (new Date().getFullYear()) }&nbsp;
          Timbuktu. { t('All rights reserved') }
        </Col>
        {/* <Col className='footer-txt-right' xs={ 24 } sm={ 24 } md={ 12 } lg={ 12 } xl={ 12 } xxl={ 12 }>
        { t('For Support') } - <a target="_blank" rel="noreferrer" href="https://t.me/timbuktuexbeta">{ t('Click Here') }</a> 
        </Col> */}
      </Row>
    </footer>
  );
};

export default Footer;
