import React, { useEffect } from 'react';
import {
  Row, Col, Button, Image
} from 'antd';
import Icon from '@ant-design/icons';
import { useTranslation } from 'react-i18next';
import Carousel from 'react-multi-carousel';
import { useStateValue } from '../../Providers/stateProvider';
import { fast, peers, custodial, fullyPeer, guide, news, contact } from './icon';
import leftCamels from '../../assets/img/left-camels.png';
import rigntCamels from '../../assets/img/right-camels.png';
// import Table from '../../Components/Shared/Table'
import './home.less';
import HomeImg from "../../assets/img/header-img.png";

import 'react-multi-carousel/lib/styles.css';

const responsive = {
  superLargeDesktop: {
    // the naming can be any, depends on you.
    breakpoint: { max: 4000, min: 3000 },
    items: 5
  },
  desktop: {
    breakpoint: { max: 3000, min: 1024 },
    items: 3
  },
  tablet: {
    breakpoint: { max: 1024, min: 464 },
    items: 2
  },
  mobile: {
    breakpoint: { max: 464, min: 0 },
    items: 1
  }
};

const Home = () => {
  const { t } = useTranslation();
  const [{ payments }, dispatch] = Object.values(useStateValue());

  useEffect(() => {
    dispatch({ type: 'SET_PAGE_CURRENT', payload: 'disclaimer' });
  }, [dispatch]);

  const { marketPrices } = payments;
  // const columns = [
  //   {
  //     title: `Pair`,
  //     dataIndex: `pair`,
  //     key: 'pair',
  //     width: '20%',
  //   },
  //   {
  //     title: 'LastPrice',
  //     dataIndex: 'lastPrice',
  //     key: 'lastPrice',
  //     width: '20%',
  //   },
  //   {
  //     title: 'LastTraded',
  //     dataIndex: 'lastTraded',
  //     key: 'lastTraded',
  //     width: '20%',
  //   }
  // ];
  // const dataSource = [
  //   {
  //     key: '1',
  //     lastPrice: '90.352',
  //     pair: 'Bantu Network Token / Nigerian Naira',

  //     lastTraded: '2 mins ago',
  //   },
  //   {
  //     key: '2',
  //     lastPrice: '90.352',
  //     pair: 'Bantu Network Token / Nigerian Naira',

  //     lastTraded: '2 mins ago',
  //   },
  //   {
  //     key: '3',
  //     lastPrice: '90.352',
  //     pair: 'Bantu Network Token / Nigerian Naira',

  //     lastTraded: '2 mins ago',
  //   },
  // ];
  return (
    <div className="">
      <div className='Home-container'>
        <div className="home-header">
          <div className="container">
            <div className="row ">
              <div className="col-lg-7 col-md-12  py-5">
                <p className='home-h1-text'>{ t('Buy & Sell Bantu Blockchain Tokens in minutes') }</p>
                <p className='home-p-text icon-wrap'>{ t('We are your trusted Peer to Peer Marketplace') }</p>
                <Row className='icon-wrap'>
                <Col xs={ 12 } sm={ 12 } md={ 6 } lg={ 4 } xl={ 2 } xxl={ 2 }>
                    <Icon component={ custodial } />
                  </Col>
                  <Col className='home-p-text' xs={ 12 } sm={ 12 } md={ 6 } lg={ 8 } xl={ 6 } xxl={ 6 }>{ t('Non-Custodial') } <br />
                    { t('Platform') }</Col>
                  <Col xs={ 12 } sm={ 12 } md={ 6 } lg={ 4 } xl={ 2 } xxl={ 2 }>
                    <Icon component={ fast } />
                  </Col>
                  <Col className='home-p-text' xs={ 12 } sm={ 12 } md={ 6 } lg={ 8 } xl={ 6 } xxl={ 6 }>{ t('Fast & Secure') }
                    <br />{ t('Transactions') }</Col>
                  <Col xs={ 12 } sm={ 12 } md={ 6 } lg={ 4 } xl={ 2 } xxl={ 2 }>
                    <Icon component={ fullyPeer } />
                  </Col>
                  <Col className='home-p-text' xs={ 12 } sm={ 12 } md={ 5 } lg={ 8 } xl={ 5 } xxl={ 5 }>{ t('Fully') } <br />
                    { t('Peer to Peer') }</Col>
                </Row>
                <Row className='btn-wrap'>
                  <Col style={ { marginRight: '40px' } } className='btn-mob margin-rightt' xs={ 24 } sm={ 24 } md={ 12 } lg={ 12 } xl={ 12 } xxl={ 12 }>
                    <Button size='large' type="secondary" href='/#/buy' style={ { color: '#D32F2F' } }>{ t('Start Transacting Now') }</Button>
                  </Col>
                  <Col className='btn-mob' xs={ 24 } sm={ 24 } md={ 12 } lg={ 8 } xl={ 6 } xxl={ 6 }>
                    <Button size='large' type="secondary" href='/#/manual' style={ { color: '#D32F2F' } }>{ t('User Guide') }</Button></Col>
                </Row>
              </div>
              <div className="col-lg-5 d-none d-lg-block">
                <img src={ HomeImg } alt="" className="hero-img d-none d-lg-block" />
              </div>
            </div>
          </div>
        </div>
        <div className="current-trades-top">
          <div className="container">
            <Carousel responsive={ responsive } showDots={ false } autoPlay={ true } infinite={ true } arrows={ false } containerClass="carousel-container" >
              { marketPrices?.map((market) => {
                return (
                  <div className='home-current-text'> { market.paire }
                    <br />
                    <span style={ { fontSize: '16px', fontWeight: 400 } }>{ market.currencySymbol } { market.price }</span>
                  </div>
                );
              })
              }
            </Carousel>;
          </div>
        </div>
        {/* <Row className='container current-table'>
          <Col xs={ 24 } sm={ 24 } md={ 24 } lg={ 24 } xl={ 24 } xxl={ 24 }>
            <p style={ { fontSize: '25px', fontWeight: 700, paddingLeft: '20px' } }>Current Markets</p>
          </Col>
          <Col xs={ 24 } sm={ 24 } md={ 24 } lg={ 24 } xl={ 24 } xxl={ 24 }>
            <Table
              dataSource={ dataSource } columns={ columns } pagination={ false } />
          </Col>
        </Row> */}
        <Row className='home-contact'>
          <Row className='container home-contact'>
            <p style={ { fontSize: '25px', fontWeight: 700, textAlign: 'center' } }>{ t('Contact us. Be the first to know. Stay in touch') }</p>
            <Row className="container">
              <Col className='home-contact-text' xs={ 24 } sm={ 24 } md={ 11 } lg={ 6 } xl={ 6 } xxl={ 6 }>
                <Icon component={ contact } />
                <br />
                <span style={ { fontWeight: 700, color: '#4F4F4F', fontSize: '25px' } }>24/7</span>
                <br />
                <span>Do you need any help? Our team will be available to support you.</span>

              </Col>
              <div className='vertical-line'></div>
              <Col className='home-contact-text' xs={ 24 } sm={ 24 } md={ 11 } lg={ 6 } xl={ 6 } xxl={ 6 }>
                <Icon component={ peers } />
                <br />
                <span style={ { fontWeight: 700, color: '#4F4F4F', fontSize: '25px' } }>{ t('Peers') }</span>
                <br />
                <span>{ t('Our community is what makes us tick. Join our ever growing  population of Timbuktu traders') }</span>
              </Col>
              <div className='vertical-line'></div>
              <Col className='home-contact-text' xs={ 24 } sm={ 24 } md={ 11 } lg={ 6 } xl={ 6 } xxl={ 6 }>
                <Icon component={ news } />
                <br />
                <span style={ { fontWeight: 700, color: '#4F4F4F', fontSize: '25px' } }>{ t('Blog') }</span>
                <br />
                <span>{ t('Be the first to know when we have some important updates.') }</span>
              </Col>
              <div className='vertical-line'></div>
              <Col className='home-contact-text' xs={ 24 } sm={ 24 } md={ 11 } lg={ 5 } xl={ 5 } xxl={ 5 }>
                <Icon component={ guide } />
                <br />
                <span style={ { fontWeight: 700, color: '#4F4F4F', fontSize: '25px' } }>{ t('Guide') }</span>
                <br />
                <span>{ t('Learn how to navigate around Timbuku P2P Exchange ') }</span>
              </Col>
            </Row>
          </Row>
        </Row>
        <Row className='camel'>
          <Col className='' xs={ 0 } sm={ 0 } md={ 0 } lg={ 6 } xl={ 6 } xxl={ 6 }>
            <Image src={ leftCamels } preview={ false } alt="camels" />
          </Col>
          <Col className='' xs={ 24 } sm={ 24 } md={ 24 } lg={ 12 } xl={ 12 } xxl={ 12 }>
            <p className='camel-text'>{ t('What are you waiting for?') }</p>
            <Button type="primary" href='/#/buy' size='large' style={ { background: '#BE3800' } }>{ t('Start Transacting Now') }</Button>
          </Col>
          <Col className='' xs={ 0 } sm={ 0 } md={ 0 } lg={ 6 } xl={ 6 } xxl={ 6 }>
            <Image src={ rigntCamels } preview={ false } alt="camels" />
          </Col>
        </Row>
      </div>
    </div>
  );
};

export default Home;
