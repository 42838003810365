import {
    getProfileDataRequest,
    setOfflineStatusRequest,
} from '../../Services/apiRequest';
import profileTypes from '../reducers/profile/type';
import { getItem } from '../../Services/localStorageService';

const {
    GET_PROFILE_REQUEST,
    GET_PROFILE,
    ERROR,
    SET_USER_ONLINE_STATUS,
    SET_USER_ONLINE_STATUS_REQUEST
} = profileTypes;

const getProfileData = async (dispatch) => {
    try {
        dispatch({
            type: GET_PROFILE_REQUEST
        });
        const username = getItem('timbuktu_username')
        const profileData = await getProfileDataRequest({ username });
        dispatch({
            type: GET_PROFILE,
            payload: profileData?.data
        });
    } catch (e) {
        dispatch({
            type: ERROR,
            payload: e
        })
    }
}

const setOfflineStatus = async (dispatch) => {
    try {
        dispatch({
            type: SET_USER_ONLINE_STATUS_REQUEST
        });

        const userData = await setOfflineStatusRequest();
        dispatch({
            type: SET_USER_ONLINE_STATUS,
            payload: userData.data
        });
    } catch (e) {
        dispatch({
            type: 'ERROR',
            payload: e
        });
    }
};

export { getProfileData, setOfflineStatus };
