import paymentState from './state';
import Type from './type';
import { formatPaymentChannels } from './utils';

const paymentReducer = (payments = paymentState, { payload, type }) => {
    switch (type) {
        case Type.GET_PAYMENT_CHANNELS_REQUEST:
            return { ...payments, loading: true };

        case Type.GET_PAYMENT_CHANNELS:
            const formatedData = formatPaymentChannels(payload);
            return {
                ...payments,
                ...formatedData,
                loading: false
            }

        case Type.GET_PAYMENT_LIST_REQUEST:
            return { ...payments, loading: true };

        case Type.GET_PAYMENT_LIST:
            return {
                ...payments,
                ...payload,
                loading: false
            }

        case Type.ADD_PAYMENT_REQUEST:
            return { ...payments, loading: true };

        case Type.ADD_PAYMENT:
            payments.paymentMethods.unshift(payload);
            return {
                ...payments,
                loading: false,
                showPaymentNotification: true,
                message: 'Payment method successfully added',
            }

        case Type.DELETE_PAYMENT_REQUEST:
            return { ...payments, loading: true };

        case Type.DELETE_PAYMENT:
            const newPaymentMethods = payments.paymentMethods.filter(p => p.id !== payload.paymentMethodId)
            return {
                ...payments,
                paymentMethods: newPaymentMethods,
                loading: false,
                showPaymentNotification: true,
                message: 'Payment method successfully removed',
            }

        case Type.SET_NOTIFICATION:
            return {
                ...payments,
                showPaymentNotification: false,
                message: ''
            }
        case Type.ERROR:
            return {
                ...payments,
                loading: false
            }
        default:
            return payments;
    }
}

export default paymentReducer;
