import React from 'react';
import { useHistory } from 'react-router-dom';
import { Steps } from 'antd';
import { useParams } from 'react-router-dom';
import { useStateValue } from '../../../Providers/stateProvider';
import SetOrderAmount from './setOrderAmount';
import { decode64 } from '../../../utils';
import { useTranslation } from 'react-i18next';

import './orderDetail.less';

const OrderDetail = () => {
    const [{ payments, offer, order }] = Object.values(useStateValue());
    const history = useHistory();
    const { t } = useTranslation();
    const { id } = useParams();

    const { offers: { records = [] } } = offer;

    const { orderDetails: { step, orderType } } = order;

    const { assets, paymentMethods } = payments;

    const decodeId = decode64(id);

    const orderData = records.find(r => r.id === decodeId);

    const tOrder = orderType || orderData?.orderType;

    const stepValue = step;
    const { Step } = Steps;

    if (records.length < 1) {
        history.push(`/buy`)
    }

    return (
        <>
            <div className='order-container'>
                <Steps className="hide-mobile" current={stepValue}>
                    <Step title={t('Place order')} />
                    <Step title={t('Await Confirmation')} />
                    <Step title={t('Make payment')} />
                    {tOrder === 'Buy' ? <Step title={t('Confirm payment')} /> : null}
                    <Step title={t('Complete order')} />
                </Steps>
                <br />
                <br />
                <br />
                <div className="content">
                    <SetOrderAmount
                        orderData={orderData}
                        assets={assets}
                        payMethods={paymentMethods}
                        id={decodeId}
                        t={t}
                    />
                </div>
                <br />
                <br />
                <br />
            </div>
        </>
    )
}

export default OrderDetail;
