export const cards = () => (
    <svg width="30" height="30" viewBox="0 0 30 30" fill="none" xmlns="http://www.w3.org/2000/svg">
        <g clipPath="url(#clip0)">
            <path d="M28.8549 5.54875L10.4813 1.66039C9.70395 1.49593 8.93345 1.99745 8.76899 2.77481L8.41992 5.01806L29.6199 9.50431L29.969 7.26106C30.1334 6.484 29.6322 5.71326 28.8549 5.54875Z" fill="black" />
            <path d="M21.128 9.97994C20.9134 9.20709 20.1058 8.75028 19.3332 8.96454L15.8452 9.93283L7.62251 8.19263L6.71819 12.4654L1.06796 14.0337C0.295405 14.248 -0.161411 15.0557 0.0528548 15.8283L3.23709 27.3027C3.4517 28.0753 4.2594 28.5324 5.03196 28.3179L23.2972 23.249C24.0697 23.0344 24.5265 22.2271 24.3123 21.4542L23.7657 19.4844L25.8142 19.9175C26.5916 20.0822 27.3621 19.5807 27.5265 18.8033L28.8226 12.6789L21.4438 11.1175L21.128 9.97994ZM23.2374 15.8117L23.6891 13.6768C23.757 13.3572 24.0739 13.151 24.3936 13.2185L26.5285 13.6705C26.8484 13.7383 27.0546 14.055 26.9871 14.3749L26.5354 16.5098C26.4675 16.8295 26.1506 17.036 25.831 16.9682L23.696 16.5165C23.3758 16.4484 23.1696 16.1314 23.2374 15.8117ZM1.3858 15.1788L6.44049 13.7765L18.367 10.4668L19.6504 10.1102C19.6738 10.1039 19.6972 10.1009 19.72 10.1009C19.823 10.1009 19.9461 10.1685 19.9821 10.2978L20.1327 10.8405L20.6049 12.5417L1.821 17.7543L1.19821 15.5107C1.15919 15.3699 1.24507 15.2178 1.3858 15.1788ZM23.1669 21.7717C23.1909 21.8587 23.1657 21.9308 23.1402 21.9758C23.115 22.0208 23.0663 22.0797 22.9793 22.1036L4.7144 27.1725C4.69132 27.1788 4.66759 27.1821 4.64475 27.1821C4.54181 27.1821 4.41872 27.1142 4.38274 26.9849L2.52547 20.292L21.3093 15.0791L22.455 19.207L23.1669 21.7717Z" fill="black" />
            <path d="M7.66584 22.2299C7.57819 21.9147 7.24922 21.7286 6.93407 21.816L4.80097 22.4082C4.48611 22.4955 4.29974 22.8248 4.38739 23.1399L4.97925 25.2727C5.0669 25.5879 5.39587 25.7739 5.71102 25.6863L7.84412 25.0944C8.15927 25.0071 8.34535 24.6778 8.258 24.3627L7.66584 22.2299Z" fill="black" />
        </g>
        <defs>
            <clipPath id="clip0">
                <rect width="30" height="30" fill="white" />
            </clipPath>
        </defs>
    </svg>
)

export const copy = () => (
    <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M20 3.33333H3.33333V20H20V3.33333ZM0 17.5V0H17.5V1.66667H1.66667V17.5H0Z" fill="#1550A6" />
    </svg>
)

export const qrCode = () => (
    <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M18.7305 20H1.26953C0.569531 20 0 19.4305 0 18.7305V1.26953C0 0.569531 0.569531 0 1.26953 0H18.7305C19.4305 0 20 0.569531 20 1.26953V18.7305C20 19.4305 19.4305 20 18.7305 20ZM1.26953 0.585938C0.892617 0.585938 0.585938 0.892617 0.585938 1.26953V18.7305C0.585938 19.1074 0.892617 19.4141 1.26953 19.4141H18.7305C19.1074 19.4141 19.4141 19.1074 19.4141 18.7305V1.26953C19.4141 0.892617 19.1074 0.585938 18.7305 0.585938H1.26953Z" fill="#1550A6" />
        <path d="M18.0656 6.70187H13.9559C13.5933 6.70187 13.2983 6.4069 13.2983 6.04433V1.93464C13.2983 1.57206 13.5933 1.2771 13.9559 1.2771H18.0656C18.4281 1.2771 18.7231 1.57206 18.7231 1.93464V6.04433C18.7231 6.4069 18.4281 6.70187 18.0656 6.70187ZM13.9559 1.86304C13.9164 1.86304 13.8843 1.89515 13.8843 1.93464V6.04433C13.8843 6.08378 13.9164 6.11593 13.9559 6.11593H18.0656C18.1051 6.11593 18.1372 6.08382 18.1372 6.04433V1.93464C18.1372 1.89515 18.1051 1.86304 18.0656 1.86304H13.9559Z" fill="#1550A6" />
        <path d="M16.8903 5.3571H15.1313C14.8621 5.3571 14.6431 5.13808 14.6431 4.86882V3.10987C14.6431 2.84061 14.8621 2.62158 15.1313 2.62158H16.8903C17.1595 2.62158 17.3786 2.84061 17.3786 3.10987V4.86882C17.3786 5.13804 17.1595 5.3571 16.8903 5.3571ZM15.229 4.77116H16.7926V3.20752H15.229V4.77116Z" fill="#1550A6" />
        <path d="M4.86931 5.3571H3.11035C2.84109 5.3571 2.62207 5.13808 2.62207 4.86882V3.10987C2.62207 2.84061 2.84109 2.62158 3.11035 2.62158H4.86931C5.13856 2.62158 5.35759 2.84061 5.35759 3.10987V4.86882C5.35759 5.13804 5.13853 5.3571 4.86931 5.3571ZM3.20801 4.77116H4.77165V3.20752H3.20801V4.77116Z" fill="#1550A6" />
        <path d="M16.8903 17.3786H15.1313C14.8621 17.3786 14.6431 17.1595 14.6431 16.8903V15.1313C14.6431 14.8621 14.8621 14.6431 15.1313 14.6431H16.8903C17.1595 14.6431 17.3786 14.8621 17.3786 15.1313V16.8903C17.3786 17.1595 17.1595 17.3786 16.8903 17.3786ZM15.229 16.7926H16.7926V15.229H15.229V16.7926Z" fill="#1550A6" />
        <path d="M4.86931 17.3786H3.11035C2.84109 17.3786 2.62207 17.1595 2.62207 16.8903V15.1313C2.62207 14.8621 2.84109 14.6431 3.11035 14.6431H4.86931C5.13856 14.6431 5.35759 14.8621 5.35759 15.1313V16.8903C5.35759 17.1595 5.13853 17.3786 4.86931 17.3786ZM3.20801 16.7926H4.77165V15.229H3.20801V16.7926Z" fill="#1550A6" />
        <path d="M10.5314 11.0197H9.46876C9.19949 11.0197 8.98047 10.8006 8.98047 10.5314V9.46876C8.98047 9.19949 9.19949 8.98047 9.46876 8.98047H10.5314C10.8007 8.98047 11.0197 9.19949 11.0197 9.46876V10.5314C11.0197 10.8007 10.8007 11.0197 10.5314 11.0197ZM9.56641 10.4338H10.4338V9.56641H9.56641V10.4338Z" fill="#1550A6" />
        <path d="M6.04408 18.7231H1.93439C1.57182 18.7231 1.27686 18.4281 1.27686 18.0656V13.9559C1.27686 13.5933 1.57182 13.2983 1.93439 13.2983H6.04408C6.40666 13.2983 6.70158 13.5933 6.70158 13.9559V18.0656C6.70158 18.4281 6.40666 18.7231 6.04408 18.7231ZM1.93439 13.8843C1.8949 13.8843 1.86279 13.9164 1.86279 13.9559V18.0656C1.86279 18.1051 1.8949 18.1372 1.93439 18.1372H6.04408C6.08354 18.1372 6.11564 18.1051 6.11564 18.0656V13.9559C6.11564 13.9164 6.08354 13.8843 6.04408 13.8843H1.93439Z" fill="#1550A6" />
        <path d="M18.0656 18.7231H13.9559C13.5933 18.7231 13.2983 18.4281 13.2983 18.0656V13.9559C13.2983 13.5933 13.5933 13.2983 13.9559 13.2983H18.0656C18.4281 13.2983 18.7231 13.5933 18.7231 13.9559V18.0656C18.7231 18.4281 18.4281 18.7231 18.0656 18.7231ZM13.9559 13.8843C13.9164 13.8843 13.8843 13.9164 13.8843 13.9559V18.0656C13.8843 18.1051 13.9164 18.1372 13.9559 18.1372H18.0656C18.1051 18.1372 18.1372 18.1051 18.1372 18.0656V13.9559C18.1372 13.9164 18.1051 13.8843 18.0656 13.8843H13.9559Z" fill="#1550A6" />
        <path d="M12.0287 6.70187H10.3906C10.0137 6.70187 9.70702 6.39519 9.70702 6.01827V4.38011C9.70702 4.32628 9.66319 4.28245 9.60936 4.28245H7.97119C7.59428 4.28245 7.2876 3.97577 7.2876 3.59886V1.96069C7.2876 1.58378 7.59428 1.2771 7.97119 1.2771H12.0287C12.4057 1.2771 12.7123 1.58378 12.7123 1.96069V6.01823C12.7123 6.39519 12.4057 6.70187 12.0287 6.70187ZM7.97119 1.86304C7.91737 1.86304 7.87354 1.90687 7.87354 1.96069V3.59886C7.87354 3.65269 7.91737 3.69651 7.97119 3.69651H9.60936C9.98628 3.69651 10.293 4.00319 10.293 4.38011V6.01827C10.293 6.0721 10.3368 6.11593 10.3906 6.11593H12.0287C12.0826 6.11593 12.1264 6.0721 12.1264 6.01827V1.96069C12.1264 1.90687 12.0826 1.86304 12.0287 1.86304H7.97119Z" fill="#1550A6" />
        <path d="M6.01799 12.7123H1.96045C1.58354 12.7123 1.27686 12.4056 1.27686 12.0287V10.3906C1.27686 10.0137 1.58354 9.70697 1.96045 9.70697H3.59861C3.65244 9.70697 3.69627 9.66314 3.69627 9.60932V7.97119C3.69627 7.59428 4.00291 7.2876 4.37986 7.2876H6.01799C6.3949 7.2876 6.70158 7.59428 6.70158 7.97119V12.0287C6.70158 12.4056 6.39494 12.7123 6.01799 12.7123ZM1.96045 10.2929C1.90662 10.2929 1.86279 10.3367 1.86279 10.3906V12.0287C1.86279 12.0825 1.90662 12.1263 1.96045 12.1263H6.01799C6.07182 12.1263 6.11564 12.0825 6.11564 12.0287V7.97119C6.11564 7.91736 6.07182 7.87354 6.01799 7.87354H4.37986C4.326 7.87354 4.28221 7.91736 4.28221 7.97119V9.60932C4.28221 9.98623 3.97553 10.2929 3.59861 10.2929H1.96045Z" fill="#1550A6" />
        <path d="M12.0287 18.7231H10.4431C10.2813 18.7231 10.1501 18.5919 10.1501 18.4301C10.1501 18.2683 10.2813 18.1372 10.4431 18.1372H12.0287C12.0826 18.1372 12.1264 18.0933 12.1264 18.0395V16.4013C12.1264 16.3475 12.0826 16.3037 12.0287 16.3037H10.3906C10.0137 16.3037 9.70702 15.997 9.70702 15.6201V13.982C9.70702 13.9281 9.66319 13.8843 9.60936 13.8843H7.97119C7.91737 13.8843 7.87354 13.9281 7.87354 13.982V18.0395C7.87354 18.0933 7.91737 18.1372 7.97119 18.1372H9.16624C9.32804 18.1372 9.45921 18.2683 9.45921 18.4301C9.45921 18.5919 9.32804 18.7231 9.16624 18.7231H7.97119C7.59428 18.7231 7.2876 18.4164 7.2876 18.0395V13.9819C7.2876 13.605 7.59428 13.2983 7.97119 13.2983H9.60936C9.98628 13.2983 10.293 13.605 10.293 13.9819V15.6201C10.293 15.6739 10.3368 15.7177 10.3906 15.7177H12.0287C12.4057 15.7177 12.7123 16.0244 12.7123 16.4013V18.0395C12.7123 18.4164 12.4057 18.7231 12.0287 18.7231Z" fill="#1550A6" />
        <path d="M15.6201 12.7123H13.982C13.6051 12.7123 13.2984 12.4056 13.2984 12.0287V10.6383C13.2984 10.4765 13.4296 10.3453 13.5913 10.3453C13.7531 10.3453 13.8843 10.4765 13.8843 10.6383V12.0287C13.8843 12.0825 13.9281 12.1263 13.982 12.1263H15.6201C15.674 12.1263 15.7178 12.0825 15.7178 12.0287V10.3906C15.7178 10.0137 16.0244 9.70697 16.4013 9.70697H18.0395C18.0933 9.70697 18.1372 9.66314 18.1372 9.60932V7.97119C18.1372 7.91736 18.0933 7.87354 18.0395 7.87354H13.9819C13.9281 7.87354 13.8843 7.91736 13.8843 7.97119V9.36154C13.8843 9.52334 13.7531 9.65451 13.5913 9.65451C13.4295 9.65451 13.2983 9.52334 13.2983 9.36154V7.97119C13.2983 7.59428 13.605 7.2876 13.9819 7.2876H18.0395C18.4164 7.2876 18.7231 7.59428 18.7231 7.97119V9.60932C18.7231 9.98623 18.4164 10.2929 18.0395 10.2929H16.4013C16.3475 10.2929 16.3037 10.3367 16.3037 10.3906V12.0287C16.3037 12.4056 15.997 12.7123 15.6201 12.7123Z" fill="#1550A6" />
        <path d="M6.04408 6.70187H1.93439C1.57182 6.70187 1.27686 6.4069 1.27686 6.04433V4.62788C1.27686 4.46608 1.40803 4.33491 1.56982 4.33491C1.73162 4.33491 1.86279 4.46608 1.86279 4.62788V6.04433C1.86279 6.08378 1.8949 6.11593 1.93439 6.11593H6.04408C6.08354 6.11593 6.11564 6.08382 6.11564 6.04433V1.93464C6.11564 1.89515 6.08354 1.86304 6.04408 1.86304H1.93439C1.8949 1.86304 1.86279 1.89515 1.86279 1.93464V3.35104C1.86279 3.51284 1.73162 3.64401 1.56982 3.64401C1.40803 3.64401 1.27686 3.51284 1.27686 3.35104V1.93464C1.27686 1.57206 1.57182 1.2771 1.93439 1.2771H6.04408C6.40666 1.2771 6.70158 1.57206 6.70158 1.93464V6.04433C6.70158 6.4069 6.40666 6.70187 6.04408 6.70187Z" fill="#1550A6" />
    </svg>
)

export const warning = () => (
    <svg width="40" height="40" viewBox="0 0 40 40" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M20 1.66675L0 38.3334H40L20 1.66675ZM18.3333 15.0001H21.6667V26.6667H18.3333V15.0001ZM20 33.7501C18.85 33.7501 17.9167 32.8167 17.9167 31.6667C17.9167 30.5167 18.85 29.5834 20 29.5834C21.15 29.5834 22.0833 30.5167 22.0833 31.6667C22.0833 32.8167 21.15 33.7501 20 33.7501Z" fill="#FC690A" />
    </svg>

)
