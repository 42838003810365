import React from 'react';
import { Button, Empty, Col, Row } from 'antd';
import Cards from '../../../Components/Shared/Card';
import { useStateValue } from '../../../Providers/stateProvider';
import { deletePayment } from '../../../Providers/action/payments';

const PaymentMethodList = ({ showModal, paymentMethods, t }) => {
    const [, dispatch] = Object.values(useStateValue());

    const deletePayMethod = (id) => {
        deletePayment(dispatch, { paymentMethodID: id });
    };
    const NoPayment = () => (
        <Row>
            <Col span={ 24 }>
                <Empty className='empty-wrapper' image={ Empty.PRESENTED_IMAGE_DEFAULT } description={
                    <span>
                        {t('No Payment method added yet')}
                    </span>
                } />
            </Col>
        </Row>
    );

    const PayItem = ({ paymentItem }) => (
        <Col span={ 24 }>
            <Cards title={ paymentItem.paymentChannelId } children={ (
                <>
                    <p>{ `${paymentItem.countryCode || ''} ${paymentItem.currencyId || ''}` || null }</p>
                    <p>{ paymentItem.name || null }</p>
                    <p style={{ wordBreak: 'break-word'}}>{ paymentItem.destinationAccount || null }</p>
                    <p>{ paymentItem.bankName || null }</p>
                    <p>{ paymentItem.accountOpeningBranch || null }</p>
                    <p>{ paymentItem.memo || null }</p>
                </>
            ) } extra={ <Button onClick={ () => deletePayMethod(paymentItem.id) }>{t('Remove')}</Button> } />
            <br />
            <br />
        </Col>
    );


    const PayList = ({ paymentMethods }) => (
        <Row gutter={ 6 }>
            { paymentMethods?.map((p, i) => (
                <PayItem key={ i } paymentItem={ p } />
            )) }
        </Row>
    );

    return (
        <div>
            { paymentMethods?.length > 0 ? <PayList paymentMethods={ paymentMethods } /> : <NoPayment /> }
        </div>
    );
};

export default PaymentMethodList;
