import React, { useEffect, useState } from 'react';
import { Row, Col, Button, Select } from 'antd';
import Icon from '@ant-design/icons';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';

import ResourceTable from '../Shared/Table';
import { cards } from './icons';
import { useStateValue } from '../../Providers/stateProvider';
import { getOfferListData } from '../../Providers/action/offers';
import { parseOfferData, encode64 } from '../../utils/index';
import { getBadge } from '../../utils/badges';

import './buyOffers.less';

const OfferList = ({ type: initialType }) => {
  const type = initialType === 'Buy' ? 'Sell' : 'Buy';
  const initialState = {
    currencyId: '',
    offerType: type,
    assetId: 'XBN',
    currencyPaymentChannelId: '',
    limit: 10,
    page: 1
  };

  // eslint-disable-next-line no-unused-vars
  const [state, setState] = useState(initialState);
  const { Option } = Select;

  const { t } = useTranslation();
  const [{ payments, offer }, dispatch] = Object.values(useStateValue());
  const history = useHistory();

  const { paymentChannels: payChannelData = [], currencies = [], assets = [] } = payments;
  const { loading, offers } = offer;

  useEffect(() => {
    getOfferListData(dispatch, state);
  }, [state, dispatch]);

  const onShowSizeChange = (current, size) => {
    setState({ ...state, limit: size, page: current });
  }

  const changePage = (page, pageSize) => {
    setState({ ...state, limit: pageSize, page });
  }

  const userProfile = (data) => {
    return <>
      <Row>
        <Col span={4}>
          <div>
            {data.username} <span style={{fontSize: '20px'}}>{getBadge(data)}</span>
          </div>
          <div style={{ color: '#B1AFAF' }}>
            {t("Trades")}: {data.trades} | {data.rank}% {t("Completion")} <br/>
            {t("Response Time")}: {data.maxTimePerTransaction}
          </div>
        </Col>
      </Row>
    </>;
  };

  const ActionButton = (data) =>
    <Button
      onClick={() => history.push(`/processOrder/${encode64(data.key)}`)}
      style={{ background: color, width: '100%' }}
      className='actionButton'>
      {t(initialType)} {data.assetId}
    </Button>

  const mobileView = (data) => {
    return <>
      <Row>
        <Col span={10}>
          {data.maker.username}
          {/* <Image preview={false} style={{ width: '30%' }} src={verified} /> */}
        </Col>
        <Col>
          <div style={{ color: '#B1AFAF' }}>
            {t("Trades")}: {data.maker.trades} | {data.maker.rank}% {t("Completion")} <br/>
            {t("Response Time")}: {data.maker.maxTimePerTransaction}
          </div>
        </Col>
      </Row>
      <br />
      <Row>
        <Col>
          <div>
            <span>{t("Price")}</span>
            <h5>{data.price}</h5>
          </div>
          <div>
            {t("Available")}: { initialType === 'Buy' ? data.availableAsset : data.availableFiat} <br />
            {t("Limit")}: {data.limits}
          </div>
        </Col>
        <Col style={{ marginTop: '10%', width: '100%' }}>
          {ActionButton(data)}
        </Col>
      </Row>
    </>;
  };

  const color = initialType === 'Buy' ? '#4F9A94' : '#D32F2F';
  const columns = [
    {
      title: "",
      render: record => mobileView(record),
      responsive: ["xs"]
    },
    {
      title: t(`${type}ers`),
      dataIndex: `maker`,
      key: 'maker',
      width: '20%',
      render: data => {
        return userProfile(data);
      },
      responsive: ['md']
    },
    {
      title: t('Available'),
      dataIndex: initialType === 'Buy' ? 'availableAsset' : 'availableFiat',
      key: initialType === 'Buy' ? 'availableAsset' : 'availableFiat',
      width: '20%',
      responsive: ['md']
    },
    {
      title: t('Price'),
      dataIndex: 'price',
      key: 'price',
      width: '20%',
      responsive: ['md']
    },
    {
      title: t('Limits'),
      dataIndex: 'limits',
      key: 'limits',
      width: '20%',
      responsive: ['md']
    },

    {
      title: t('Payment method'),
      dataIndex: 'paymentMethod',
      key: 'paymentMethod',
      width: '25%',
      responsive: ['md']
    },
    {
      title: t('Action'),
      render: (data) => {
        return ActionButton(data);
      },
      responsive: ['md']
    },

  ];

  let paymentTypes = [];

  const payKeys = Object.keys(payChannelData);
  payKeys?.map(p => {
    paymentTypes = paymentTypes.concat(payChannelData[p]);
    return true;
  });

  const handleChange = (value, name) => {
    setState({ ...state, [name]: value });
  };

  const dataSource = parseOfferData(offers?.records);

  const Cards = props => <Icon component={cards} {...props} />;
  return (
    <div style={{ minHeight: '100vh', width: '100%' }}>
      <div className="filter">
        <Row >
          <Col xm={24} sm={24} md={24} lg={8} xl={8} xxl={8}>
            <p>{t('Select asset')}</p>
            <Select
              size="large"
              showSearch
              allowClear
              optionLabelProp="label"
              placeholder={
                <React.Fragment>
                  <Cards />
                </React.Fragment>
              }
              style={{ width: 300 }}
              defaultValue="XBN"
              name="assetId"
              onChange={(value) => handleChange(value, 'assetId')}
            >
              <Option value="">All assets</Option>
              {assets?.map(data => (
                <Option
                  key={data.id}
                  value={data.id}
                  label={
                    <React.Fragment>
                      <Cards />
                      &nbsp;
                      {data.id}
                    </React.Fragment>
                  }
                >
                  {data.id}
                </Option>
              ))}
            </Select>
          </Col>
          <Col xm={24} sm={24} md={24} lg={8} xl={8} xxl={8}>
            <p>{`${t(`Select currency to`)} ${t(initialType === 'Sell' ? 'receive' : 'pay')}`}</p>
            <Select
              size="large"
              showSearch
              allowClear
              optionLabelProp="label"
              defaultValue=""
              name="currencyId"
              onChange={(value) => handleChange(value, 'currencyId')}
              placeholder={
                <React.Fragment>
                  <Cards />
                  {/* &nbsp; Search... */}
                </React.Fragment>
              }
              style={{ width: 300 }}
            >
              <Option value="">{t('All currencies')}</Option>
              {currencies.map(data => (
                <Option
                  key={data.id}
                  value={data.id}
                  label={
                    <React.Fragment>
                      <Cards />
                      &nbsp;
                      {data.id}
                    </React.Fragment>
                  }
                >
                  {data.id}
                </Option>
              ))}
            </Select>
          </Col>
          <Col xm={24} sm={24} md={24} lg={8} xl={8} xxl={8}>
            <p>{t('Select payment method')}</p>
            <Select
              showSearch
              size="large"
              allowClear
              optionLabelProp="label"
              placeholder={
                <React.Fragment>
                  <Cards />
                  {/* &nbsp; Search... */}
                </React.Fragment>
              }
              style={{ width: 300 }}
              name="currencyPaymentChannelId"
              onChange={(value) => handleChange(value, 'currencyPaymentChannelId')}
            >
              <Option value="">{t('All payment methods')}</Option>
              {paymentTypes.map(data => (
                <Option
                  key={data}
                  value={data}
                  label={
                    <React.Fragment>
                      <Cards />
                      &nbsp;
                      {data}
                    </React.Fragment>
                  }
                >
                  {data}
                </Option>
              ))}
            </Select>
          </Col>
        </Row>
      </div>
      <ResourceTable
        loading={loading}
        data={offers}
        onShowSizeChange={onShowSizeChange}
        onChange={changePage}
        tableProps={{
          columns,
          dataSource,
          pagination: 'topRight',
        }}
      />
    </div>
  );
};

export default OfferList;
