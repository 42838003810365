import axios from 'axios';
import { getRefreshToken, getAccessToken, setToken } from './localStorageService';
import dotenv from 'dotenv';
const customAxios = axios.create();

dotenv.config();

const baseURL = process.env.REACT_APP_URL || 'https://p2p-api-alpha.dev.bantupay.org';
customAxios.interceptors.request.use(
    async config => {
        const access_token = getAccessToken()
        config.headers = {
            'Authorization': `Bearer ${access_token}`,
            'Accept': 'application/json',
        }
        return config;
    },
    error => {
        Promise.reject(error)
    });

customAxios.interceptors.response.use((response) => {
    return response
}, async function (error) {
    const originalRequest = error.config;
    if (error?.response?.status === 401 && !originalRequest._retry) {
        if (error?.response?.data.error === 'Token is expired') {
            originalRequest._retry = true;
            const refreshToken = getRefreshToken();
            return axios.post(`${baseURL}/v1/login/token/refresh`,
                {
                    "refreshToken": refreshToken
                })
                .then(res => {
                    if (res.status === 201) {
                        const { accessToken, refreshToken } = res.data;
                        setToken(accessToken, refreshToken);
                        originalRequest.headers['Authorization'] = `Bearer ${accessToken}`;
                        return axios(originalRequest);
                    }
                })
        } else if (['signature is invalid', 'refresh token expired'].includes(error?.response?.data.error)) {
            window.location = '/#/logout'
        }
    }
    return Promise.reject(error);
});


export default customAxios