export const getBadge = (data) => {
    const trades = data?.trades;
    switch (true) {
        case trades >= 500:
            return '💎'
        case trades >= 350:
            return '🥇'
        case trades >= 200:
            return '🥈';
        case trades >= 100:
            return '🥉';
        case trades >= 50:
            return '🐪'
        default:
            return '';
    }
};