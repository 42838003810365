import i18n from './reducers/i18n';
import user from './reducers/user';
import payments from './reducers/payments';
import profile from './reducers/profile';
import offer from './reducers/offers';
import error from './reducers/error';
import order from './reducers/orders';
import app from './reducers/app';

const mainReducer = (state, action) => ({
    i18n: i18n(state.i18n, action),
    user: user(state.user, action),
    payments: payments(state.payments, action),
    profile: profile(state.profile, action),
    offer: offer(state.offer, action),
    error: error(state.error, action),
    order: order(state.order, action),
    app: app(state.app, action),
});

export default mainReducer;
