import moment from 'moment';
import commaNumber from 'comma-number';

const statusCode = (maker, taker, statusCode, offerCurrencyId) => {
  let user;
  if ([1, 2, 4, 6, 9].includes(statusCode)) {
    user = maker;
  } else if ([3, 5, 7, 8].includes(statusCode)) {
    user = taker;
  }
  const status = {
    1: `Awaiting order confirmation from ${user}`,
    2: `Waiting for ${user}'s escrow deposit`,
    3: `Waiting For ${user}'s ${offerCurrencyId} Deposit`,
    4: `Waiting For ${user} To Confirm ${offerCurrencyId} Deposit`,
    5: `Waiting For ${user}'s Escrow Deposit`,
    6: `Waiting For ${user}'s ${offerCurrencyId} Deposit`,
    7: `Waiting For ${user} To Confirm ${offerCurrencyId} Deposit`,
    8: `Canceled By ${user}`,
    9: `Canceled By ${user}`,
    10: `Expired`,
    11: `Completed`,
    12: `Rejected`,
  }

  return status[statusCode]

}

export const parseOfferData = (records) => {
  const returnData = [];
  records?.map(r => {
    returnData.push({
      key: r.id,
      maker: {
        username: r.maker,
        trades: r.makerStats.trades || 0,
        rank: r.makerStats.rank || '0',
        maxTimePerTransaction: r.maxTimePerTransaction < 60 ? `${r.maxTimePerTransaction} Mins` : `${(r.maxTimePerTransaction / 60).toFixed(1)} Hours`,
      },
      price: `${commaNumber(r.assetPrice)} ${r.currencyId}`,
      availableAsset: `${commaNumber(Number(r.assetAmount).toFixed())} ${r.assetId}`,
      availableFiat: `${commaNumber(Number(r.assetAmount * r.assetPrice).toFixed())} ${r.currencyId}`,
      available: `${commaNumber(Number(r.assetAmount).toFixed())} ${r.assetId}`,
      paymentMethod: r.currencyPaymentChannelId,
      paymentChannelId: r.currencyPaymentMethod.paymentChannelId,
      limits: `${commaNumber(r.minTradeAmount)} - ${commaNumber(r.maxTradeAmount)} ${r.currencyId}`,
      assetId: r.assetId,
      offerType: r.offerType || '',
      inactive: r.inactive === 0 ? 'Active' : 'Inactive'
    });
    return true;
  });
  return returnData;
}


export const parseOffer = (records) => {
  const returnData = [];
  records?.map(r => {
    returnData.push({
      key: r.id,
      maker: {
        username: r.maker,
        trades: r.makerStats.trades || 0,
        rank: r.makerStats.rank || '0'
      },
      price: `${commaNumber(r.assetPrice)} ${r.currencyId}`,
      available: commaNumber(r.assetAmount),
      availableAsset: `${commaNumber(r.assetAmount)} ${r.assetId}`,
      availableFiat: `${commaNumber(r.assetAmount * r.assetPrice)} ${r.currencyId}`,
      paymentMethod: r.currencyPaymentChannelId,
      assetId: r.assetId,
      paymentChannelId: r.currencyPaymentMethod.paymentChannelId,
      limits: `${commaNumber(r.minTradeAmount)} - ${commaNumber(r.maxTradeAmount)} ${r.currencyId}`
    });
    return true;
  });
  return returnData;
}

export const decode64 = (string) => atob(string);

export const encode64 = (string) => btoa(string);

export const parseOrderData = (records) => {
  const returnData = [];
  records?.map(r => {
    returnData.push({
      key: r.id,
      offerType: r.offerType,
      orderType: r.orderType,
      name: r.offerTaker,
      maker: r.offerMaker,
      taker: r.offerTaker,
      asset: r.offerAssetId,
      price: `${commaNumber(r.offerAssetPrice)} ${r.offerCurrencyId}`,
      quantity: `${commaNumber(r.orderAmount)} ${r.offerAssetId}`,
      currency: `${commaNumber(r.orderAmount * r.offerAssetPrice)} ${r.offerCurrencyId}`,
      orderId: r.id,
      orderStatusId: r.orderStatusId,
      time: `${moment(r.createdAt).fromNow()}`,
      date: r.createdAt,
      status: statusCode(r.offerMaker, r.offerTaker, r.orderStatusId, r.offerCurrencyId),
    });
    return true;
  });
  return returnData;
}
