import React from 'react';
import {
    Row,
    Col,
    Button,
    Modal,
} from 'antd';
import Loading from '../Shared/Loading';
import commaNumber from 'comma-number';


const ConfirmModal = ({
    t,
    showConfirmMOdal,
    confirmHandleOk,
    confirmHandleCancel,
    loading,
    data
}) => {
    const paymentLabel = data.offerType === 'buy' ? `${t('Bantu Wallet to receive')} ${data.assetId} ${t('asset')}` : `${t('Receiving account for')} ${data.currencyId}`;
    const currencyLabel = data.offerType === 'buy' ? t('Payment currency') : t(`Currency to receive`);

    const liquidityLabel = data.offerType === 'buy' ? t(`Total liquidity needed`) : t(`Total Liquidity Available`);
  
    const valueLabel = data.offerType === 'buy' ? t(`Value of liquidity needed`) : t(`Value of Liquidity Available`);

    return (
        <Modal
            width={700}
            title={t('Create offer summary')}
            visible={showConfirmMOdal}
            onOk={confirmHandleOk}
            onCancel={confirmHandleCancel}
            footer={[
            <Button key="back" type="secondary" onClick={confirmHandleCancel}>
                {t('Cancel')}
            </Button>,
            <Button key="submit" disabled={loading} type="primary" onClick={confirmHandleOk}>
                {loading ? <Loading /> : t('Confirm')}
            </Button>]}
        >
            <Row gutter={4}>
                <Col>
                    <b>Asset</b>: {data.assetId}
                </Col>
            </Row>
            <Row gutter={4}>
                <Col>
                   <b>Offer type</b>: {data.offerType}
                </Col>
            </Row>
            <Row gutter={4}>
                <Col>
                   <b>{currencyLabel}</b>: {data.currencyId}
                </Col>
            </Row>
            <br/>
            <Row gutter={4}>
                <Col>
                   <b>Offer price (per unit)</b>: {commaNumber(data.assetPrice)}
                </Col>
            </Row>
            <Row gutter={4}>
                <Col>
                    <b>Payment method</b>: {data.currencyPaymentMethod}
                </Col>
            </Row>
            <Row gutter={4}>
                <Col>
                    <b>{paymentLabel}</b>: {data.paymentMethod}
                </Col>
            </Row>
            <br/>
            <Row gutter={4}>
                <Col>
                    <b>{liquidityLabel}</b>: {commaNumber(data.assetAmount)} {data.assetId}
                </Col>
            </Row>
            <Row gutter={4}>
                <Col>
                    <b>{valueLabel}</b>: {commaNumber(data.amount)} {data.currencyId}
                </Col>
            </Row>
            <br/>
            <Row gutter={4}>
                <Col>
                    <b>Min trade amount {data.currencyId ? '(' + data.currencyId + ')' : ''}</b>: {commaNumber(data.minTradeAmount)}
                </Col>
            </Row>

            <Row gutter={4}>
                <Col>
                    <b>Max trade amount {data.currencyId ? '(' + data.currencyId + ')' : ''}</b>: {commaNumber(data.maxTradeAmount)}
                </Col>
            </Row>
            <br/>
            <Row gutter={4}>
                <Col>
                    <b>Max Time Per Transaction</b>: {data.maxTimePerTransaction < 60 ? `${data.maxTimePerTransaction} Mins` : `${(data.maxTimePerTransaction / 60).toFixed(1)} hours`}
                </Col>
            </Row>

            <Row gutter={4}>
                <Col>
                    <b>Remark</b>: <br/>
                    {data.remark}
                </Col>
            </Row>
            
        </Modal>
    )
}

export default ConfirmModal
