import React from 'react'
// import PropTypes from 'prop-types';
import { Row } from 'antd'

import PaymentCompoment from '../../Components/Orders/OrderDetail';

const ProcessOrder = props => {
    return (
        <Row className="container">
            <PaymentCompoment />
        </Row>
    )
}

ProcessOrder.propTypes = {

}

export default ProcessOrder
