import React, { useEffect }  from 'react';
import { Row } from 'antd';
import OfferTable from '../../Components/Orders';
import { useStateValue } from '../../Providers/stateProvider';

const Sell = () => {
    const [, dispatch] = Object.values(useStateValue());
    useEffect(() => { 
        dispatch({ type: 'SET_PAGE_CURRENT', payload: 'sell' });
    }, [dispatch]);

    return (
        <Row className="container">
            <OfferTable type="Sell" />
        </Row>
    )
}

export default Sell
