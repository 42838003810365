import React, { useEffect } from 'react';
import { useStateValue } from '../../../Providers/stateProvider';
import notification from '../Notification';
import when604 from '../../../assets/audio/when-604.mp3';
import useSound from 'use-sound';

const Notifications = ({ t }) => {
    const [play] = useSound(when604);

    const [
        {
            offer: {
                showNotification,
                message: offerMsg
            },
            order: {
                playNotification,
                message: orderMsg
            },
            error: {
                showErrorNotification,
                message
            },
            payments: {
                showPaymentNotification,
                message: paymentMsg
            },
            user: {
                showAddTradeContactntNotification,
                message: tradeContactMsg
            }
        }, dispatch] = Object.values(useStateValue());

    //offer notification trigger
    useEffect(() => {
        if (showNotification) {
            notification('success', t(offerMsg))
            dispatch({
                type: 'SET_NOTIFICATION',
                payload: false
            })
        }
    }, [dispatch, showNotification, t, offerMsg])

    useEffect(() => {
        if (showAddTradeContactntNotification) {
            notification('success', t(tradeContactMsg))
            dispatch({
                type: 'SET_NOTIFICATION',
                payload: false
            })
        }
    }, [dispatch, showAddTradeContactntNotification, t, tradeContactMsg])

    useEffect(() => {
        if (showPaymentNotification) {
            notification('success', t(paymentMsg))
            dispatch({
                type: 'SET_NOTIFICATION',
                payload: false
            })
        }
    }, [dispatch, showPaymentNotification, t, paymentMsg])

    //order update notification trigger
    useEffect(() => {
        if (playNotification) {
            notification('success', t(orderMsg));
            play();
            dispatch({
                type: 'SET_NOTIFICATION_SOUND',
                payload: false
            })
        }
    }, [playNotification, play, dispatch, orderMsg, t]);

    //error notification trigger
    useEffect(() => {
        if (showErrorNotification) {
            notification('error', message)
            dispatch({
                type: 'SET_NOTIFICATION',
                payload: false
            })
        }
    }, [showErrorNotification, message, dispatch, t])
    return (
        <div>
        </div>
    )
}

export default Notifications
