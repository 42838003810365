import React from 'react'
import { Row } from 'antd'

import OrderActionCompoment from '../../Components/Orders/OrderDetail/orderAction';

const ProcessOrder = () => {
    return (
        <Row className="container">
            <OrderActionCompoment />
        </Row>
    )
}

export default ProcessOrder;
