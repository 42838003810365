import profileDataState from './state';
import Type from './type';

const profileReducer = (profileData = profileDataState, { payload, type }) => {
    switch (type) {
        case Type.GET_PROFILE_REQUEST:
            return { ...profileData, loading: true };

        case Type.GET_PROFILE:
            return {
                ...profileData,
                ...payload,
                loading: false
            }
        case Type.SET_USER_ONLINE_STATUS_REQUEST:
            return { ...profileData, loading: true }

        case Type.SET_USER_ONLINE_STATUS:
            return {
                ...profileData,
                ...payload,
                loading: false,
            }
        default:
            return profileData;
    }
}

export default profileReducer;
