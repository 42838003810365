import React, { useEffect } from 'react';
import { Row } from 'antd';
import OfferTable from '../../Components/Orders';
import { useStateValue } from '../../Providers/stateProvider';

const Buy = () => {
  const [, dispatch] = Object.values(useStateValue());

  useEffect(() => { 
    dispatch({ type: 'SET_PAGE_CURRENT', payload: 'buy' });
  }, [dispatch]);

    return (
      <Row className="container">
        <OfferTable type="Buy" />
      </Row>
    )
}

export default Buy
