import React, { useEffect } from 'react';
import { Row, Col, Divider, Image, BackTop } from 'antd';
import { useStateValue } from '../../Providers/stateProvider';
import imgMobile from '../../assets/img/mobile.png';
import imgDesktop from '../../assets/img/web.png';
import imgPay from '../../assets/img/3.png';
import imgOrder from '../../assets/img/4.png';
import imgbuy from '../../assets/img/5.png';
import imgsell from '../../assets/img/6.png';

import './manual.less';

const Manual = () => {
  const [, dispatch] = Object.values(useStateValue());

  useEffect(() => { 
    dispatch({ type: 'SET_PAGE_CURRENT', payload: 'disclaimer' });
    window.scrollTo(0, 0)
  }, [dispatch]);
  return (
    <div className='bg-img' >
      <Row className="container pl-5 pr-5">
        <Col span={ 24 } className='wrap text-center'>
          <div className='manual-hd-text'>User Guide</div>
          <div className='manual-body-text'>You will need a BantuPay Wallet to transact on Timbuktu. If you do not have the BantuPay app, 
          <p><a href='https://bantupay.org/' rel="noreferrer" target="_blank">click here to download</a></p>
</div>
        </Col>
        <Divider className='divider' />
        <Col span={ 24 } className='text-center'>
          <div className='manual-sb-text'>Login To Timbuktu</div>
          <div className='manual-body-text'>Using a Mobile Browser</div>
          <Image className='mobile' preview={ false }
            src={ imgMobile }
            onClick={ e => e.preventDefault() }
            alt="phone" />
        </Col>
        <Divider className='divider' />
        <Col span={ 24 } className=''>
          <div className='steps-padding'>
            <p className='manual-body-text-2' style={ { fontWeight: 700 } }>Step 1</p>
            <p className='manual-body-text-2'>Clicking Login at the upper right corner of the page reveals the page as shown in the picture. On this Timbuktu page, enter your BantuPay username in the field provided below and tap on the <span style={ { fontWeight: 700 } }>Login</span> button to submit.</p>
          </div>
          <div className='steps-padding'>
            <p className='manual-body-text-2' style={ { fontWeight: 700 } }>Step 2</p>
            <p className='manual-body-text-2'>A QR code and a "Authorize with BantuPay" button will be displayed; if your BantuPay wallet is on another device, launch it and tap on the QR code icon on the lower right corner if you are not logged in or at the top right corner of your home screen if you are logged in on BantuPay. Then point your phone camera to the QR code displayed. If your BantuPay wallet is on the same device, just click on "Authorize with BantuPay". This takes you to your BantuPay wallet for authorization.</p>
          </div>
          <div className='steps-padding'>
            <p className='manual-body-text-2' style={ { fontWeight: 700 } }>Step 3</p>
            <p className='manual-body-text-2'>On your BantuPay Wallet, authorize with Biometrics or use your password. If successful, you will get a success message. If unsuccessful, ensure that you have used a valid BantuPay username and password, and try again.</p>
          </div>
          <div className='steps-padding'>
            <p className='manual-body-text-2' style={ { fontWeight: 700 } }>Step 4</p>
            <p className='manual-body-text-2'>You have now successfully signed into Timbuktu. Customize your profile, add your payment methods and begin transacting on Timbuktu.</p>
          </div>
        </Col>
        <Divider className='divider' />
        <Col span={ 24 } className='text-center'>
          <div className='manual-sb-text'>Login To Timbuktu</div>
          <div className='manual-body-text'>Using a PC Browser</div>
          <Image preview={ false }
            src={ imgDesktop }
            onClick={ e => e.preventDefault() }
            alt="desktop" />
        </Col>
        <Divider className='divider' />
        <Col span={ 24 } className=''>
          <div className='steps-padding'>
            <p className='manual-body-text-2' style={ { fontWeight: 700 } }>Step 1</p>
            <p className='manual-body-text-2'>Clicking Login at the upper right corner of the page reveals the page as shown in the picture. On this Timbuktu page, enter your BantuPay username in the field provided below and tap on the <span style={ { fontWeight: 700 } }>Login</span> button to submit.</p>
          </div>
          <div className='steps-padding'>
            <p className='manual-body-text-2' style={ { fontWeight: 700 } }>Step 2</p>
            <p className='manual-body-text-2'>A QR code will be displayed. Launch your BantuPay Wallet and tap on the QR code icon on the lower right corner if you are not logged in or at the top right corner of your home screen if you are logged in on BantuPay. Then point your phone camera to the QR code displayed on this Timbuktu page.</p>
          </div>
          <div className='steps-padding'>
            <p className='manual-body-text-2' style={ { fontWeight: 700 } }>Step 3</p>
            <p className='manual-body-text-2'>Once the QR code is successfully scanned, an authorization page will be displayed on your BantuPay app. Authorize with Biometrics or use your password. If successful, you will get a success message. If unsuccessful, ensure that you have used a valid BantuPay username and password, and try again.</p>
          </div>
          <div className='steps-padding'>
            <p className='manual-body-text-2' style={ { fontWeight: 700 } }>Step 4</p>
            <p className='manual-body-text-2'>You have now successfully signed into Timbuktu. Customize your profile, add your payment methods and begin transacting on Timbuktu.</p>
          </div>
        </Col>
        <Divider className='divider' />
        <BackTop />
        <Col span={ 24 } className='text-center'>
          <div className='manual-sb-text'>Add a Payment Method</div>
          <Image preview={ false }
            src={ imgPay }
            onClick={ e => e.preventDefault() }
            alt="payment" />
        </Col>
        <Divider className='divider' />
        <Col span={ 24 } className=''>
          <div className='steps-padding'>
            <p className='manual-body-text-2' style={ { fontWeight: 700 } }>Step 1</p>
            <p className='manual-body-text-2'>Click on the profile icon at the top right corner of the page.</p>
          </div>
          <div className='steps-padding'>
            <p className='manual-body-text-2' style={ { fontWeight: 700 } }>Step 2</p>
            <p className='manual-body-text-2'>Your profile is automatically inherited from BantuPay</p>
          </div>
          <div className='steps-padding'>
            <p className='manual-body-text-2' style={ { fontWeight: 700 } }>Step 3</p>
            <p className='manual-body-text-2'>From the drop-down, click to add payment methods. Add bank accounts or wallet addresses to receive payments for assets you are selling. In the same way, add your BantuPay wallet address for receiving assets bought on Timbuktu.</p>
          </div>
        </Col>
        <Divider className='divider' />
        <Col span={ 24 } className='text-center'>
          <div className='manual-sb-text'>Create Offer</div>
          <Image preview={ false }
            src={ imgOrder }
            onClick={ e => e.preventDefault() }
            alt="offer" />
        </Col>
        <Divider className='divider' />
        <Col span={ 24 } className=''>
          <div className='steps-padding'>
          <p className='manual-body-text-2' style={ { fontWeight: 700 } }>Step 1</p>
          <p className='manual-body-text-2'>Navigate to:&nbsp;
              <span style={ { fontWeight: 700 } }>Create Offer</span> on the top menu bar to view the form. Note that only approved Merchants can see the form. <a href='https://docs.google.com/forms/d/1OejL13kJKm1Bca-oXMq4_V6mNyr6NzH8xoe-IMTJwoA/edit' rel="noreferrer" target="_blank">Become a Merchant</a> if you want to create offers.</p>
              </div>

          <div className='steps-padding'>
            <p className='manual-body-text-2' style={ { fontWeight: 700 } }>Step 2</p>
            <p className='manual-body-text-2'>Select the asset you want to trade under <span style={ { fontWeight: 700 } }>Select Asset</span></p>
          </div>
          <div className='steps-padding'>
            <p className='manual-body-text-2' style={ { fontWeight: 700 } }>Step 2</p>
            <p className='manual-body-text-2'>Select <span style={ { fontWeight: 700 } }>I want to buy</span> to create a buy offer, or select <span style={ { fontWeight: 700 } }>I want to sell</span> to create a sell offer.</p>
          </div>
              <div className='steps-padding'>
              <p className='manual-body-text-2' style={ { fontWeight: 700 } }>Step 3</p>
            <p className='manual-body-text-2'>Fill out the required fields with:
            <ul style={{listStyle: 'circle', padding: '32px'}}>
              <li>The Currency you want to trade the asset with - <span style={ { fontWeight: 700 } }>Payment currency.</span> </li>
              <li>How you want to make or receive payment - <span style={ { fontWeight: 700 } }>Payment Method.</span></li>
              <li>The price you want to buy/sell the asset in the payment currency you selected - <span style={ { fontWeight: 700 } }>Offer price (per unit).</span></li>
              <li>Quantity of asset you want to buy/sell, or the value of the asset you want to buy/sell in the payment currency you selected.</li>
              <li>Minimum and maximum size per trade.</li>
              <li>Your response time within which you want each party to take action on the order.</li>
              <li>Any remark you want your peer to see. Provide the required details of your offer and submit your offer.</li>
            </ul>
            </p>
          </div>
          <div className='steps-padding'>
            <p className='manual-body-text-2' style={ { fontWeight: 700 } }>Step 4</p>
            <p className='manual-body-text-2'>Confirm your final submission and the newly created offer will be added to the list of offers in the <span style={ { fontWeight: 700 } }>'My Offers'</span> page. An offer an be edited after submission</p>
          </div>
        </Col>
        <Divider className='divider' />
        <Col span={ 24 } className=''>
          <div className='manual-sb-text text-center'>Create Offer</div>
          <div className='manual-body-text text-center'>Definitions</div>
          <div className='steps-padding'>
            <p className='manual-body-text-2' style={ { fontWeight: 700 } }>Payment Currency</p>
            <p className='manual-body-text-2'>The currency you wish to make or receive payments with for the assets you wish to buy/sell. Eg. NGN, USD, USDT, BTC</p>
          </div>
          <div className='steps-padding'>
            <p className='manual-body-text-2' style={ { fontWeight: 700 } }>Payment Method</p>
            <p className='manual-body-text-2'>The method you wish to make or receive payment with the currency selected. Eg. Bank Transfer, Crypto</p>
          </div>
          <div className='steps-padding'>
            <p className='manual-body-text-2' style={ { fontWeight: 700 } }>Bantu Wallet</p>
            <p className='manual-body-text-2'>Your BantuPay Public Key</p>
          </div>
          <div className='steps-padding'>
            <p className='manual-body-text-2' style={ { fontWeight: 700 } }>Offer price</p>
            <p className='manual-body-text-2'>How much you wish to buy/sell a single unit of the asset you wish to buy/sell.</p>
          </div>
          <div className='steps-padding'>
            <p className='manual-body-text-2' style={ { fontWeight: 700 } }>Total Liquidity Needed</p>
            <p className='manual-body-text-2'>The total amount of assets you wish to buy.</p>
          </div>
          <div className='steps-padding'>
            <p className='manual-body-text-2' style={ { fontWeight: 700 } }>Value of Liquidity Needed</p>
            <p className='manual-body-text-2'>The value of the total liquidity needed in the currency in which you choose to transact</p>
          </div>
          <div className='steps-padding'>
            <p className='manual-body-text-2' style={ { fontWeight: 700 } }>Minimum & Maximum Trade Amounts</p>
            <p className='manual-body-text-2'>The minimum and maximum value of assets you wish to buy. This will help filter sell orders that do not match your criteria</p>
          </div>
          <div className='steps-padding'>
            <p className='manual-body-text-2' style={ { fontWeight: 700 } }>Enter Remark</p>
            <p className='manual-body-text-2'>Leave a note for sellers engaging with your offer (optional).</p>
          </div>
          <div className='steps-padding'>
            <p className='manual-body-text-2' style={ { fontWeight: 700 } }>Currency to receive</p>
            <p className='manual-body-text-2'>Currency in which you would like to receive payment for the asset you are selling.</p>
          </div>
          <div className='steps-padding'>
            <p className='manual-body-text-2' style={ { fontWeight: 700 } }>Total Liquidity Available</p>
            <p className='manual-body-text-2'>The total amount of assets you have available for sale.</p>
          </div>
          <div className='steps-padding'>
            <p className='manual-body-text-2' style={ { fontWeight: 700 } }>Value of Liquidity Available</p>
            <p className='manual-body-text-2'>The value of assets you have available for sale in the currency you chose to transact in.</p>
          </div>
        </Col>
        <Divider className='divider' />
        <Col span={ 24 } className='text-center'>
          <div className='manual-sb-text'>Buy on Timbuktu</div>
          <Image preview={ false }
            src={ imgbuy }
            onClick={ e => e.preventDefault() }
            alt="buy" />
        </Col>
        <Divider className='divider' />
        <Col span={ 24 } className=''>
          <div className='manual-sb-text text-center'>Buy on Timbuktu</div>
          <div className='steps-padding'>
            <p className='manual-body-text-2' style={ { fontWeight: 700 } }>Step 1</p>
            <p className='manual-body-text-2'>From the homepage, click on the <span style={ { fontWeight: 700 } }>Buy</span> Menu. This will display the list of offers available in the market that you can buy from.</p>
          </div>
          <div className='steps-padding'>
            <p className='manual-body-text-2' style={ { fontWeight: 700 } }>Step 2</p>
            <p className='manual-body-text-2'>Use <span style={ { fontWeight: 700 } }>Select Asset</span>,  <span style={ { fontWeight: 700 } }>Select currency to Pay</span> and <span style={ { fontWeight: 700 } }>Select payment method</span> to filter the offers available to you.</p>
          </div>
          <div className='steps-padding'>
            <p className='manual-body-text-2' style={ { fontWeight: 700 } }>Step 3</p>
            <p className='manual-body-text-2'>Decide who you would like to trade with and click the <span style={ { fontWeight: 700 } }>Buy</span> button to engage.</p>
          </div>
          <div className='steps-padding'>
            <p className='manual-body-text-2' style={ { fontWeight: 700 } }>Step 4</p>
            <p className='manual-body-text-2'>Enter how much you would like to pay in the currency of the offer you selected, this will display how many assets you will receive for your buy trade or enter how many assets you would like to buy, and this will display the amount you will pay.</p>
          </div>
          <div className='steps-padding'>
            <p className='manual-body-text-2' style={ { fontWeight: 700 } }>Step 5</p>
            <p className='manual-body-text-2'>Select the BantuPay wallet in which you will receive the assets bought.</p>
          </div>
          <div className='steps-padding'>
            <p className='manual-body-text-2' style={ { fontWeight: 700 } }>Step 6</p>
            <p className='manual-body-text-2'>
              Confirm the trade, then wait for the seller to confirm availability to take your order.</p>
          </div>
          <div className='steps-padding'>
            <p className='manual-body-text-2' style={ { fontWeight: 700 } }>Step 7</p>
            <p className='manual-body-text-2'>
            Once your order has been accepted by the engaged peer and you have been notified on the platform that the asset has been secured for you, make payment to the peer’s provided account/wallet address and confirm payment.</p>
          </div>
          <div className='steps-padding'>
            <p className='manual-body-text-2' style={ { fontWeight: 700 } }>Step 8</p>
            <p className='manual-body-text-2'>
            Once the peer confirms that payment has been received, your provided BantuPay Wallet will be credited with the assets bought.</p>
          </div>
        </Col>
        <Divider className='divider' />
        <Col span={ 24 } className='text-center'>
          <div className='manual-sb-text'>Sell on Timbuktu</div>
          <Image preview={ false }
            src={ imgsell }
            onClick={ e => e.preventDefault() }
            alt="sell" />
        </Col>
        <Divider className='divider' />
        <Col span={ 24 } className=''>
          <div className='manual-sb-text text-center'>Sell on Timbuktu</div>
          <div className='steps-padding'>
            <p className='manual-body-text-2' style={ { fontWeight: 700 } }>Step 1</p>
            <p className='manual-body-text-2'>From the homepage, click on the <span style={ { fontWeight: 700 } }>Sell</span> Menu. This will display the list of offers available in the market that you can sell to.</p>
          </div>
          <div className='steps-padding'>
            <p className='manual-body-text-2' style={ { fontWeight: 700 } }>Step 2</p>
            <p className='manual-body-text-2'>Use <span style={ { fontWeight: 700 } }>Select Asset</span>,  <span style={ { fontWeight: 700 } }>Select currency to Pay</span> and <span style={ { fontWeight: 700 } }>Select payment method</span> to filter the offers available to you.</p>
          </div>
          <div className='steps-padding'>
            <p className='manual-body-text-2' style={ { fontWeight: 700 } }>Step 3</p>
            <p className='manual-body-text-2'>Decide who you would like to trade with and click the <span style={ { fontWeight: 700 } }>sell</span> button to engage.</p>
          </div>
          <div className='steps-padding'>
            <p className='manual-body-text-2' style={ { fontWeight: 700 } }>Step 4</p>
            <p className='manual-body-text-2'>Enter how many assets you would like to sell, this will display how much you will receive for your sell trade, or enter how much you want to receive and this will display how many assets you need to sell.</p>
          </div>
          <div className='steps-padding'>
            <p className='manual-body-text-2' style={ { fontWeight: 700 } }>Step 5</p>
            <p className='manual-body-text-2'>Select the payment method for receiving your payment.</p>
          </div>
          <div className='steps-padding'>
            <p className='manual-body-text-2' style={ { fontWeight: 700 } }>Step 6</p>
            <p className='manual-body-text-2'>
              Confirm the trade, then wait for the buyer to confirm availability to take your order.</p>
          </div>
          <div className='steps-padding'>
            <p className='manual-body-text-2' style={ { fontWeight: 700 } }>Step 7</p>
            <p className='manual-body-text-2'>
            Once your order has been accepted by the engaged peer, Scan the displayed QR code or tap Pay with BantuPay if you are on mobile to send the correct amount of asset being sold and confirm. The asset is now sent to an escrow account pending when your peer sends you payment in the currency of trade. Only your confirmation of payment will authorize the release of the asset to the other peer.</p>
          </div>
          <div className='steps-padding'>
            <p className='manual-body-text-2' style={ { fontWeight: 700 } }>Step 8</p>
            <p className='manual-body-text-2'>
              Once you confirm receipt of payment, the buyer will be credited with your sold asset.</p>
          </div>
        </Col>
        <Divider className='divider' />
        <Col span={ 24 } className='text-center'>
          <div className='manual-sb-text'>Become a Merchant</div>
          <Image preview={ false }
            src={ imgsell }
            onClick={ e => e.preventDefault() }
            alt="sell" />
        </Col>
        <Divider className='divider' />
        <Col span={ 24 } className=''>
          <div className='steps-padding'>
            <p className='manual-body-text-2' style={ { fontWeight: 700 } }>Step 1</p>
            <p className='manual-body-text-2'>Navigate to:&nbsp;
              <span style={ { fontWeight: 700 } }>Create Offer</span> on the top menu bar and click on <a href='https://docs.google.com/forms/d/1OejL13kJKm1Bca-oXMq4_V6mNyr6NzH8xoe-IMTJwoA/edit' rel="noreferrer" target="_blank">Become a Merchant</a> button at the middle of the page.</p>
          </div>
          <div className='steps-padding'>
            <p className='manual-body-text-2' style={ { fontWeight: 700 } }>Step 2</p>
            <p className='manual-body-text-2'>Fill the form with the relevant information, submit it and wait for approval.</p>
          </div>
          <div className='steps-padding'>
            <p className='manual-body-text-2' style={ { fontWeight: 700 } }>Step 3</p>
            <p className='manual-body-text-2'>Once you are approved as a merchant, you will have access to the following three menus</p>
          </div>
          <div className='steps-padding'>
            <p className='manual-body-text-2'><span style={ { fontWeight: 700 } }>&nbsp;&nbsp; 1.  Create Offer:</span> This allows you to create your own offers and determine your own market price.</p>
          </div>
          <div className='steps-padding'>
            
            <p className='manual-body-text-2'><span style={ { fontWeight: 700 } }>&nbsp;&nbsp;2.  My Offer Trades:</span> This allows you to manage the orders that are placed on your offers</p>
          </div>
          <div className='steps-padding'>
            <p className='manual-body-text-2'><span style={ { fontWeight: 700 } }>&nbsp;&nbsp;3.  My Offers:</span> This allows you to manage the offers you have in the market</p>
          </div>
        </Col>
        <Divider className='divider' />
      </Row>
    </div>
  );
};

export default Manual;
