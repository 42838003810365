/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect } from 'react';
import {
  Row,
  Col,
  Form,
  Input,
  Select,
  Slider,
  InputNumber,
} from 'antd';
import { useHistory } from 'react-router-dom';
import InputNumberField from '../Shared/InputNumberField';
import { useTranslation } from 'react-i18next';
import Button from '../../Components/Shared/Button';
import PaymentMethodModal from '../Shared/Navigationbar/PaymentModal';
import ConfirmOrderModal from './confirmModal';

import { useStateValue } from '../../Providers/stateProvider';
import { getItem } from '../../Services/localStorageService';
import { createOrder } from '../../Providers/action/offers';

import './offerForm.less';

const { TextArea } = Input;

const { Option } = Select;

const OfferForm = ({ type, asset }) => {
  const initialState = {
    currencyId: '',
    paymentMethodId: '',
    paymentMethod: '',
    maker: getItem('timbuktu_username') || '',
    assetAmount: '',
    assetId: asset,
    assetPrice: '',
    minTradeAmount: '',
    maxTradeAmount: '',
    remark: '',
    amount: '',
    offerType: type,
    currencyPaymentMethodId: '',
    currencyPaymentMethod: '',
    maxTimePerTransaction: 45,
  };

  const [form] = Form.useForm();
  const [state, setState] = useState(initialState);
  const [currencySymbol, setCurrencySymbol] = useState('');
  const [disableSubmit, setDisableSubmit] = useState(true);
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [showConfirmMOdal, setShowConfirmModal] = useState(false);
  const [loading, setLoading] = useState(false);
  const history = useHistory();

  const { t } = useTranslation();
  const [{
    payments,
    offer: { error }
  }, dispatch] = Object.values(useStateValue());

  const { paymentMethods = [], currencies } = payments;

  useEffect(() => {
    form.resetFields();
    setState(initialState);
  }, [type]);
  
  useEffect(() => {
    const {
      assetAmount, assetPrice,
      currencyId, maker, minTradeAmount,
      maxTradeAmount, currencyPaymentMethodId,
      maxTimePerTransaction
    } = state;

    if (
      assetAmount !== '' &&
      asset !== '' &&
      assetPrice !== '' &&
      currencyId !== '' &&
      maker !== '' &&
      minTradeAmount !== '' &&
      maxTradeAmount !== '' &&
      currencyPaymentMethodId !== '' &&
      maxTimePerTransaction
    ) {
      setDisableSubmit(false);
    } else {
      setDisableSubmit(true);
    }
  }, [state]);

  const selectCurrency = (id) => {
    const currency = currencies.find(c => c.id === id);
    setCurrencySymbol(currency.currencySymbol);
    setState({
      ...state,
      currencyId: id
    });
  };

  const selectPayMethod = (i, name, paymentMethods) => {
    const nm = `${name}Id`;
    const data = paymentMethods[i];
    console.log(paymentMethods, i);
    if (i === -1) {
      showModal();
    } else {
      setState({
        ...state,
        [nm]: data.id,
        [name]: data.paymentChannelId
      });
    };
  };

  const paymentLabel = type === 'buy' ? `${t('Bantu Wallet to receive')} ${asset} ${t('asset')}` : `${t('Receiving account for')} ${state.currencyId}`;
  const filteredPayMethodFiatAsset = paymentMethods.filter((method) => {
    const filter = type === 'buy' ? 
      method.paymentChannelId === 'Bantu Public Key'
      :
      state.currencyId === 'USDT' ? state.currencyId  === method.paymentChannelId.split('-')[0] : method.paymentChannelId !== 'Bantu Public Key' && method.paymentChannelId.split('-')[0] !== 'USDT'; 
    return filter;

  });

  const filteredPayMethod = paymentMethods.filter((method) => {
    const filter = state.currencyId === 'USDT' ? 
        state.currencyId  === method.paymentChannelId.split('-')[0] : method.paymentChannelId !== 'Bantu Public Key' && method.paymentChannelId.split('-')[0] !== 'USDT'; 
    return filter;

  });

  const currencyLabel = type === 'buy' ? t('Payment currency') : t(`Currency to receive`);

  const liquidityLabel = type === 'buy' ? t(`Total liquidity needed`) : t(`Total Liquidity Available`);

  const valueLabel = type === 'buy' ? t(`Value of liquidity needed`) : t(`Value of Liquidity Available`);

  const onChange = (value, name) => {
    if (name ===  'assetPrice'){
      setState({
        ...state,
        assetPrice: value,
        amount: state.assetAmount * (value || 1),
      });
    } else {
      setState({ ...state, [name]: '' + value, 'assetId': asset });
    }
  };

  const onChangeAssetAmount = (value) => {
    setState({ ...state, assetAmount: value, amount: value * state.assetPrice });
  };

  const onChangeAmount = (value) => {
    setState({ ...state, amount: value, assetAmount: value / state.assetPrice });
  };

  const createOffer = async () => {
    setLoading(true);
    await createOrder(dispatch, {
      ...state,
      assetAmount: '' + state.assetAmount,
      amount: '' + state.amount,
      assetPrice: ''+state.assetPrice,
      maxTimePerTransaction: Number(state.maxTimePerTransaction)
    });
    setLoading(false);
    history.push('/offers');
  };

  useEffect(() => {
    if (!error) {
      form.resetFields();
      setState(initialState);
      setShowConfirmModal(false);
    }
  }, [error]);

  const showPromiseConfirm = () => {
    setShowConfirmModal(true);
  };

  const showModal = () => {
    setIsModalVisible(true);
  };


  const handleCancel = () => {
    setIsModalVisible(false);
  };

  const confirmHandleOk = () => {
    createOffer();
  }

  const confirmHandleCancel = () => {
    setShowConfirmModal(false)
  }

  // const onSearch = (value) => {
  //   const result = filteredPayMethod.find(method => {
  //     return method.paymentChannelId.toLowerCase().startsWith(value.toLowerCase());
  //   });
    
  //   console.log(result);
  //   return result;
  // }

  return (
    <Row
      style={{
        padding: '70px 0',
      }}
      className="offerForm"
      type='flex'>
      <Col lg={18} md={24} sm={24} xs={24}>
        <Form
          form={form}
          className='add-offer'
          layout='vertical'
        >
          <Row gutter={16}>
            <Col xxl={6} lg={8} md={12} sm={24} xs={24}>
              <Form.Item
                name='currency'
                label={currencyLabel}
                rules={[
                  {
                    required: true,
                    message: t('Please select a currency') + '!',
                  },
                ]}>
                <Select
                  style={{ width: '100%' }}
                  size='large'
                  showSearch
                  onChange={selectCurrency}
                  placeholder='Select'>
                  {currencies.map((item) => (
                    <Option key={item.id} className='option'>
                      {item.name} - {item.id}
                    </Option>
                  ))}
                </Select>
              </Form.Item>
            </Col>
            <Col xxl={8} lg={10} md={12} sm={24} xs={24}>
              <Form.Item
                label={t('Payment method')}
                name='currencyPaymentMethodId'
                rules={[
                  {
                    required: true,
                    message: t('Please Select mode of payment') + '!',
                  },
                ]}>
                <Select
                  size='large'
                  showSearch
                  // onSearch={onSearch}
                  onChange={(value) => selectPayMethod(value, 'currencyPaymentMethod', filteredPayMethod)}
                  placeholder='Select'>
                  {filteredPayMethod.map((item, i) => (
                    <Option key={item.id} value={i} className='option'>
                      {item.paymentChannelId} - {item.destinationAccount}
                    </Option>
                  ))}
                  <Option value={-1}>{`>> ${t('Add Payment Method')} << `}</Option>
                </Select>
              </Form.Item>
            </Col>
          </Row>
          <Row gutter={16}>
            <Col xxl={13} lg={13} md={12} sm={24} xs={24}>
              <Form.Item
                label={paymentLabel}
                name='paymentMethod'
                rules={[
                  {
                    required: true,
                    message: t('Please Select mode of payment') + '!',
                  },
                ]}>
                <Select
                  showSearch
                  size='large'
                  onChange={(value) => selectPayMethod(value, 'paymentMethod', filteredPayMethodFiatAsset)}
                  placeholder='Select'>
                  {filteredPayMethodFiatAsset.map((item, i) => (
                    <Option key={item.id} value={i} className='option'>
                      {item.paymentChannelId} - {item.destinationAccount}
                    </Option>
                  ))}
                  <Option value={-1}>{`>> ${t('Add Payment Method')} << `}</Option>
                </Select>
              </Form.Item>
            </Col>
            <Col xxl={8} lg={8} md={12} sm={24} xs={24}>
              <Form.Item
                label={t('Offer price (per unit)') + ':'}
                name='price'
                rules={[
                  {
                    required: true,
                    message: t('Please set a price'),
                  },
                ]}>
                <InputNumberField
                  size="large" min={0}
                  style={{
                    width: 150,
                  }}
                  name="assetPrice"
                  prefix={currencySymbol}
                  onChange={(value) => onChange(value, 'assetPrice')}
                  // formatter={value => `${currencySymbol} ${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')}
                />
              </Form.Item>
            </Col>
          </Row>
          <Row gutter={16}>
            <Col xxl={20} lg={20} md={20} sm={24} xs={24}>
              <Form.Item label={`${liquidityLabel} ${asset ? '(' + asset + ')' : ''}`}>
                <Form.Item
                  noStyle
                  rules={[{ required: true, message: t('Please add an amount') }]}
                >
                  <Input.Group>
                    <InputNumberField size="large" min={0}
                      value={state.assetAmount}
                      name="assetAmount"
                      onChange={(value) => onChangeAssetAmount(value)}
                    />
                    <Input style={{ width: '20%' }} size="large" value={asset || '-'} />
                  </Input.Group>
                </Form.Item>
              </Form.Item>
              <Form.Item
                label={`${valueLabel} ${state.currencyId ? '(' + state.currencyId + ')' : ''}`}
                name={['Amount:', 'amount']}
                rules={[{ message: t('Please add an amount') }]}
              >
                <Input.Group>
                  <InputNumberField size="large" min={0}
                    prefix={currencySymbol}
                    value={state.amount}
                    name="amount"
                    onChange={(value) => onChangeAmount(value)}
                    formatter={value => `${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')}
                  />
                  <Input style={{ width: '20%' }} size="large" value={state.currencyId || '-'} />
                </Input.Group>
              </Form.Item>
            </Col>
          </Row>
          <Row gutter={12}>
            <Col xxl={8} lg={8} md={10} sm={24} xs={24}>
              <Form.Item
                label={`Min trade amount ${state.currencyId ? '(' + state.currencyId + ')' : ''}:`}
                name='minTradeAmount'
                rules={[
                  {
                    required: true,
                    message: t('Please set a Limit'),
                  },
                ]}>
                <InputNumberField
                  size="large"
                  prefix={currencySymbol}
                  min={0}
                  onChange={(value) => onChange(value, 'minTradeAmount')}
                  formatter={value => `${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')}
                />
              </Form.Item>
            </Col>
            <Col xxl={5} lg={5} md={5} sm={24} xs={24} className='top-limit-to'> <p>{'To'} </p></Col>
            <Col xxl={8} lg={8} md={8} sm={24} xs={24} >
              <Form.Item
                label={`Max trade amount ${state.currencyId ? '(' + state.currencyId + ')' : ''} :`}
                name='maxTradeAmount'
                rules={[
                  {
                    required: true,
                    message: 'Please set a Limit!',
                  },
                ]}
              >
                <InputNumberField
                  size="large"
                  prefix={currencySymbol}
                  min={0}
                  onChange={(value) => onChange(value, 'maxTradeAmount')}
                  style={{
                    width: '100%',
                  }}
                  formatter={value => `${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')}
                />
              </Form.Item>
            </Col>
          </Row>
          <Row>
            <p>Enter Max time per transaction (unit: mins):</p>
              <Col span={17}>
                <Slider 
                  name='maxTimePerTransaction' 
                  size="large" placeholder="00:00:45 = 45 mins"
                  defaultValue={45}
                  max={480}
                  value={state.maxTimePerTransaction}
                  // tooltipVisible
                  onChange={(value) => onChange(value, 'maxTimePerTransaction')} /> 
              </Col>
              <Col span={4}>
                <InputNumber
                  min={1}
                  max={480}
                  style={{ margin: '0 16px' }}
                  value={state.maxTimePerTransaction}
                  onChange={(value) => onChange(value, 'maxTimePerTransaction')}
                />
                </Col>
        </Row>
          {state.maxTimePerTransaction < 60 ? `${state.maxTimePerTransaction} Mins` : `${(state.maxTimePerTransaction / 60).toFixed(1)} hours`}
          <br/>
          <br/>
          <Row >
            <Col xxl={18} lg={18} md={19} sm={24} xs={24}>
              <Form.Item name='remark' label='Enter Remark (Optional):'>
                <TextArea onChange={(value) => onChange(value.target.value, 'remark')} rows={4} placeholder={t('Enter remark and description here')} />
              </Form.Item>
            </Col>
          </Row>
          <Row className='saveBtn'>
            <Button
              type='primary'
              onClick={showPromiseConfirm}
              size='large'
              className='saveBtn'
              disabled={disableSubmit}
              style={{ color: 'white' }}
            >
              {t('Continue')}
            </Button>
          </Row>
        </Form>
      </Col>
      <PaymentMethodModal isModalVisible={isModalVisible} handleCancel={handleCancel} />
      <ConfirmOrderModal
        t={t}
        showConfirmMOdal={showConfirmMOdal}
        confirmHandleOk={confirmHandleOk}
        confirmHandleCancel={confirmHandleCancel}
        loading={loading}
        data={state}
      />
    </Row>
  );
};
export default OfferForm;