const payment = {
    assets: [],
    currencies: [],
    marketPrices: [],
    paymentChannels: {},
    paymentMethods: [],
    loading: false,
    showPaymentNotification: false,
    message: '',
};

export default payment;
