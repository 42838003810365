import React from 'react';
import { Col, Form, Input } from 'antd';



const CryptoForm = ({ setState, state, t }) => {
  const onChange = (e) => {
    const { name, value } = e.target;
    setState({ ...state, [name]: value });
  };
  return (
    <Col xxl={ 24 } lg={ 24 } md={ 24 } sm={ 24 } xs={ 24 }>
      <Form.Item
        label={t('Wallet address')+':'}
        rules={ [
          {
            required: true,
            message: t('Please enter correct Wallet Address'),
          },
        ] }
      >
        <Input name='destinationAccount' onChange={ onChange } size="large" />
      </Form.Item>
      <Form.Item
        label={t('Memo (Optional)')+':'}
      >
        <Input name='memo' onChange={ onChange } size="large" />
      </Form.Item>
    </Col>
  );
};
export default CryptoForm;