
const setItem = (key, value) => localStorage.setItem(key, value);

const getItem = (key) => localStorage.getItem(key);

const removeItem = (key) => localStorage.removeItem(key);

const setToken = (accessToken, refreshToken) => {
    setItem('timbuktu_access_token', accessToken);
    setItem('timbuktu_refresh_token', refreshToken);
};

const clearToken = () => {
    removeItem('timbuktu_access_token');
    removeItem('timbuktu_refresh_token');
    removeItem('timbuktu_username');
}

const getAccessToken = () => getItem('timbuktu_access_token');

const getRefreshToken = () => getItem('timbuktu_refresh_token');

const getLoginId = () => getItem('timbuktu_login_id');

const setPreLogin = (dynamicLink, qrCode, loginId) => {
    setItem('timbuktu_login_id', loginId);
    setItem('timbuktu_dynamic_link', dynamicLink);
    setItem('timbuktu_qr_code', qrCode);
}

const clearPreLogin = () => {
    removeItem('timbuktu_login_id');
    removeItem('timbuktu_dynamic_link');
    removeItem('timbuktu_qr_code');
}

export {
    setToken, getAccessToken,
    clearToken, getRefreshToken, setItem, getItem,
    removeItem, setPreLogin, clearPreLogin, getLoginId,
};
