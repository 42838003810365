const profileData = {
  bantuTalk: "",
  email: "",
  firstName: "",
  imageThumbnail: "",
  lastName: "",
  middleName: "",
  mobile: "",
  username: "",
  kycLevel: 0,
  loading: false,
  offline: 0
};

export default profileData;
