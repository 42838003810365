import React from 'react';
import { Button as AntButton } from 'antd';
import './button.less';

const Button = (props) => {
    return (
        <AntButton { ...props }>
            {props.children }
        </AntButton>
    );
};

export default Button;