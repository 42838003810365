import React, { useState, useEffect } from 'react';
import { Row, Badge,Col } from 'antd';
import { useTranslation  } from 'react-i18next';
import Tabs from '../../Components/Shared/Tabs';
import MyTradeOffers from '../../Components/MyTradeOffers';
import CompletedOrders from '../../Components/MyTradeOffers/CompletedTrades';
import CancelledOrders from '../../Components/MyTradeOffers/CancelledTrades';
import AwaitingTradesOffers from '../../Components/MyTradeOffers/AwaitingTradesOffers';
import { useStateValue } from '../../Providers/stateProvider';
import EmptyComponent from '../Dashboard/empty';
import './offers.less';

const MyOfferTrades = () => {
  const initualValues = {
    1: false,
    2: false,
    3: false,
    4: false,
  }
  const [resetValue, setResetValue] = useState(initualValues);
  const { t } = useTranslation();
  
  const changeTabe = (key) => {
    const newS = {...resetValue, [key]: !resetValue[key]};
    setResetValue(newS)
  }
  
  const [{ order, profile: { kycLevel } }, dispatch] = Object.values(useStateValue());
  const { myTrades } = order;

  useEffect(() => { 
    dispatch({ type: 'SET_PAGE_CURRENT', payload: 'trades' });
  }, [dispatch]);

  const pending = (
    <Badge 
      count={myTrades.Awaiting?.records?.length || 0}
      overflowCount={99}
      offset={[10, -6]}
      style={{ backgroundColor: '#fff', color: '#000', border: '1px solid #000' }}
    >
      {t('Pending')}
    </Badge>
  )

  const tabsData = [
    {
      key: t('Completed'),
      body: (
        <CompletedOrders resetValue={resetValue['3']} t={t} />
      ),
    },
    {
      key: t('Cancelled'),
      body: (
        <CancelledOrders resetValue={resetValue['4']} t={t} />
      ),
    },
  ];

  const pendingTab = {
    key: pending,
    body: (
      <AwaitingTradesOffers resetValue={resetValue['1']} t={t} />
    ),
  };
  const allTab = {
    key: t('All'),
    body: (
      <MyTradeOffers resetValue={resetValue['2']} t={t} />
    ),
  }

  if (myTrades.Awaiting?.records?.length > 0){
    tabsData.unshift(pendingTab, allTab);
  } else {
    tabsData.unshift(allTab, pendingTab);
  }

  return (
    <Row className="container" style={ { border: '1px solid #d9d9d9', minHeight: '100vh' } }>
      { kycLevel > 0 ?
      <Tabs onTabClick={changeTabe} tabs={ tabsData } />
      : <Row
      style={{
        margin: '10px 0',
      }}
      type='flex'>
      <Col>
      <div style={ { minHeight: '100vh' } }>
        <EmptyComponent t={t} />
      </div>
      </Col>
    </Row>}
    </Row>
  );
};
export default MyOfferTrades;
