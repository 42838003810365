const paymentTypes = {
    GET_PAYMENT_CHANNELS_REQUEST: 'GET_PAYMENT_CHANNELS_REQUEST',
    GET_PAYMENT_CHANNELS: 'GET_PAYMENT_CHANNELS',
    GET_PAYMENT_LIST_REQUEST: 'GET_PAYMENT_LIST_REQUEST',
    GET_PAYMENT_LIST: 'GET_PAYMENT_LIST',
    ADD_PAYMENT_REQUEST: 'ADD_PAYMENT_REQUEST',
    ADD_PAYMENT: 'ADD_PAYMENT',
    ERROR: 'ERROR',
    DELETE_PAYMENT_REQUEST: 'DELETE_PAYMENT_REQUEST',
    SET_NOTIFICATION: 'SET_NOTIFICATION',
    DELETE_PAYMENT: 'DELETE_PAYMENT'
}

export default paymentTypes;
