import dotenv from "dotenv";
import { getAccessToken, getItem } from '../Services/localStorageService';
import Type from '../Providers/reducers/orders/type';
dotenv.config()
const BASE_URL = 'p2p-api-alpha.dev.bantupay.org';

export const userOrderData = (dispatch) => {
    const token = getAccessToken();
    const username = getItem('timbuktu_username')
    const url = `wss://${BASE_URL}/v1/streams/private/${username}`;
    const ws = new WebSocket(url);

    ws.onopen = () => {
        ws.send(JSON.stringify({ token: `Bearer ${token}` }));
    }

    ws.onmessage = (event) => {
        const response = JSON.parse(event.data);
        if (response.stream_type === "orderChanged") {
            dispatch({
                type: Type.SOCKET_ORDER_UPDATE,
                payload: response.stream.order,
                message: 'You have an update to your order',
            });
        } else if (response.stream_type === "orderCreated") {
            const { offerMaker } = response.stream.order;
            if (username.includes(offerMaker)) {
                dispatch({
                    type: Type.SOCKET_NEW_ORDER,
                    payload: response.stream.order,
                    message: 'You have a new order'
                });
            }
        }
    }

    ws.onclose = () => {
        ws.close();
    };

    return () => {
        ws.close();
    };
};
