import React, { useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { Row, Col, Image, Button, Input } from 'antd';
import { UserOutlined } from '@ant-design/icons';
import { Link } from 'react-router-dom';
import { useStateValue } from '../../Providers/stateProvider';
import { userLogin, verifyLogin } from '../../Providers/action/user';
import { setItem, clearToken, clearPreLogin } from '../../Services/localStorageService';
import Loading from '../../Components/Shared/Loading';

import './login.less';
import img from '../../assets/img/bulletPoint.png';
// import { getItem } from '../../Providers/reducers/i18n/utils';


const Home = () => {
  const { t, i18n } = useTranslation();
  const [{ i18n: defaultLanguage, user }, dispatch] = Object.values(useStateValue());
  const [page, setPage] = useState(1);
  const [username, setUsername] = useState('');
  const history = useHistory();

  const { loading, dynamicLink, loginId, qrCode, verified, counter } = user;

  const onChange = (e) => {
    const { value } = e.target;
    setUsername(value?.trim());
  };

  const submitForm = () => {
    let user_name;

    if (!username.includes('@')) {
      user_name = `${username}@bantupay`?.trim();
    } else {
      user_name = username?.trim();
    }

    setItem('timbuktu_username', user_name);
    userLogin({ username: user_name }, dispatch);
  };

  const submitOnEnter = (e) => {
    if (e.key === 'Enter') {
      submitForm();
    }
  };
  useEffect(() => {
    i18n.changeLanguage(defaultLanguage);
  }, [defaultLanguage, i18n]);

  useEffect(() => {
    if (loginId) {
      let user_name;

      if (!username.includes('@')) {
        user_name = `${username}@bantupay`;
      } else {
        user_name = username;
      }

      setPage(2);
      let timer;

      const data = {
        loginId,
        username: user_name
      };
      timer = setTimeout(async () => {
        await verifyLogin(data, dispatch);
      }, 5000);

      if (verified) {
        clearTimeout(timer);
        // const from = getItem('from')
        history.push('/buy');
      }
    }
  }, [loginId, username, dispatch, verified, counter, history]);

  const reset = () => {
    clearPreLogin();
    clearToken();
    setPage(1);
    dispatch({ type: 'RESET' })
  }

  const resetLogin = () => (
    <p onClick={reset} style={{ cursor: 'pointer', textAlign: 'center', color: '#f86912', fontSize: '20px', fontWeight: '600' }}>Reset Login</p>
  )

  const loginForm = () => (
    <div style={{ textAlign: 'center' }}>
      <br />
      <br />
      <br />
      <h5>{t('Enter your BantuPay username')}</h5>
      <Input onChange={onChange} size="large" onKeyDown={submitOnEnter} placeholder="Username" prefix={<UserOutlined />} />
      <br />
      <br />
      <Button onClick={() => submitForm()} disabled={loading} className="loginbtn"> {loading ? <> <Loading /> &nbsp; {t('Submiting')}... </> : t('Login')}</Button>
      <br /> <br />
    </div>
  );

  return (
    <div className='bg-img' style={{ minHeight: '120vh' }}>
      <div>
        <Row>
          <Col className="loginWrap d-none d-lg-block"></Col>
        </Row>
      </div>
      <div className="loginContainer">
        <Row>
          <Col className="hide-lg" span={24} style={{ textAlign: 'center' }}>
            {page === 1 ? loginForm() :
              <>
                {resetLogin()}
                <Image preview={false} style={{ width: '85%', margin: '0 auto' }} src={qrCode} alt="bullet point" />
                {t('or')}<br />
                <Button onClick={() => window.open(dynamicLink, '_blank')} className="loginbtn">
                  {t('Authorize with BantuPay')}
                </Button>
                <br />
                <br />
                <br />
              </>
            }
          </Col>
        </Row>
        <Row>
          <Col sm={24} lg={16}>
            <p className="headerText">{t("HOW IT WORKS")}</p>
            <p style={{ fontSize: '16px' }}><>{t('You will need a BantuPay Wallet to transact on Timbuktu')}</></p>
            <div className="hide-sm">
              <Row>
                <Col span={1} style={{ marginRight: '5%' }}>
                  <Image preview={false} style={{ width: '85%' }} src={img} alt="bullet point" />
                </Col>
                <Col span={20} className="bulletPoints">{t('On this Timbuktu page, enter your BantuPay username in the login field provided and tap on the login button to reveal the QR code.')}</Col>
              </Row>
              <Row>
                <Col span={1} style={{ marginRight: '5%' }}><Image preview={false} style={{ width: '85%' }} src={img} alt="bullet point" /> </Col>
                <Col span={20} className="bulletPoints">{t('Launch your BantuPay wallet on your mobile phone.')}</Col>
              </Row>
              <Row>
                <Col span={1} style={{ marginRight: '5%' }}><Image preview={false} style={{ width: '85%' }} src={img} alt="bullet point" /> </Col>
                <Col span={20} className="bulletPoints">{t('On your BantuPay wallet, tap on the QR code scanner at the bottom right corner if not logged in or at the top right hand corner of your homepage if logged in.')}</Col>
              </Row>
              <Row>
                <Col span={1} style={{ marginRight: '5%' }}><Image preview={false} style={{ width: '85%' }} src={img} alt="bullet point" /> </Col>
                <Col span={20} className="bulletPoints">{t('On your BantuPay Wallet, authorize with Biometrics or use your password. If successful, you will get a success message and will be logged in on Timbuktu. If unsuccessful, ensure that you have used a valid BantuPay username and password, and try again.')}</Col>
              </Row>
              <Row>
                <Col span={1} style={{ marginRight: '5%' }}><Image preview={false} style={{ width: '85%' }} src={img} alt="bullet point" /> </Col>
                <Col span={20} className="bulletPoints">{t('You have now successfully signed into Timbuktu. Customize your profile, add your payment methods and begin transacting on Timbuktu.')}</Col>
              </Row>
              <Row>
                <Col span={24}>
                  <div>{t('If you do not have the Bantupay app')}, <a href="https://bantupay.org/" target="_blank" rel="noreferrer"> {t('click here to download')}</a></div>
                </Col>
              </Row>
            </div>
            <div className="hide-lg">
              <Row>
                <Col span={1} style={{ marginRight: '5%' }}><Image preview={false} style={{ width: '85%' }} src={img} alt="bullet point" /> </Col>
                <Col span={20} className="bulletPoints">{t("On this Timbuktu page, enter your BantuPay username in the login field provided and tap on the login button to reveal the QR code and an 'Authorize with BantuPay' button.")}</Col>
              </Row>
              <Row>
                <Col span={1} style={{ marginRight: '5%' }}><Image preview={false} style={{ width: '85%' }} src={img} alt="bullet point" /> </Col>
                <Col span={20} className="bulletPoints">{t("Tap on the 'Authorize with BantuPay' button if your bantuPay app is on the same mobile device. This takes you to your BantuPay wallet for authorization.")}</Col>
              </Row>
              <Row>
                <Col span={1} style={{ marginRight: '5%' }}><Image preview={false} style={{ width: '85%' }} src={img} alt="bullet point" /> </Col>
                <Col span={20} className="bulletPoints">{t("If your BantuPay app is on another device, launch it and tap on the QR code scanner at the bottom right corner if not logged in or at the top right hand corner of your homepage if logged in.")}</Col>
              </Row>
              <Row>
                <Col span={1} style={{ marginRight: '5%' }}><Image preview={false} style={{ width: '85%' }} src={img} alt="bullet point" /> </Col>
                <Col span={20} className="bulletPoints">{t("On your BantuPay Wallet, authorize with Biometrics or use your password. If successful, you will get a success message and you will be logged in on Timbuktu. If unsuccessful, ensure that you have used a valid BantuPay username and password, and try again.")}</Col>
              </Row>
              <Row>
                <Col span={1} style={{ marginRight: '5%' }}><Image preview={false} style={{ width: '85%' }} src={img} alt="bullet point" /> </Col>
                <Col span={20} className="bulletPoints">{t("You have now successfully signed into Timbuktu. Customize your profile, add your payment methods and begin transacting on Timbuktu.")}</Col>
              </Row>
              <Row>
                <Col span={24}>
                  <div>{t('If you do not have the Bantupay app')},
                    <a href="https://bantupay.org/" target="_blank" rel="noreferrer"> {t('click here to download')}</a></div>
                </Col>
              </Row>
            </div>
            <br />
            <br />
            <p className="pclass">{t('Need more help to get started? See user guide ')}<span><Link to='/manual' style={{ fontWeight: 700 }}>{t('here')}</Link></span></p>
            {/* <p className="pclass">{ t('Need more help to get started? Watch video tutorial below.') }</p> 
            <ReactPlayer width="100%" url='https://www.youtube.com/watch?v=3OVjGnK1-cg' /> */}
          </Col>
          <Col className="hide-sm" lg={8}>
            {page === 1 ? loginForm() :
              <>
                {resetLogin()}
                <Image preview={false} src={qrCode} alt="bullet point" />
              </>
            }
          </Col>
        </Row>
      </div>
    </div >
  );
};

export default Home;
