import React, { useEffect } from 'react';

import 'bootstrap/dist/css/bootstrap.min.css';

import Routes from './Routes';
import Navigationbar from './Components/Shared/Navigationbar';
import Footer from './Components/Shared/Footer';
import Type from './Providers/reducers/i18n/type';
import { useStateValue } from './Providers/stateProvider';
import { clearPreLogin } from './Services/localStorageService';

import './App.less';

const App = () => {
  const [, dispatch] = Object.values(useStateValue());

  useEffect(() => {
    dispatch({
      type: Type.LOAD_DEFAULT_LANGUAGE
    });
  }, [dispatch]);

  clearPreLogin();

  return (
    <>
      <Navigationbar />
      <Routes />
      <Footer />
    </>
  );
};

export default App;
