import {
    getPaymentDataRequest,
    addPaymentDataRequest,
    getPaymentDataChannels,
    deletePaymentMethodRequest
} from '../../Services/apiRequest';
import paymentTypes from '../reducers/payments/type';

const {
    GET_PAYMENT_CHANNELS_REQUEST,
    GET_PAYMENT_CHANNELS,
    GET_PAYMENT_LIST_REQUEST,
    GET_PAYMENT_LIST,
    ADD_PAYMENT_REQUEST,
    ADD_PAYMENT,
    ERROR,
    DELETE_PAYMENT_REQUEST,
    DELETE_PAYMENT
} = paymentTypes;

const getPaymentChannelsData = async (dispatch) => {
    try {
        dispatch({
            type: GET_PAYMENT_CHANNELS_REQUEST
        });

        const paymentChannels = await getPaymentDataChannels();
        dispatch({
            type: GET_PAYMENT_CHANNELS,
            payload: paymentChannels?.data
        });
    } catch (e) {
        dispatch({
            type: ERROR,
            payload: e
        })
    }
}

const getPaymentData = async (dispatch) => {
    try {
        dispatch({
            type: GET_PAYMENT_LIST_REQUEST
        });

        const paymentList = await getPaymentDataRequest();
        dispatch({
            type: GET_PAYMENT_LIST,
            payload: paymentList?.data
        });
    } catch (e) {
        dispatch({
            type: ERROR,
            payload: e
        })
    }
}

const addPayment = async (dispatch, data) => {
    try {
        dispatch({
            type: ADD_PAYMENT_REQUEST
        });
        const newPayment = await addPaymentDataRequest(data);
        dispatch({
            type: ADD_PAYMENT,
            payload: newPayment.data
        });

    } catch (e) {
        dispatch({
            type: ERROR,
            payload: e
        });
    }
}
const deletePayment = async (dispatch, data) => {
    dispatch({
        type: DELETE_PAYMENT_REQUEST
    });
    try {
        const response = await deletePaymentMethodRequest(data);
        dispatch({
            type: DELETE_PAYMENT,
            payload: response?.data,
        });
    } catch (e) {
        dispatch({
            type: ERROR,
            payload: e
        });
    }
};

export {
    getPaymentData,
    getPaymentChannelsData,
    addPayment,
    deletePayment
};
