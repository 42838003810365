import axios from 'axios';
import dotenv from 'dotenv';
import customAxios from './customAxios';
dotenv.config();


const baseURL = process.env.REACT_APP_URL || 'https://p2p-api-alpha.dev.bantupay.org';

const loginRequest = (data) => axios.post(`${baseURL}/v1/login`, data);

const verifyLoginRequest = (data) => axios.get(`${baseURL}/v1/users/verify/${data.username}/${data.loginId}`);

const getPaymentDataChannels = () => customAxios.get(`${baseURL}/v1/payments`);

const getPaymentDataRequest = () => customAxios.get(`${baseURL}/v1/users/payments`);

const addPaymentDataRequest = (data) => customAxios.post(`${baseURL}/v1/users/payments`, data);

const getProfileDataRequest = (data) => customAxios.get(`${baseURL}/v1/users/detail/${data.username}`);

const createOfferRequest = (data) => customAxios.post(`${baseURL}/v1/users/offers`, data);

const getUserOfferRequest = (data) => customAxios.get(`${baseURL}/v1/users/offers?page=${data.page}&limit=${data.limit}`);

const getOfferListRequest = (data) => axios.get(`${baseURL}/v1/offers?currencyId=${data.currencyId}&offerType=${data.offerType}&assetId=${data.assetId}&currencyPaymentChannelId=${data.currencyPaymentChannelId}&page=${data.page}&limit=${data.limit}`);

const logoutRequest = () => customAxios.post(`${baseURL}/v1/logout`);

const deletePaymentMethodRequest = (data) => customAxios.delete(`${baseURL}/v1/payments/${data.paymentMethodID}`);

const placeOrderRequest = (data) => customAxios.post(`${baseURL}/v1/users/orders`, data);

const deleteOrderRequest = (data) => customAxios.delete(`${baseURL}/v1/users/orders/${data.orderId}`, data);

const acceptOrderRequest = (data) => customAxios.put(`${baseURL}/v1/users/trades/${data.orderId}`);

const getUserOrderListRequest = (data) => customAxios.get(`${baseURL}/v1/users/orders?resultGroup=${data.resultGroup}&page=${data.page}&limit=${data.limit}`);

const getUserTradeListRequest = (data) => customAxios.get(`${baseURL}/v1/users/trades?resultGroup=${data.resultGroup}&page=${data.page}&limit=${data.limit}`);

const getUserOrderDetailsRequest = (data) => customAxios.get(`${baseURL}/v1/users/${data.type}/${data.orderId}`);

const takerConfirmEscrowDepositRequest = (data) => customAxios.post(`${baseURL}/v1/users/orders/${data.orderId}/${data.transactionID}`);

const makerConfirmEscrowDepositRequest = (data) => customAxios.post(`${baseURL}/v1/users/trades/${data.orderId}/${data.transactionID}`);

const takerCompleteOrderRequest = (data) => customAxios.put(`${baseURL}/v1/users/orders/complete/${data.orderId}`);

const makerCompleteOrderRequest = (data) => customAxios.put(`${baseURL}/v1/users/trades/complete/${data.orderId}`);

const confirmTakerFiatDepositRequest = (data) => customAxios.put(`${baseURL}/v1/users/orders/fiat/${data.orderId}`, data);

const confirmMakerFiatDepositRequest = (data) => customAxios.put(`${baseURL}/v1/users/trades/fiat/${data.orderId}`, data);

const editOfferRequest = (data) => customAxios.put(`${baseURL}/v1/users/offers/${data.id}`, data);

const deleteOfferRequest = (data) => customAxios.delete(`${baseURL}/v1/users/offers/${data.id}`, data);

const setOfflineStatusRequest = () => customAxios.put(`${baseURL}/v1/users/toggle`);

const setTradContactRequest = (data) => customAxios.put(`${baseURL}/v1/users/update`, data);

const rejectOrderRequest = (data) => customAxios.delete(`${baseURL}/v1/users/trades/${data.orderId}`, data);

const takerAppealOrderRequest = (data) => customAxios.post(`${baseURL}/v1/users/appeals/orders/${data.orderId}`, data);

const makerAppealOrderRequest = (data) => customAxios.post(`${baseURL}/v1/users/appeals/trades/${data.orderId}`, data);


export {
    loginRequest,
    verifyLoginRequest,
    getPaymentDataRequest,
    getPaymentDataChannels,
    addPaymentDataRequest,
    getProfileDataRequest,
    createOfferRequest,
    getOfferListRequest,
    getUserOfferRequest,
    logoutRequest,
    deletePaymentMethodRequest,
    placeOrderRequest,
    acceptOrderRequest,
    deleteOrderRequest,
    getUserOrderListRequest,
    getUserTradeListRequest,
    getUserOrderDetailsRequest,
    takerConfirmEscrowDepositRequest,
    makerConfirmEscrowDepositRequest,
    takerCompleteOrderRequest,
    makerCompleteOrderRequest,
    confirmMakerFiatDepositRequest,
    confirmTakerFiatDepositRequest,
    editOfferRequest,
    setOfflineStatusRequest,
    setTradContactRequest,
    rejectOrderRequest,
    takerAppealOrderRequest,
    makerAppealOrderRequest,
    deleteOfferRequest,
}
