import errorState from './state';
import Type from './type';

const errorReducer = (error = errorState, { payload = {}, type, errorTitle }) => {
    const defaultMsg = 'Something went wrong, please try again later.';
    const { response = {} } = payload;
    switch (type) {
        case Type.ERROR:
            if (response.status >= 500) {
                return {
                    ...error,
                    message: defaultMsg,
                    showErrorNotification: true,
                }
            } else if (response?.data?.error === 'refresh token expired') {
                window.location = '/#/logout';
                return error;
            } else if (response?.status === 401 && response?.data?.error === 'unauthorized to perform this action') {
                return error;
            } else {
                const message = response?.data?.message;
                console.log(message, 'ERROR <<<');
                return {
                    ...error,
                    message: message || defaultMsg,
                    showErrorNotification: true,
                }
            }
        case Type.SET_NOTIFICATION:
            return {
                ...error,
                showErrorNotification: false
            }
        case Type.CONFIRM_ERROR:
            return {
                ...error,
                showErrorModal: true,
                message: response?.data?.message,
                errorTitle,
            }
        case Type.SET_CONFIRM_ERROR:
            return {
                showErrorModal: payload,
                message: '',
                errorTitle: '',
            }
        case Type.CLEAR_ERROR:
            return {
                ...error,
                loading: false,
                message: '',
                showErrorNotification: false,
                showErrorModal: false,
                errorTitle: ''
            }
        default:
            return error;
    }
};

export default errorReducer;