import appState from './state';
import Type from './type';

const appReducer = (app = appState, { payload, type }) => {
    switch (type) {
        case Type.SET_PAGE_CURRENT:
            return {
                ...app,
                current: payload
            }
        default:
            return app;
    }
};

export default appReducer;
