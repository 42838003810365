import React, { useEffect } from 'react';
import { Row, Col } from 'antd';
import { useTranslation } from 'react-i18next';
import MyOffers from '../../Components/MyOffers';
import { useStateValue } from '../../Providers/stateProvider';
import EmptyComponent from '../Dashboard/empty';

import './offers.less';

const MyOfferList = () => {
  const [{ profile: { kycLevel }}, dispatch] = Object.values(useStateValue());
  const { t } = useTranslation();
  useEffect(() => { 
    dispatch({ type: 'SET_PAGE_CURRENT', payload: 'offers' });
  }, [dispatch]);

  return (
    <Row className="container" style={{ border: '1px solid #d9d9d9', minHeight: '100vh'  }}>
      { kycLevel > 0 ?
      <>
        <Col span={24}>
          <MyOffers />
        </Col>
      </>
      :  <Row
      style={{
        margin: '10px 0',
      }}
      type='flex'>
      <Col>
      <div style={ { minHeight: '100vh' } }>
        <EmptyComponent t={t} />
      </div>
      </Col>
    </Row>}
    </Row>


  );
};
export default MyOfferList;
