const orderType = {
    PLACE_ORDER_REQUEST: 'PLACE_ORDER_REQUEST',
    PLACE_ORDER: 'PLACE_ORDER',
    DELETE_ORDER_REQUEST: 'DELETE_ORDER_REQUEST',
    DELETE_ORDER: 'DELETE_ORDER',
    GET_USER_ORDER_LIST_REQUEST: 'GET_USER_ORDER_LIST_REQUEST',
    GET_USER_ORDER_LIST: 'GET_USER_ORDER_LIST',
    GET_USER_ORDER_DETAILS_REQUEST: 'GET_USER_ORDER_DETAILS_REQUEST',
    GET_USER_ORDER_DETAILS: 'GET_USER_ORDER_DETAILS',
    GET_USER_TRADES_DETAILS_REQUEST: 'GET_USER_TRADES_DETAILS_REQUEST',
    GET_USER_TRADES_DETAILS: 'GET_USER_TRADES_DETAILS',
    GET_USER_TRADE_LIST_REQUEST: 'GET_USER_TRADE_LIST_REQUEST',
    GET_USER_TRADE_LIST: 'GET_USER_TRADE_LIST',
    CONFIRM_DEPOSITE_REQUEST: 'CONFIRM_DEPOSITE_REQUEST',
    CONFIRM_DEPOSITE: 'CONFIRM_DEPOSITE',
    MAKER_CONFIRM_DEPOSITE_REQUEST: 'TAKER_CONFIRM_DEPOSITE_REQUEST',
    MAKER_CONFIRM_DEPOSITE: 'MAKER_CONFIRM_DEPOSITE',
    TAKER_COMPLETE_ORDER_REQUEST: 'TAKER_COMPLETE_ORDER_REQUEST',
    TAKER_COMPLETE_ORDER: 'TAKER_COMPLETE_ORDER',
    MAKER_COMPLETE_ORDER: 'MAKER_COMPLETE_ORDER',
    MAKER_COMPLETE_ORDER_REQUEST: 'MAKER_COMPLETE_ORDER_REQUEST',
    ACCEPT_ORDER_REQUEST: 'ACCEPT_ORDER_REQUEST',
    ACCEPT_ORDER: 'ACCEPT_ORDER',
    SOCKET_ORDER_UPDATE: 'SOCKET_ORDER_UPDATE',
    SOCKET_NEW_ORDER: 'SOCKET_NEW_ORDER',
    SET_NOTIFICATION_SOUND: 'SET_NOTIFICATION_SOUND',
    SET_NOTIFICATION: 'SET_NOTIFICATION',
    RESET: 'RESET',
    ERROR: 'ERROR',
    REJECT_OFFER_REQUEST: 'REJECT_OFFER_REQUEST',
    REJECT_OFFER: 'REJECT_OFFER',
    CONFIRM_ERROR: "CONFIRM_ERROR",
    TAKER_APPEAL_ORDER_REQUEST: "TAKER_APPEAL_ORDER_REQUEST",
    MAKER_APPEAL_ORDER_REQUEST: "MAKER_APPEAL_ORDER_REQUEST",
    TAKER_APPEAL_ORDER: "TAKER_APPEAL_ORDER",
    MAKER_APPEAL_ORDER: "MAKER_APPEAL_ORDER",
}

export default orderType;
