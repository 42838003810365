import Type from './type';
import defaultState from './state';
import { setItem, getItem } from './utils';

const i18n = (state = defaultState, action) => {
    const { type, payload } = action;
    switch (type) {
        case Type.CHANGE_DEFAULT_LANGUAGE:
            setItem('defaultLanguage', payload);
            return payload;
        case Type.LOAD_DEFAULT_LANGUAGE:
            const language = getItem('defaultLanguage');
            if (language === null) {
                setItem('defaultLanguage', state);
                return state;
            } else {
                return language;
            }
        default:
            return state;
    }

}

export default i18n;
