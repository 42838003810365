import React from 'react'
import { Row } from 'antd'

import ProcessOrderCompoment from '../../Components/Orders/OrderDetail/processOrder';

const ProcessOrder = () => {
    return (
        <Row className="container">
            <ProcessOrderCompoment />
        </Row>
    )
}

export default ProcessOrder
