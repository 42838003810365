import {
  placeOrderRequest,
  deleteOrderRequest,
  getUserOrderListRequest,
  getUserTradeListRequest,
  getUserOrderDetailsRequest,
  takerConfirmEscrowDepositRequest,
  makerConfirmEscrowDepositRequest,
  takerCompleteOrderRequest,
  makerCompleteOrderRequest,
  acceptOrderRequest,
  confirmTakerFiatDepositRequest,
  confirmMakerFiatDepositRequest,
  rejectOrderRequest,
  makerAppealOrderRequest,
  takerAppealOrderRequest,
} from '../../Services/apiRequest';
import orderTypes from '../reducers/orders/type';

const {
  PLACE_ORDER_REQUEST,
  PLACE_ORDER,
  DELETE_ORDER_REQUEST,
  DELETE_ORDER,
  GET_USER_ORDER_LIST_REQUEST,
  GET_USER_ORDER_LIST,
  GET_USER_TRADE_LIST_REQUEST,
  GET_USER_TRADE_LIST,
  GET_USER_ORDER_DETAILS_REQUEST,
  GET_USER_ORDER_DETAILS,
  ACCEPT_ORDER_REQUEST,
  ACCEPT_ORDER,
  GET_USER_TRADES_DETAILS_REQUEST,
  GET_USER_TRADES_DETAILS,
  ERROR,
  REJECT_OFFER_REQUEST,
  REJECT_OFFER,
  CONFIRM_ERROR,
  TAKER_APPEAL_ORDER_REQUEST,
  TAKER_APPEAL_ORDER,
  MAKER_APPEAL_ORDER_REQUEST,
  MAKER_APPEAL_ORDER,
} = orderTypes;

const placeOrder = async (dispatch, data) => {
  try {
    dispatch({
      type: PLACE_ORDER_REQUEST
    });

    const orderData = await placeOrderRequest(data);
    dispatch({
      type: PLACE_ORDER,
      payload: orderData?.data
    });

  } catch (e) {
    dispatch({
      type: CONFIRM_ERROR,
      payload: e,
      errorTitle: 'Place order error.'
    })
  }
}

const getUserOrders = async (dispatch, data) => {
  try {
    dispatch({
      type: GET_USER_ORDER_LIST_REQUEST
    });

    const ordersList = await getUserOrderListRequest(data);
    const All = 'All';
    dispatch({
      type: GET_USER_ORDER_LIST,
      payload: {
        [data?.resultGroup || All]: ordersList.data
      }
    });

  } catch (e) {
    dispatch({
      type: ERROR,
      payload: e,
    });
  };
};

const getUserTrades = async (dispatch, data) => {
  try {
    dispatch({
      type: GET_USER_TRADE_LIST_REQUEST
    });

    const tradesList = await getUserTradeListRequest(data);
    const All = 'All';
    dispatch({
      type: GET_USER_TRADE_LIST,
      payload: {
        [data?.resultGroup || All]: tradesList.data
      }
    });

  } catch (e) {
    dispatch({
      type: ERROR,
      payload: e,
    });
  };
};

const deleteOrder = async (dispatch, data) => {
  try {
    dispatch({
      type: DELETE_ORDER_REQUEST
    });

    const orderData = await deleteOrderRequest(data);
    dispatch({
      type: DELETE_ORDER,
      payload: orderData?.data
    });

  } catch (e) {
    dispatch({
      type: ERROR,
      payload: e
    })
  }
};

const getUserOrderDetails = async (dispatch, data, showLoading = true) => {
  if (data.type === 'trades') {
    try {
      if (showLoading) {
        dispatch({
          type: GET_USER_TRADES_DETAILS_REQUEST
        });
      }

      const orderData = await getUserOrderDetailsRequest(data);
      dispatch({
        type: GET_USER_TRADES_DETAILS,
        user: 'maker',
        payload: orderData?.data
      });

    } catch (e) {
      dispatch({
        type: ERROR,
        payload: e
      })
    }
  } else if (data.type === 'orders') {
    try {
      if (showLoading) {
        dispatch({
          type: GET_USER_ORDER_DETAILS_REQUEST
        });
      }

      const orderData = await getUserOrderDetailsRequest(data);
      dispatch({
        type: GET_USER_ORDER_DETAILS,
        user: 'taker',
        payload: orderData?.data
      });

    } catch (e) {
      dispatch({
        type: ERROR,
        payload: e
      })
    }
  }

};

const takerComfirmDeposit = async (dispatch, data) => {
  try {
    dispatch({
      type: GET_USER_ORDER_DETAILS_REQUEST
    });

    const orderData = await takerConfirmEscrowDepositRequest(data);
    dispatch({
      type: GET_USER_ORDER_DETAILS,
      payload: orderData?.data
    });

  } catch (e) {
    dispatch({
      type: ERROR,
      payload: e
    })
  }
};

const makerComfirmDeposit = async (dispatch, data) => {
  try {
    dispatch({
      type: GET_USER_ORDER_DETAILS_REQUEST
    });

    const orderData = await makerConfirmEscrowDepositRequest(data);
    dispatch({
      type: GET_USER_ORDER_DETAILS,
      payload: orderData?.data
    });

  } catch (e) {
    dispatch({
      type: ERROR,
      payload: e
    })
  }
};

const takerCompleteOrder = async (dispatch, data) => {
  try {
    dispatch({
      type: GET_USER_ORDER_DETAILS_REQUEST
    });

    const orderData = await takerCompleteOrderRequest(data);
    dispatch({
      type: GET_USER_ORDER_DETAILS,
      payload: orderData?.data
    });

  } catch (e) {
    dispatch({
      type: ERROR,
      payload: e
    })
  }
};

const makerCompleteOrder = async (dispatch, data) => {
  try {
    dispatch({
      type: GET_USER_ORDER_DETAILS_REQUEST
    });

    const orderData = await makerCompleteOrderRequest(data);
    dispatch({
      type: GET_USER_ORDER_DETAILS,
      payload: orderData?.data
    });

  } catch (e) {
    dispatch({
      type: ERROR,
      payload: e
    })
  }
};

const acceptOrder = async (dispatch, data) => {
  try {
    dispatch({
      type: ACCEPT_ORDER_REQUEST
    });

    const orderData = await acceptOrderRequest(data);
    dispatch({
      type: ACCEPT_ORDER,
      payload: orderData?.data
    });

  } catch (e) {
    dispatch({
      type: ERROR,
      payload: e
    })
  }
};

const confirmTakerFiatDeposit = async (dispatch, data) => {
  try {
    dispatch({
      type: ACCEPT_ORDER_REQUEST
    });

    const orderData = await confirmTakerFiatDepositRequest(data);
    dispatch({
      type: ACCEPT_ORDER,
      payload: orderData?.data
    });

  } catch (e) {
    dispatch({
      type: ERROR,
      payload: e
    })
  }
};

const confirmMakerFiatDeposit = async (dispatch, data) => {
  try {
    dispatch({
      type: ACCEPT_ORDER_REQUEST
    });

    const orderData = await confirmMakerFiatDepositRequest(data);
    dispatch({
      type: ACCEPT_ORDER,
      payload: orderData?.data
    });

  } catch (e) {
    dispatch({
      type: ERROR,
      payload: e
    })
  }
};

const rejectOrder = async (dispatch, data) => {
  try {
    dispatch({
      type: REJECT_OFFER_REQUEST
    });

    const orderData = await rejectOrderRequest(data);
    dispatch({
      type: REJECT_OFFER,
      payload: orderData?.data
    });

  } catch (e) {
    dispatch({
      type: ERROR,
      payload: e
    })
  }
};

const makerAppealOrder = async (dispatch, data) => {
  try {
    dispatch({
      type: MAKER_APPEAL_ORDER_REQUEST
    });

    const orderData = await makerAppealOrderRequest(data);
    dispatch({
      type: MAKER_APPEAL_ORDER,
      payload: orderData?.data
    });

  } catch (e) {
    dispatch({
      type: ERROR,
      payload: e
    })
  }
};

const takerAppealOrder = async (dispatch, data) => {
  try {
    dispatch({
      type: TAKER_APPEAL_ORDER_REQUEST
    });

    const orderData = await takerAppealOrderRequest(data);
    dispatch({
      type: TAKER_APPEAL_ORDER,
      payload: orderData?.data
    });

  } catch (e) {
    dispatch({
      type: ERROR,
      payload: e
    })
  }
};

export {
  deleteOrder,
  placeOrder,
  getUserOrders,
  getUserTrades,
  getUserOrderDetails,
  takerComfirmDeposit,
  makerComfirmDeposit,
  takerCompleteOrder,
  makerCompleteOrder,
  acceptOrder,
  confirmTakerFiatDeposit,
  confirmMakerFiatDeposit,
  rejectOrder,
  makerAppealOrder,
  takerAppealOrder,
};
