
export const formatPaymentChannels = (data) => {

    const {
        assets,
        currencies,
        paymentChannels,
        marketPrices
    } = data;

    let m = {};

    paymentChannels.map(p => {
        if (m[p.paymentType]) { m[p.paymentType].push(p.id) } else { m[p.paymentType] = [p.id] }
        return true;
    });

    return {
        assets,
        currencies,
        paymentChannels: m,
        marketPrices
    };
}