import i18n from "i18next";
import LanguageDetector from "i18next-browser-languagedetector";
import XHR from "i18next-xhr-backend";

import english from "./locales/en/english.json";
import arabic from './locales/ar/arabic.json';
import spanish from './locales/es/spanish.json';
import french from './locales/fr/french.json';
import hausa from './locales/ha/hausa.json';
import hindi from './locales/hi/hindi.json';
import japanese from './locales/ja/japanese.json';
import malay from './locales/ms/malay.json';
import panjabi_punjabi from './locales/pa/panjabi_punjabi.json';
import polish from './locales/pl/polish.json';
import portuguese from './locales/pt/portuguese.json';
import russian from './locales/ru/russian.json';
import swahili from './locales/sw/swahili.json';
import tagalog from './locales/tl/tagalog.json';
import chinese from './locales/zh/chinese.json';
import indonesian from './locales/id/indonesian.json';
import igbo from './locales/ig/igbo.json';
import turkish from './locales/tr/turkish.json';
import vietnamese from './locales/vi/vietnamese.json';
import yoruba from './locales/yo/yoruba.json';
import korean from './locales/ko/korean.json';
import bengali from './locales/bn/bengali.json'


i18n
    .use(XHR)
    .use(LanguageDetector)
    .init({
        debug: true,
        lng: "en",
        fallbackLng: "en", // use en if detected lng is not available

        keySeparator: false, // we do not use keys in form messages.welcome

        interpolation: {
            escapeValue: false // react already safes from xss
        },

        resources: {
            en: {
                translations: english
            },
            ar: {
                translations: arabic
            },
            es: {
                translations: spanish
            },
            fr: {
                translations: french
            },
            ha: {
                translations: hausa
            },
            hi: {
                translations: hindi
            },
            ja: {
                translations: japanese
            },
            ms: {
                translations: malay
            },
            pa: {
                translations: panjabi_punjabi
            },
            pl: {
                translations: polish
            },
            pt: {
                translations: portuguese
            },
            ru: {
                translations: russian
            },
            sw: {
                translations: swahili
            },
            tl: {
                translations: tagalog
            },
            zh: {
                translations: chinese
            },
            id: {
                translations: indonesian
            },
            ig: {
                translations: igbo
            },
            tr: {
                translations: turkish
            },
            vi: {
                translations: vietnamese
            },
            yo: {
                translations: yoruba
            },
            ko: {
                translations: korean
            },
            bn: {
                translations: bengali
            },
        },
        // have a common namespace used around the full app
        ns: ["translations"],
        defaultNS: "translations"
    });

export default i18n;
