export const stepValue = (payload) => {
    const { orderStatusId = 0 } = payload;
    let step = 0;

    if ([2, 5].includes(orderStatusId)) {
        step = 2;
    }

    if ([3, 4, 6, 7].includes(orderStatusId)) {
        step = 3
    }

    if (orderStatusId === 1) {
        step = 1
    }

    if (orderStatusId === 11) {
        step = 11
    }

    return step;
};
