const offersData = {
    offers: [],
    userOffer: [],
    loading: false,
    showNotification: false,
    showErrorModal: false,
    message: '',
    errorTitle: '',
    error: true,
};

export default offersData;
