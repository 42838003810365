import React from 'react';
import { Steps } from 'antd';
import { useParams } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { useStateValue } from '../../../Providers/stateProvider';

import ConfirmOrderPayment from './confirmOrderPayment';
import { decode64 } from '../../../utils';

import './orderDetail.less';

const OrderDetail = () => {
    const [{ offer, order }] = Object.values(useStateValue());
    const { t } = useTranslation();
    const { id  } = useParams();
    const { offers: { records = []} } = offer;
    const { orderDetails: { step, orderType, user } } = order;
    const decodeId = decode64(id);
    const orderData = records.find(r => r.id === decodeId);
    const tOrder = orderType || orderData?.orderType;
    const stepValue = step;
    const { Step } = Steps;

    return (
        <>
            <div className='order-container'>
            <Steps className="hide-mobile" current={stepValue}>
              <Step title={ user === 'maker'? t('Accept order') : t('Place order')} />
              <Step title={ t('Await Confirmation')} />
              <Step title={t('Make payment')} />
              { tOrder === 'Buy' ? <Step title={t('Confirm payment')} /> : null }
              <Step title={t('Complete order')} />
            </Steps>
            <br/>
            <br/>
            <br/>
                <div className="content">
                     <ConfirmOrderPayment t={t} />
                </div>
                <br/>
                <br/>
                <br/>
            </div>
        </>
    )
}

export default OrderDetail;
