import React from 'react';
import { Row, Col } from 'antd';
 import Icon from '@ant-design/icons';
import { warning } from '../icons';

import './'

const warningComponent = ({ Text }) => {
    const WarningIcon = props => <Icon component={warning} {...props} />;
    return (
        <Row align="middle" className="warning-container">
            <Col xs={6} lg={2}>
                <WarningIcon />
            </Col>
            <Col xs={18} lg={16}>
                {Text()}
            </Col>
        </Row>
    )
}

export default warningComponent;
