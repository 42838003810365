import {
    loginRequest,
    verifyLoginRequest,
    setTradContactRequest
} from '../../Services/apiRequest';
import userType from '../reducers/user/type';

const {
    INITIAL_LOGIN_REQUEST,
    INITIAL_LOGIN,
    LOGIN_USER,
    LOOP_REQUEST,
    LOGIN_ERROR,
    SET_TRADE_CONTACT,
} = userType;

const userLogin = async (data, dispatch) => {
    try {
        dispatch({
            type: INITIAL_LOGIN_REQUEST,
        });

        const user = await loginRequest(data);

        const { dynamicLink, loginId, qrCode } = user.data;
        dispatch({
            type: INITIAL_LOGIN,
            payload: { dynamicLink, loginId, qrCode }
        });

        return user;
    } catch (e) {
        dispatch({
            type: LOGIN_ERROR,
            error: e?.response?.data?.error
        });
    }
};

const verifyLogin = async (data, dispatch) => {
    try {
        const verifiedUser = await verifyLoginRequest(data);
        dispatch({
            type: LOGIN_USER,
            payload: verifiedUser.data
        });
    } catch (e) {
        dispatch({
            type: LOOP_REQUEST
        });
    }
}

const setTradContact = async (dispatch, data) => {
    try {
        await setTradContactRequest(data);
        dispatch({
            type: SET_TRADE_CONTACT
        })
    } catch (e) {
        dispatch({
            type: 'ERROR',
            payload: e
        })
    }
}


export { userLogin, verifyLogin, setTradContact };
