import React from 'react';
import { Modal as AntModal } from 'antd';
import './modal.less'

const Modal = ({
  title,
  visible,
  handleCancel,
  handleOk,
  okText,
  loading,
  children,
  ...props
}) => {
  return (
    <AntModal
      title={title}
      visible={visible}
      confirmLoading={loading}
      onOk={handleOk}
      onCancel={handleCancel}
      okText={okText || 'Save'}
      {...props}>
      {children}
    </AntModal>
  );
};

export default Modal;
