import i18n from './reducers/i18n/state';
import user from './reducers/user/state';
import payments from './reducers/payments/state';
import profile from './reducers/profile/state';
import offer from './reducers/offers/state';
import error from './reducers/error/state';
import order from './reducers/orders/state';
import app from './reducers/app/state';

const initialState = {
    i18n,
    user,
    payments,
    profile,
    offer,
    error,
    order,
    app,
};

export default initialState;