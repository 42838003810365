import React from 'react';
import { Empty, Button, Row, Col } from 'antd';

const EmptyComponent = ({ t }) => {
  return (
    <div className='empty'>
      <Empty
        description={
          <Row>
            <Col>{ t('The Timbuktu p2p platform allows everyone to buy and sell XBN using various currencies in a peer-to-peer manner.')}</Col>
            <br />
            <Col>
            { t('Interested merchants can apply by clicking the below button to make markets and provide liquidity on the platform.')}
            </Col>
          </Row>
        }
      >
        <Button type="primary" href="https://docs.google.com/forms/d/e/1FAIpQLSepJqzeDv1_d4zk-_0qPpOBzZcscOga9Ezts8ZZyfZPSEyuvw/viewform" target="_blank" rel="noopener noreferrer">
         { t('Become a Merchant') }</Button>
      </Empty>
    </div>
  );
};

export default EmptyComponent;
