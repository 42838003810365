import React, { useEffect } from 'react';
import { Row, Col } from 'antd';
import { useStateValue } from '../../Providers/stateProvider';
import './termsAndConditions.less';



const TermsAndConditions = () => {
  const [, dispatch] = Object.values(useStateValue());

  useEffect(() => { 
    dispatch({ type: 'SET_PAGE_CURRENT', payload: 'disclaimer' });
    window.scrollTo(0, 0)
  }, [dispatch]);

  return (
    <div className='Terms-container container'>
      <Row className='header-style'>
        <h2>TIMBUKTU TERMS OF USE</h2>
      </Row>
      <Row >
        <Col className='body-style' xs={ 24 } sm={ 24 } md={ 24 } lg={ 24 } xl={ 24 } xxl={ 24 }>
          <h6>1. Introduction</h6>
          <p>The Terms of Use (the “Terms”) constitute the agreement and understanding regarding the use of any or all of the services on Timbuktu (“Services”), and any manner of accessing them, between you and Speratech Limited (“Speratech”), a Nigerian registered company.
          </p> <p>
            Speratech is the operator and developer of the Timbuktu Platform, an online marketplace for trading digital assets leveraging the Bantu Blockchain network.
          </p>
          <p>
            As described in the Terms, you agree to be legally bound by these terms and all terms incorporated by reference. If you do not agree to be bound by these terms, do not access or use the Services
          </p>
          <p>
            Speratech reserve the right to change or modify the terms and conditions contained in the Terms, including but not limited to any policy or guideline of the platform, at any time and at our sole discretion. Speratech will provide notice of these changes by posting the revised Terms or by any other means as determined by Speratech at its sole discretion. Any changes or modifications will be effective immediately upon posting the revisions to the platform or at the instant that we transmit the information. These changes will apply at that instant to all then current and subsequent Services.
          </p>
          <p>
            The Services are intended solely for users who are 18 or older. By accessing or using our Services, you represent and warrant that you are at least 18 years old and have not previously been suspended or removed from the Platform or any Services. You also represent and warrant that you are not on any trade or economic sanctions lists, such as the United Nations Security Council Sanctions List, nor restricted or prohibited from engaging in any type of trading by any governmental or regulatory agency.
          </p>
          <h6>
            2. KYC/AML Requirements
          </h6>
          <p>
            Speratech has the right to know the purpose and background of the users who use our Services. You should provide comprehensive and accurate information as required. If we have reasonable grounds to suspect that you have provided false information, we are entitled to restrict you from the use of some or all of our Services temporarily or permanently.
          </p>
          <p>
            Yow will comply with our KYC requirements concerning your identity and you shall ensure that the accounts you use on Timbuktu are legally registered and used by yourself.
          </p>
          <p>
            When triggering our anti-money laundering and anti-terrorist financing alarms, you shall cooperate with us in its verification, and assist in providing any risk control evidence as needed (subject to our risk control measures then effective). If you fail to do so after triggering the anti-money laundering and anti-terrorist financing alarms, we may disable all or part of the features of your account.
          </p>
          <p>
            You must ensure you have the legal rights to the transaction in accordance with applicable laws. The Platform does not guarantee the legality of your transactions. To make sure all transactions are legally compliant, we may (but is not obliged) review the legality of the transactions carried out by you and take necessary actions in accordance with applicable laws and regulations.
          </p>
          <h6>3. Disclaimer</h6>
          <p>
            You fully understand the risks associated with P2P transactions, including but not limited to the risk of great fluctuations in the value of digital assets, the credibility risks concerning the counterparties under P2P transactions, and the compliance risks of fiat currency transactions.
          </p>
          <p>
            You have adequate investment knowledge and experience, and the ability to bear the risks associated with P2P transactions and agree to independently bear all the risks arising from the engagement in P2P transactions.
          </p>
          <p>
            Before undertaking any transaction, you have read and understood all contents of the Terms and relevant rules on the Platform and have consulted professionals before deciding at your own discretion on whether or not and how to complete the P2P transactions based on their recommendations.
          </p>
          <p>
            Speratech is not a party to a P2P transaction. Therefore, any disputes between users engaging in P2P transactions have nothing to do with Speratech. Speratech has neither the rights nor the obligations to resolve any disputes arising therefrom. It is not obliged to assume physical obligations or responsibilities to any party (including but not limited to indemnities).
          </p>
          <p>
            You shall bear any loss resulted in your own fault or error, including but not limited to: not in accordance with the transaction prompts operation, not timely P2P operations, forgetting or leakage of passwords, passwords are cracked, and/or your computer is invaded or hacked by others.
          </p>
          <p>
            You agree and authorize Speratech to take all reasonable actions (including but not limited to canceling transactions or disabling account features under certain circumstances) in accordance with Terms and relevant rules of the Platform to safeguard you, Speratech and other users’ legitimate rights.
          </p>
          <p>
            You agree that in the event of a potential loophole in the Terms that unjustly enriches you, Speratech will contact you to recover the gains. You must effectively cooperate, otherwise, we will take steps, including, but not limited to restrictions to Services, freezing funds, prosecute you in a court with right of jurisdiction and other recourse measures. In the event that you did not cooperate, you will also bear recourse costs.
          </p>
          <h6>4. Abnormal Transactions</h6>
          <p>
            For abnormal transactions, market interruption and other abnormal conditions caused by system failure, network failure, distributed denial of service attacks (DDoS) and other hacker attacks and other unexpected factors, Speratech has the right to cancel the abnormal transaction results.
          </p>
          <p>
            You agree that, to the maximum extent permitted by law, Speratech shall not be liable for any losses you suffer from the use of the Services on the Platform due to reasons other than Speratech’s intentional conduction or gross negligence, including but not limited to hacker attacks, power outages or unavoidable technical failures.
          </p>
          <p>
            Speratech has the right to restrict, suspend, or terminate your account or access to Services in its sole and absolute discretion, immediately and with or without notice and for a period, at the discretion of Speratech, if:
            <ul>
              <li>
                we reasonably suspect that you have violated or may be acting in violation of the Terms;
              </li>
              <li>
                we believe it is necessary to protect other users from danger or loss. If we exercise our right to restrict or deny you access to the Services, we will not be responsible for any consequences of our failure to provide you with access to the Services, including any delay, damage, or inconvenience that may result;
              </li>
              <li>
                if we determine that you are using the Services in any way that may adversely affect or prevent other users from taking advantage of our Services;
              </li>
              <li>
                if it is revealed that in the process of interacting with other users, you used inappropriate language against the counterparty, insulted, extorted, harassed, threatened, or otherwise violated or tried to violate legal rights (such as, among other things, the rights to privacy, publicity, etc. intellectual property) of other users, as well as collecting any personal information of other users for the purpose of its distribution on the Internet or any form of attack, personal or otherwise.
              </li>
            </ul>
          </p>
          <p>
            You acknowledge that our decision to take certain actions, including limiting access to, suspending, or closing your account or wallet, may be based on confidential criteria that are essential to our risk management and security protocols. You agree that Speratech is under no obligation to disclose the details of its risk management and security procedures to you.
          </p>
          <p>
            We will remove the suspension as soon as possible once the reasons for the suspension no longer exist, however, we are under no obligation to notify you when (if ever) such suspension will be lifted.
          </p>
          <h6>5. Limitations to License Use</h6>
          <p>
            We grant You a limited, nonexclusive, non-transferable license, subject to the Terms, to access and use our website and Services, solely for approved purposes as permitted by us. You agree that you will not copy, transmit, distribute, sell, license, reverse engineer, modify, publish, or participate in the transfer or sale of, create derivative works from, or in any other way, exploit any of our products and Services.
          </p>
          <p>
            You agree that:
            <ul>
              <li>
                All rights, title and interest in the Service and associated software, website and technology, including all intellectual property rights therein, are and shall remain with Speratech;
              </li>
              <li>
                No right or interest in the Service is conveyed other than the limited licenses granted herein;
              </li>
              <li>
                The Services are protected by the copyright and other intellectual property laws;
              </li>
              <li>
                All rights not expressly granted in these Terms are reserved.
              </li>
            </ul>
          </p>
          <p>
            Speratech has the right to inquire, freeze or deduct your items and accounts in accordance with the requirements of any applicable judicial organizations, administrative organizations and military organizations, including but not limited to public security organizations, prosecutorial organizations, courts, customs, tax authorities and so on.
          </p>
          <p>
            It is your sole responsibility to determine whether, and to what extent, any taxes apply to any transactions You conduct through the Services, and to withhold, collect, report and remit the correct amounts of taxes to the appropriate tax authorities. Additionally, you agree to comply with all relevant laws as applicable. Regarding the prevention of terrorist financing and anti-money laundering (AML), we will work with local authorities. When using our Services, you acknowledge that your actions are in a legal and proper manner and your sources of digital assets are not from illegal activities. We may discretionarily or in coordination with local law enforcement authorities seize, restrict or close-out your account, fiat currency and digital assets.
          </p>
          <p>
            You shall indemnify us and our agents (if any), employees, officers, directors, affiliates, subsidiaries and successors, and hold them harmless from and against all third-party claims except those resulting solely from our breach of the Terms.
          </p>
          <p>
            Please refer to our Privacy Policy for information about how we collect, use, and share your information.
          </p>
          <h6>6. No Financial Advice </h6>
          <p>
            Speratech is not a broker, intermediary, agent, or advisor and has no fiduciary relationship or obligation to you in connection with any trades or other decisions or activities effected by you using the Platform or the Services. No communication or information provided to you on the the Platform is intended as or shall be considered or construed as, investment advice, financial advice, trading advice, or any other sort of advice.
          </p>
          <h6>7. Contact</h6>
          <p>
            Feel free to contact us <a target="_blank" rel="noreferrer" href="https://t.me/timbuktux">here</a> with any questions about these terms.
          </p>
          <h6>8. Use Outside of Nigeria</h6>
          <p>
            Speratech expressly disclaims any representation or warranty that the Platform complies with all applicable laws and regulations outside of Nigeria. If you access the Platform outside of Nigeria, you expressly understand and agree that you are responsible for determining compliance with different laws, regulations, or customs that may apply in connection with your use of the Platform.
          </p>
          <h6>9. Risk of Regulatory Actions in One or More Jurisdictions</h6>
          <p>
            The Platform could be impacted by one or more regulatory inquiries or regulatory action, which could impede or limit the ability of Speratech to continue to develop, or which could impede or limit your ability to access or use the Platform or the underlying blockchain network.
          </p>
          <h6>10.  Relationship</h6>
          <p>
            This Agreement does not create a joint venture, agency, partnership, or other forms of joint enterprise between you and us. Except as expressly provided herein, neither party has the right, power, or authority to create any obligation or duty, express or implied, on behalf of the other.
          </p>
          <h6>11. Governing Law</h6>
          <p>
            The Terms, your use of the Services, any claim, counterclaim or dispute of any kind or nature whatsoever arising out of the Terms, directly or indirectly, shall be governed by, and construed in accordance with the laws of Nigeria without regard to the principles of conflicts of laws thereof.
          </p>
        </Col>
      </Row>
    </div>
  );
};

export default TermsAndConditions;
