import React, { useEffect } from 'react';
import { Link } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { Row, Menu, Badge } from 'antd';
import { getUserOrders, getUserTrades } from '../../../Providers/action/order';
import { useStateValue } from '../../../Providers/stateProvider';
import './menuBar.less';


const MenuBar = () => {
  const pageValue = {
    page: 1,
    limit: 10,
    resultGroup: 'Awaiting'
  };

  const [{ user: { loggedIn }, order, app: { current }, profile: { kycLevel }}, dispatch] = Object.values(useStateValue());
  const { t } = useTranslation();

  const { myOrders, myTrades } = order;

  useEffect(() => {
    if (loggedIn) {
      getUserOrders(dispatch, pageValue);
      if (kycLevel > 0) {
        getUserTrades(dispatch, pageValue, current);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dispatch, loggedIn, kycLevel]);

  return (
    <>
      <Row className='menu-border' style={{ background: '#FF9A47', borderBottom: 'none' }}>
        <div className='container' style={{ background: '#FF9A47' }}>
          <Menu selectedKeys={[current]} mode="horizontal">
            <Menu.Item key="buy">
              <Link to="/buy" style={{ color: '#000000' }}>
                {t('Buy')}
              </Link>
            </Menu.Item>
            <Menu.Item key="sell">
              <Link to="/sell" style={{ color: '#000000' }}>
                {t('Sell')}
              </Link>
            </Menu.Item>
            {loggedIn ?
              <>
                  <Menu.Item key="orders">
                <Badge 
                  count={myOrders.Awaiting?.records?.length || 0}
                  overflowCount={99}
                  offset={[10, -6]}
                  style={{ backgroundColor: '#fff', color: '#000', border: '1px solid #000' }}
                >
                    <Link to="/orders" style={{ color: '#000000' }}>
                      {t('My Orders')}
                    </Link>
                </Badge>
                  </Menu.Item>
                <Menu.Item key="offers/add">
                    <Link to="/offers/add" style={{ color: '#000000' }}>
                      {t('Create Offer')}
                    </Link>
                </Menu.Item>
                <Menu.Item key="trades">
                <Badge 
                  count={myTrades.Awaiting?.records?.length || 0}
                  overflowCount={99}
                  offset={[10, -6]}
                  style={{ backgroundColor: '#fff', color: '#000', border: '1px solid #000' }}
                >
                  <Link to="/trades" style={{ color: '#000000' }}>
                    {t('My Offer Trades')}
                  </Link>
                </Badge>
                </Menu.Item>
                <Menu.Item key="offers">
                  <Link to="/offers" style={{ color: '#000000' }}>
                    {t('My Offers')}
                  </Link>
                </Menu.Item>
              </>
              : null}
          </Menu>
        </div>
      </Row>
    </>
  );
};

export default MenuBar;
