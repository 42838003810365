import React from 'react';
import PropTypes from 'prop-types';
import { Tabs as AntdTabs } from 'antd';
import './tabs.less'
const { TabPane } = AntdTabs;

const Tabs = ({ tabs, tabsProps, tabPosition = 'top', tabBarExtraContent, onChange, onTabClick }) => {

  return (
    <AntdTabs
      defaultActiveKey="1"
      className='tabs'
      onTabClick={onTabClick}
      onChange={onChange}
      {...tabsProps}
      tabPosition={tabPosition}
      tabBarExtraContent={tabBarExtraContent}
      tabBarGutter={40}
    >
      {tabs.map((tab, i) => (
        <TabPane tab={tab.key} key={i + 1}>
          {tab.body}
        </TabPane>
      ))}
    </AntdTabs>
  );
};

Tabs.propTypes = {
  tabs: PropTypes.arrayOf(
    PropTypes.shape({
      key: PropTypes.string.isRequired,
      body: PropTypes.node.isRequired,
    }),
  ),
  tabsProps: PropTypes.shape({
    ...Tabs.propTypes,
  }),
};



export default Tabs;
