import React, { useEffect } from 'react';
import { useHistory } from "react-router-dom";
import { useTranslation } from 'react-i18next';
import { useStateValue }  from '../Providers/stateProvider';
import notification from '../Components/Shared/Notification';

const Logout = () => {
    const history  = useHistory();
    const { t } = useTranslation();
    const [,dispatch] = Object.values(useStateValue());

    useEffect(() => {
    dispatch({
        type: 'LOGOUT'
      });
        notification('warning', t('Please login to continue'));
        history.push('/login');
    });
    return (
        <></>
    )
}

export default Logout
