import React, { useState, useEffect } from 'react';
import {
  Form,
  Row,
  Col,
  Input,
  Select
} from 'antd';
import Modal from '../Modal';
import Button from '../Button';
import Loading from '../Loading';

import { useStateValue } from '../../../Providers/stateProvider';
import { setItem } from '../../../Services/localStorageService';
import { setTradContact } from '../../../Providers/action/user';

import 'react-phone-number-input/style.css'
import { getCountries, getCountryCallingCode } from 'react-phone-number-input/input';
import { formatPhoneNumberIntl, isValidPhoneNumber } from 'react-phone-number-input';
import en from 'react-phone-number-input/locale/en.json';


const { Option } = Select;

const PhoneModal = ({ t, loggedIn, manualCancel, phone = '', manualTrigger = false }) => {
  const initialState = {
    contactPhone: phone,
    isModalVisible: false,
    loading: false,
    code: '+'
  };
  const [state, setState] = useState(initialState);
  const [form] = Form.useForm();
  const [{
    profile: {
      contactPhone
    }
  }, dispatch] = Object.values(useStateValue());
  const handleOk = async () => {
    setState({ ...state, loading: true })
    await setTradContact(dispatch, state);
    setItem('timbuktu_trade_contact_number', state.contactPhone);
    setState({ ...state, loading: false })
    handleCancel();
  };

  const handleCancel = () => {
    setState({ ...state, isModalVisible: false });
    manualCancel();
  };

  const onChange = (phone) => {
    const { value } = phone.target;
    const x = `+${state.code}${value}`;
    if(isValidPhoneNumber(x)){
      const formatedPhone = formatPhoneNumberIntl(x);
      setState({ ...state, 'contactPhone': formatedPhone });
    } else {
      setState({ ...state, 'contactPhone': value });
    
    }

  // console.log(x, formatedPhone, value);
};

  useEffect(() => {
    if (manualTrigger) {
      setState({
        ...state,
        isModalVisible: manualTrigger,
        contactPhone: phone
      });
    }
    if (loggedIn) {
      if (!contactPhone) {
        setState({ ...state, isModalVisible: true });
      }
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [contactPhone, loggedIn, manualTrigger]);

  const selectCountry = (value) => {
    const country = value.split('-')[1];
    const code  = getCountryCallingCode(country);
    const formatedPhone = formatPhoneNumberIntl(`${code}${state.phone}`);
    setState({ ...state, 'contactPhone': formatedPhone, code });
  }

  return (
    <div>
      <Modal
        title={t("Add trade contact number")}
        visible={state.isModalVisible}
        onOk={handleOk}
        onCancel={handleCancel}
        footer={[
          <Button key="back" type="secondary" onClick={handleCancel}>
            {t('Cancel')}
          </Button>,
          <Button
            key="submit"
            type="primary"
            style={{ color: 'white' }}
            disabled={state.loading}
            onClick={handleOk}
          >
            {state.loading ? <> <Loading /> &nbsp; {t('Saving')}... </> : t('Save contact')}
          </Button>
        ]}
      >
          <Form
            form={form}
            className='add-offer'
            layout='vertical'
          //   onFinish={handleSubmit}
          >
            <Row>
              <Col xxl={24} lg={24} md={24} sm={24} xs={24}>
                <Input.Group compact>
                    <Select
                      style={{ width: '30%' }}
                      onChange={selectCountry}
                      placeholder={t('Select Country')}
                      size="large"
                      showSearch
                      >
                      {getCountries().map((country) => (
                        <Option key={country} value={`${en[country]}-${country}`} className='option'>
                          {en[country]} +{getCountryCallingCode(country)}
                        </Option>
                      ))}
                    </Select>
                    <Input
                      style={{ width: '70%' }}
                      name='contactPhone'
                      value={ state.contactPhone}
                      onChange={onChange}
                      size="large"
                    />
                </Input.Group>
              </Col>
            </Row>
          </Form>
      </Modal>
    </div>
  )
}

export default PhoneModal;
