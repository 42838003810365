import React from 'react';
import {
    Row,
    Col,
    Button,
} from 'antd';
import { useHistory } from 'react-router-dom'
import Modal from '../../../Components/Shared/Modal';

const ReleaseModal = ({ 
    t,
    showAppealModal,
    handleCancelAppeal,
    orderId,
    maker,
}) => {
    const history = useHistory();

    return (
        <Modal
            width={700}
            title={t('Order Appeal')}
            visible={showAppealModal}
            onOk={handleCancelAppeal}
            onCancel={handleCancelAppeal}
            footer={[
                <Button key="submit" type="secondary" onClick={() => history.push(!maker ? `/appeal/${orderId}` : {pathname: `/appeal/${orderId}`, search: '?trades=true'} )}>
                    {t('Yes')}
                </Button>,
                <Button key="submit" type="primary" onClick={handleCancelAppeal}>
                    {t('No')}
                </Button>
            ]}
        >
            <Row>
                <Col span={24}>
                    {/* <h3> {t('Kindly send an email to')} <a href="mailto:support@timbuktu.exchange">support@timbuktu.exchange</a> {t('to log your complaint.')} </h3> */}
                    <h3>Are you sure you want to proceed with the appeal? </h3>
                    {/* { !reject ?
                    <p style={{ fontSize: '20px', textAlign: 'center' }}>
                        {t('Are you sure you want to release')} {offerAssetId} {t('asset from escrow')}?
                    </p> :  <p style={{ fontSize: '20px', textAlign: 'center' }}>
                        {t('Are you sure you want to cancel this order?')}?
                    </p>}
                    <p style={{ textAlign: 'center' }}>{t('Enter the code below to proceed')}</p>

                    <h1 className="confirm-code">{state.code}</h1>
                    <Input name='codeInput' placeholder={t("Enter the code above to proceed")} onChange={onChange} size="large" />
                    <br /><br />
                    <br /> */}
                </Col>
            </Row>
        </Modal>
    )
}

export default ReleaseModal
