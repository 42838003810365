const order = {
    loading: false,
    playNotification: false,
    showNotification: false,
    message: '',
    lastUpdated: new Date(),
    orderDetails: {
        orderId: '',
        orderType: '',
        offerAssetId: '',
        orderStatusId: 0,
        step: 0,
        counter: 0,
        orderAssetReleaseExplorerLink: '',
        fiatDepositTransactionId: '',
    },
    myOrders: {
        Awaiting: [],
        Completed: [],
        Canceled: [],
        All: []
    },
    myTrades: {
        Awaiting: [],
        Completed: [],
        Canceled: [],
        All: []
    }
}

export default order;
