import React, { useEffect } from 'react';
import { Row } from 'antd';
import { useStateValue } from '../../Providers/stateProvider';
import './disclaimer.less';



const Disclaimer = () => {
  const [, dispatch] = Object.values(useStateValue());

  useEffect(() => { 
    dispatch({ type: 'SET_PAGE_CURRENT', payload: 'disclaimer' });
    window.scrollTo(0, 0)
  }, [dispatch]);

  return (
    <div className='Terms-container container'>
      <Row className='header-style'>
        <h2>DISCLAIMER</h2>
      </Row>
      <Row className='body-style'>
        <p>I fully understand the risks associated with P2P transactions, including but not limited to the risk of great fluctuations in the value of digital assets, the credibility risks concerning the counterparties under P2P transactions, and the compliance risks of fiat currency transactions.</p> <p>
          Speratech is the operator and developer of the Timbuktu Platform, an online marketplace for trading digital assets leveraging the Bantu Blockchain network.
        </p>
        <p>
          I have adequate investment knowledge and experience, and the ability to bear the risks associated with P2P transactions and agree to independently bear all the risks arising from the engagement in P2P transactions.</p>
        <p>
          Before undertaking any transaction, I have read and understood all contents of the Terms and relevant rules on the Platform and have consulted professionals before deciding at my own discretion on whether or not and how to complete the P2P transactions based on their recommendations.</p>
        <p>
          Timbuktu is not a party to any P2P transaction. Therefore, any disputes between users engaging in P2P transactions have nothing to do with Timbuktu . Timbuktu has neither the rights nor the obligations to resolve any disputes arising therefrom. It is not obliged to assume physical obligations or responsibilities to any party (including but not limited to indemnities).</p>

        <p>
          I shall bear any loss resulted in my own fault or error, including but not limited to: not in accordance with the transaction prompts operation, not timely P2P operations, forgetting or leakage of passwords, passwords are cracked, and/or my computer is invaded or hacked by others.</p>
        <p>
          I agree and authorize Timbuktu to take all reasonable actions (including but not limited to canceling transactions or disabling account features under certain circumstances) in accordance with Terms and relevant rules of the Platform to safeguard you, Timbuktu and other users’ legitimate rights.</p>
        <p>
          I agree that in the event of a potential loophole in the Terms that unjustly enriches me, Timbuktu will contact you to recover the gains. I must effectively cooperate, otherwise, we will take steps, including, but not limited to restrictions to Services, freezing funds, prosecute me in a court with right of jurisdiction and other recourse measures. In the event that you did not cooperate, I will also bear recourse costs. </p>

      </Row>
    </div>
  );
};

export default Disclaimer;
