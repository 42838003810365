import userState from './state';
import Type from './type';
import { setToken, clearPreLogin, clearToken } from '../../../Services/localStorageService';
import notification from '../../../Components/Shared/Notification';

const userReducer = (user = userState, { payload, type, error }) => {
    switch (type) {
        case Type.INITIAL_LOGIN_REQUEST:
            return { ...user, loading: true }

        case Type.INITIAL_LOGIN:
            return {
                ...user,
                loading: false,
                qrCode: payload.qrCode,
                dynamicLink: payload.dynamicLink,
                loginId: payload.loginId,
            }
        case 'RESET':
            return {
                ...user,
                loading: false,
                qrCode: '',
                dynamicLink: '',
                loginId: '',
            }
        case Type.LOOP_REQUEST:
            return {
                ...user,
                counter: user.counter + 1
            }
        case Type.LOGIN_USER:
            const { accessToken, refreshToken } = payload
            setToken(accessToken, refreshToken);
            clearPreLogin()
            return {
                ...user,
                verified: true,
                loggedIn: true,
            }
        case Type.LOGOUT:
            clearToken()
            return {
                ...user,
                verified: false,
                loggedIn: false,
                qrCode: '',
                contactPhone: '',
                dynamicLink: '',
                loginId: '',
            }
        case Type.LOGIN_ERROR:
            notification('error', error)
            return {
                ...user,
                loading: false
            }
        case Type.SET_TRADE_CONTACT:
            return {
                ...user,
                showAddTradeContactntNotification: true,
                message: 'Trade contact added successfully!',
            }
        case Type.SET_NOTIFICATION:
            return {
                ...user,
                showAddTradeContactntNotification: payload
            }
        default:
            return user;
    }
}

export default userReducer;

