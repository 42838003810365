/* eslint-disable no-unused-vars */
import React, { useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import {
    Steps,
    Row,
    Col,
    Descriptions,
    Statistic,
    Button
} from 'antd';
import { useParams } from 'react-router-dom';
import { useStateValue } from '../../../Providers/stateProvider';
import { decode64, encode64 } from '../../../utils';
import { useTranslation } from 'react-i18next';
import moment from 'moment';

import { 
    getUserOrderDetails,
    acceptOrder,
    rejectOrder 
} from '../../../Providers/action/order';


import './orderDetail.less';
const { Countdown } = Statistic;


const OrderDetail = () => {
    const [{ order }, dispatch] = Object.values(useStateValue());
    const history = useHistory();
    const { t } = useTranslation();
    const { id } = useParams();
    const [rejecting, setRejecting] = useState(false);
    const [accepting, setAccepting] = useState(false);

    const { loading, orderDetails} = order;

    const decodedId = decode64(id);
    const {
        orderType, offerAssetId,
        orderStatusId, id: orderId,
        qrCode: payQrCode = '',
        dynamicLink, offerType = '',
        createdAt, offerAssetPrice,
        user, offerCurrencyId, orderAmount,
        takerPaymentChannelId, takerPaymentMethodBankName,
        takerPaymentMethodDestinationAccount,
        takerPaymentMethodName, orderCurrencyAmount, offerCurrencyPaymentMethod,
        orderAssetReleaseExplorerLink,
        expiresAt, offerMaker, offerTaker,
        fiatDepositTransactionId, offerTakerPhone,
        offerMakerPhone, takerPaymentMethodCountryCode,
        takerReputation,
        // cancelAfter,
    } = orderDetails;

    useEffect(() => {
        let query = { orderId: decodedId, type: 'trades' };
        getUserOrderDetails(dispatch, query);
    }, [dispatch, decodedId]);


    const tOrder = orderType;

    const { Step } = Steps;
    const goToDetails = async (e, orderId, type = '') => {
        if (type === 'accept'){
            setAccepting(true);
            await acceptOrder(dispatch, { orderId });
            setAccepting(false);
        } else if (type === 'reject') {
            setRejecting(true);
            await rejectOrder(dispatch, { orderId });
            setRejecting(false);
        }
        history.push({
            pathname: `/orderDetail/${encode64(orderId)}`,
            search: '?trades=true'
          });
    }

    return (
        <>
            <div className='order-container'>
                <Steps className="hide-mobile" current={1}>
                    <Step title={t('Receive order')} />
                    <Step title={t('Await Confirmation')} />
                    <Step title={t('Make payment')} />
                    {tOrder === 'Buy' ? <Step title={t('Confirm payment')} /> : null}
                    <Step title={t('Complete order')} />
                </Steps>
                <br />
                <br />
                <br />
                <div className="content">
                <Row>
        <Col md={24} lg={12} className="content-border">
          <h3>{offerType} {offerAssetId} </h3>

                <Col span={24}>
                {offerTaker} {`${t('is waiting for you to confirmation this order')}`} <Countdown  value={moment(expiresAt)} />
                </Col>
        <Descriptions column={1}>
          <Descriptions.Item label="" >
            <span style={{ fontSize: '18px' }}>
              {offerTaker}
            </span>
            &nbsp;
            &nbsp;
            &nbsp;
            <Descriptions.Item label="" className="user-progress">
              {t('Trades')}:
              &nbsp;
              <span
                style={{ fontWeight: 400 }}
              >
                {takerReputation?.trades || 0}
              </span>
              &nbsp;&nbsp;  | &nbsp;&nbsp;
              <span style={{ fontWeight: 400 }}>
                {takerReputation?.rank || 0}% {t('Completion')}
              </span>
            </Descriptions.Item>
          </Descriptions.Item>
        </Descriptions>

        <Descriptions column={1}>
            { offerType === 'Sell' ?
            <>
            <Descriptions.Item label={t('Amount to send')}>{orderAmount} {offerAssetId}</Descriptions.Item>
            <Descriptions.Item label={t('Amount to receive')}>{orderAmount * offerAssetPrice } {offerCurrencyId}</Descriptions.Item>
            </> : <>
            <Descriptions.Item label={t('Amount to send')}>{orderAmount * offerAssetPrice } {offerCurrencyId}</Descriptions.Item>
            <Descriptions.Item label={t('Amount to receive')}>{orderAmount} {offerAssetId}</Descriptions.Item>
            </>}
          <Descriptions.Item label={t('Payment method')}>
            {takerPaymentChannelId } <br/>
            {takerPaymentMethodBankName } <br/>
            {takerPaymentMethodDestinationAccount } <br/>
            {takerPaymentMethodName } <br/>
            {takerPaymentMethodCountryCode } <br/>
          </Descriptions.Item>
          <Descriptions.Item>
          <Col span={12}> 
            <Button onClick={(e) => goToDetails(e, decodedId, 'accept')} type="primary">{ accepting ? 'Accepting...' : t('Accept') }</Button>
          </Col>
          <Col span={12}>
            <Button onClick={(e) => goToDetails(e, decodedId, 'reject')} type='secondary'>{rejecting ? 'Rejecting...' : t('Reject')}</Button>
          </Col>
          </Descriptions.Item>
        </Descriptions>

      </Col>
      <Col className="payment-form" md={24} xl={12} ></Col>
    </Row>
                </div>
                <br />
                <br />
                <br />
            </div>
        </>
    )
}

export default OrderDetail;
