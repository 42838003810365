import { getItem } from '../../../Services/localStorageService';

const dynamicLink = getItem('timbuktu_dynamic_link');
const qrCode = getItem('timbuktu_qr_code');
const loginId = getItem('timbuktu_login_id');
const loggedIn = getItem('timbuktu_access_token') ? true : false;

const user = {
    dynamicLink,
    qrCode,
    loginId,
    loggedIn,
    accessToken: '',
    refreshToken: '',
    loading: false,
    verified: false,
    counter: 0,
    showAddTradeContactntNotification: false,
    message: '',
}

export default user;
