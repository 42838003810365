import React from 'react';
import { Route, useHistory } from "react-router-dom";
import { getRefreshToken, setItem } from './../Services/localStorageService';
import jwtDecode from "jwt-decode";

export const PrivateRoute = ({ children, ...rest }) => {
  const refreshToken = getRefreshToken();
  const history = useHistory()

  let decodedToken = null
  let validToken;

  if (refreshToken) {
    try {
      decodedToken = jwtDecode(refreshToken);
      validToken = new Date(decodedToken.exp * 1000) > new Date();

    } catch (e) {

    }
  }

  const redirectAndClearLoing = () => {
    const from = rest?.path;
    setItem('from', from);
    history.push('/logout');
  }

  return (
    <>
      {validToken ?
        <Route
          {...rest}
          render={() => (children)
          }
        /> : redirectAndClearLoing()};
    </>
  );
};
