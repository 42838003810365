import {
    createOfferRequest,
    getOfferListRequest,
    getUserOfferRequest,
    editOfferRequest,
    deleteOfferRequest,
} from '../../Services/apiRequest';
import offerTypes from '../reducers/offers/type';

const {
    CREATE_OFFER_REQUEST,
    CREATE_OFFER,
    GET_OFFERS_REQUEST,
    GET_OFFERS,
    GET_USER_OFFERS_REQUEST,
    GET_USER_OFFERS,
    EDIT_OFFER_REQUEST,
    EDIT_OFFER,
    ERROR,
    CONFIRM_ERROR,
    SET_ERROR,
    DELETE_OFFER_REQUEST,
    DELETE_OFFER
} = offerTypes;

const createOrder = async (dispatch, data) => {
    try {
        dispatch({
            type: CREATE_OFFER_REQUEST
        });
        const order = await createOfferRequest(data);
        dispatch({
            type: CREATE_OFFER,
            payload: order.data,
            error: false
        });
    } catch (e) {
        dispatch({
            type: SET_ERROR,
            payload: true,
        });

        dispatch({
            type: CONFIRM_ERROR,
            payload: e,
            errorTitle: 'Add offer error.'
        })
    }
};

const getOfferListData = async (dispatch, data) => {
    try {
        dispatch({
            type: GET_OFFERS_REQUEST
        });

        const offersList = await getOfferListRequest(data);
        dispatch({
            type: GET_OFFERS,
            payload: offersList.data,
        });

    } catch (e) {
        dispatch({
            type: ERROR,
            payload: e
        });
    };
};


const getUserOffer = async (dispatch, data) => {
    try {
        dispatch({
            type: GET_USER_OFFERS_REQUEST
        });
        const offersList = await getUserOfferRequest(data);
        dispatch({
            type: GET_USER_OFFERS,
            payload: offersList.data,
        });

    } catch (e) {
        dispatch({
            type: ERROR,
            payload: e,
        });
    };
};

const editOffer = async (dispatch, data) => {
    try {
        dispatch({
            type: EDIT_OFFER_REQUEST
        });

        const editOfferData = await editOfferRequest(data);
        dispatch({
            type: EDIT_OFFER,
            payload: editOfferData?.data,
            error: false
        });

    } catch (e) {
        dispatch({
            type: SET_ERROR,
            payload: true,
        })
        dispatch({
            type: CONFIRM_ERROR,
            payload: e,
            errorTitle: 'Edit offer error'
        })
    }
};



const deleteOffer = async (dispatch, data) => {
    try {
        dispatch({
            type: DELETE_OFFER_REQUEST
        });

        await deleteOfferRequest(data);
        dispatch({
            type: DELETE_OFFER,
            payload: data,
            error: false
        });

    } catch (e) {
        dispatch({
            type: SET_ERROR,
            payload: true,
        })
        dispatch({
            type: CONFIRM_ERROR,
            payload: e,
            errorTitle: 'Delete offer error'
        })
    }
};

export {
    createOrder,
    getOfferListData,
    getUserOffer,
    editOffer,
    deleteOffer,
};
