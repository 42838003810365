import React, {useState, useEffect } from 'react';
import {
    Modal,
    Button,
    Checkbox
} from 'antd';
import { useHistory } from 'react-router-dom';
import { setItem, getItem } from '../../../Services/localStorageService';
import { useStateValue } from '../../../Providers/stateProvider';
import './t_c.less';

const DisclaimerModal = ({t, setTandC}) => {
    const [disabled, setDisabled] = useState(true);
    const [visible, setVisible] = useState(false);
    const [{app: { current }}] = Object.values(useStateValue());
    const history = useHistory();
    const handleOk = () => { 
        setVisible(false);
        setDisabled(false);
        setItem('timbuktu_disclaimerAccepted', 'true');
    }

    useEffect(() => {
        if (current === 'disclaimer' || current === 'home' || current === 't&c' || current === 'manual') { 
                setVisible(false);
                setDisabled(true);
        } else {
            if (getItem('timbuktu_disclaimerAccepted')) {
                setVisible(false);
                setDisabled(false);
                setTandC(true);
            } else {
                setVisible(true);
            }
        }
    }, [visible, current, setTandC]);

    const handleCheck = (e) => { 
        setDisabled(!e.target.checked)
    }

    const handleCancel = () => { 
        history.push('/');
    }

    return (
        <>
            <Modal
                title={t("Disclaimer")}
                visible={visible}
                onOk={handleOk}
                onCancel={handleCancel}
                footer={[
                    <Button
                        key="submit"
                        type="primary"
                        style={{ color: 'white' }}
                        disabled={disabled}
                        onClick={handleOk}
                    >
                        {t('Continue')}
                    </Button>
                ]}
            >
                <p style={{ textAlign: 'justify' }}>I fully understand the risks associated with P2P transactions,
                     including but not limited to the risk of great fluctuations in the value of 
                     digital assets, the credibility risks concerning 
                    the counterparties under P2P... <a href="#/disclaimer">Read more</a></p> 
                    <br/>
                    <br/>
                    <br/>

                    <span className="tandc"><Checkbox onChange={handleCheck}> <b>{t("I accept the ")}</b></Checkbox> <a href="#/termsAndConditions" ><b>{t("Terms & Conditions")}</b></a> </span>
            </Modal>
        </>
    )
}

export default DisclaimerModal
